import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';


import { AuthService } from 'src/app/auth/auth.service';
import { mimeType } from '../mime-type.validator';
import { EditorpageService } from '../editorpage.service';

@Component({
  selector: 'app-abouteditor',
  templateUrl: './abouteditor.component.html',
  styleUrls: ['./abouteditor.component.css']
})
export class AbouteditorComponent implements OnInit, OnDestroy {
  elementIds: string[] = ["ebackgroundPicDiv0Text", "ebackgroundPicDiv0", "eleftColumn",
  "epicText", "esection2Header1", "esection2Header2",
  "esection2Header3", "esection2Text1", "esection2Text2",
  "esection2Text3", "ebackgroundPicDiv1", "ehomepageSection2", "backgroundLayer"];
  bbIds: string[] = ["ctEditDiv", "biEditDiv", "p1EditDiv", "ptEditDiv", "s2h1EditDiv", "s2h2EditDiv", "s2h3EditDiv",
  "s2t1EditDiv", "s2t2EditDiv", "s2t3EditDiv", "bpd1EditDiv", "hs2EditDiv", "blEditDiv"];
  elementFontSizes: string[] = [];
  savedElementFontSizes: string[] = [];
  elementFonts: string[] = [];
  savedElementFonts: string[] = [];
  elementColors: string[] = [];
  savedElementColors: string[] = [];//here
  textElements: string[] = ["ebackgroundPicDiv0Text","epicText","esection2Header1","esection2Text1","esection2Text2","esection2Header2","esection2Text3","esection2Header3"];
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  headerSetting: string;
  userId = "";
  leadEmail = "";
  backgroundImagePath = "";
  photoOne = "";
  pageForm: FormGroup;
  biForm: FormGroup;
  p1Form: FormGroup;
  biimagePreview: string;
  p1imagePreview: string;
  bpd1Form: FormGroup;
  hs2Form: FormGroup;
  blForm: FormGroup;
  ctDirty = false;
  ptDirty = false;
  s2h1Dirty = false;
  s2h2Dirty = false;
  s2h3Dirty = false;
  s2t1Dirty = false;
  s2t2Dirty = false;
  s2t3Dirty = false;
  icon1Form: FormGroup;
  icon2Form: FormGroup;
  icon3Form: FormGroup;
  icon4Form: FormGroup;
  icon5Form: FormGroup;
  icon6Form: FormGroup;
  icon7Form: FormGroup;
  icon8Form: FormGroup;

  fsForm1: FormGroup; fsForm2: FormGroup; fsForm3: FormGroup;
  fsForm4: FormGroup; fsForm5: FormGroup; fsForm6: FormGroup;
  fsForm7: FormGroup; fsForm8: FormGroup;

  tcForm1: FormGroup; tcForm2: FormGroup; tcForm3: FormGroup;
  tcForm4: FormGroup; tcForm5: FormGroup; tcForm6: FormGroup;
  tcForm7: FormGroup; tcForm8: FormGroup;

  colorEditorBool = true;
  textEditorBool = false;
  fontEditorBool = false;
  savedSection1Color: string; savedSection2Color: string; savedTitleText: string; savedRightSideText: string;
  savedHeaderOne: string; savedParagraphOne: string; savedParagraphTwo: string; savedHeaderTwo: string;
  savedParagraphThree: string; savedHeaderThree: string; savedBackgroundLayer: string;


  constructor(
    public authService: AuthService,
    public editorpageService: EditorpageService,
    public route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    document.getElementById("initloader").style.display = "none";
    document.getElementById("hToolbar").style.width = "78%";
    document.getElementById("hToolbar").style.right = "0%";
    document.getElementById("hToolbar").style.zIndex = "2";
    document.getElementById("hToolbar").style.position = "fixed";
    document.getElementById("previewItem").style.display = "block";

    document.getElementById("edColor").style.background = "transparent";
    document.getElementById("edColor").style.border = "1px solid white";

    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
      });

    // code to set the font
    // var allText = document.querySelectorAll<HTMLElement>(".font");
    // for (var i = 0; i < allText.length; i++) {
    //   allText.item(i).style.fontFamily = "Spartan-Regular";
    // }

    this.editorpageService.getHomepage().subscribe(data => {
      console.log(data);
      document.getElementById("logo").style.backgroundImage = 
      'url(' + data.homepage.brandLogoPath + ')';

      document.getElementById("headerTitleText").innerHTML = data.homepage.brandText;
      document.getElementById("hToolbar").style.backgroundColor = data.homepage.headerColor;

      this.headerSetting = data.homepage.headerSetting;
      if (this.headerSetting === "text") {
        document.getElementById("headerTitleText").style.display = "block";
        document.getElementById("logo").style.display = "none";
      } else if (this.headerSetting === "image") {
        document.getElementById("headerTitleText").style.display = "none";
        document.getElementById("logo").style.display = "block";
      }
    });

      this.editorpageService.getAboutpage().subscribe(data => {//new data
        console.log("ABOUTPAGE DATA");
        console.log(data);

        for (var i = 0; i < data.aboutpage.elementFonts.length; i++) {
          this.elementFonts[i] = data.aboutpage.elementFonts[i];
        }
  
        for (var i = 0; i < data.aboutpage.elementFonts.length; i++) {
          document.getElementById(this.textElements[i]).style.fontFamily = data.aboutpage.elementFonts[i];
        }

        for (var i = 0; i < data.aboutpage.elementColors.length; i++) {//here
          this.elementColors[i] = data.aboutpage.elementColors[i];
          this.savedElementColors[i] = data.aboutpage.elementColors[i];
          document.getElementById(this.textElements[i]).style.color = data.aboutpage.elementColors[i];
        }

        for (var i = 0; i < data.aboutpage.elementFontSizes.length; i++) {
          this.elementFontSizes[i] = data.aboutpage.elementFontSizes[i];
          this.savedElementFontSizes[i] = data.aboutpage.elementFontSizes[i];
          document.getElementById(this.textElements[i]).style.fontSize = data.aboutpage.elementFontSizes[i];
        }

        this.userId = data.aboutpage.userId;
        this.leadEmail = data.aboutpage.email;
  
  
        this.backgroundImagePath = data.aboutpage.backgroundImagePath;
        console.log(this.backgroundImagePath);
        if (this.backgroundImagePath !== "") {
          document.getElementById("ebackgroundPicDiv0").style.backgroundImage = 
          'url(' + this.backgroundImagePath + ')';
          console.log(document.getElementById("ebackgroundPicDiv0").style.backgroundImage);
        }
        document.getElementById("backgroundLayer").style.backgroundColor = data.aboutpage.backgroundLayer;
        this.savedBackgroundLayer = data.aboutpage.backgroundLayer;
        this.photoOne = data.aboutpage.photoOne;
        console.log(this.photoOne);
        if (this.photoOne !== "") {
          document.getElementById("eleftColumn").style.backgroundImage = 
          'url(' + this.photoOne + ')';
          console.log(document.getElementById("eleftColumn").style.backgroundImage);
        }

        document.getElementById("ebackgroundPicDiv1").style.backgroundColor = data.aboutpage.section1Color;
        this.savedSection1Color = data.aboutpage.section1Color;
        document.getElementById("ehomepageSection2").style.backgroundColor = data.aboutpage.section2Color;
        this.savedSection2Color = data.aboutpage.section2Color;
        document.getElementById("ebackgroundPicDiv0Text").innerHTML = data.aboutpage.titleText;
        this.savedTitleText = data.aboutpage.titleText;
        document.getElementById("epicText").innerHTML = data.aboutpage.rightSideText;
        this.savedRightSideText = data.aboutpage.rightSideText;
        document.getElementById("esection2Header1").innerHTML = data.aboutpage.headerOne;
        this.savedHeaderOne = data.aboutpage.headerOne;
        document.getElementById("esection2Text1").innerHTML = data.aboutpage.paragraphOne;
        this.savedParagraphOne = data.aboutpage.paragraphOne;
        document.getElementById("esection2Text2").innerHTML = data.aboutpage.paragraphTwo;
        this.savedParagraphTwo = data.aboutpage.paragraphTwo;
        document.getElementById("esection2Header2").innerHTML = data.aboutpage.headerTwo;
        this.savedHeaderTwo = data.aboutpage.headerTwo;
        document.getElementById("esection2Text3").innerHTML = data.aboutpage.paragraphThree;
        this.savedParagraphThree = data.aboutpage.paragraphThree;
        document.getElementById("esection2Header3").innerHTML = data.aboutpage.headerThree;
        this.savedHeaderThree = data.aboutpage.headerThree;
        
      });
      

      this.bpd1Form = new FormGroup({bpd1Color: new FormControl(null, {validators: [Validators.required]}) });
      this.hs2Form = new FormGroup({hs2Color: new FormControl(null, {validators: [Validators.required]}) });
      this.blForm = new FormGroup({layerColor: new FormControl(null, { validators: [Validators.required] })});

      this.biForm = new FormGroup({
        image: new FormControl(null, {
          validators: [Validators.required],
          asyncValidators: [mimeType]
        })
      });

      this.p1Form = new FormGroup({
        image: new FormControl(null, {
          validators: [Validators.required],
          asyncValidators: [mimeType]
        })
      });

      this.pageForm = new FormGroup({
        pageSelect: new FormControl(null, {
          validators: [Validators.required]
        })
      });

      this.icon1Form = new FormGroup({
        fontFamily: new FormControl(null, { validators: [Validators.required] })
      }); 
      this.icon2Form = new FormGroup({
        fontFamily: new FormControl(null, { validators: [Validators.required] })
      });
      this.icon3Form = new FormGroup({
        fontFamily: new FormControl(null,{ validators: [Validators.required] })
      });
      this.icon4Form = new FormGroup({
        fontFamily: new FormControl(null,{ validators: [Validators.required] })
      });
      this.icon5Form = new FormGroup({
        fontFamily: new FormControl(null,{ validators: [Validators.required] })
      });
      this.icon6Form = new FormGroup({
        fontFamily: new FormControl(null,{ validators: [Validators.required] })
      });
      this.icon7Form = new FormGroup({
        fontFamily: new FormControl(null,{ validators: [Validators.required] })
      });
      this.icon8Form = new FormGroup({
        fontFamily: new FormControl(null,{ validators: [Validators.required] })
      });

      this.tcForm1 = new FormGroup({tc1: new FormControl(null, { validators: [Validators.required] })});
      this.tcForm2 = new FormGroup({tc2: new FormControl(null, { validators: [Validators.required] })});
      this.tcForm3 = new FormGroup({tc3: new FormControl(null, { validators: [Validators.required] })});
      this.tcForm4 = new FormGroup({tc4: new FormControl(null, { validators: [Validators.required] })});
      this.tcForm5 = new FormGroup({tc5: new FormControl(null, { validators: [Validators.required] })});
      this.tcForm6 = new FormGroup({tc6: new FormControl(null, { validators: [Validators.required] })});
      this.tcForm7 = new FormGroup({tc7: new FormControl(null, { validators: [Validators.required] })});
      this.tcForm8 = new FormGroup({tc8: new FormControl(null, { validators: [Validators.required] })});

      this.fsForm1 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
      this.fsForm2 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
      this.fsForm3 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
      this.fsForm4 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
      this.fsForm5 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
      this.fsForm6 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
      this.fsForm7 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
      this.fsForm8 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});

  }

  colorEditor() {

    this.activeButton("edColor");

    document.getElementById("pageFocus").style.display = "block";
    this.colorEditorBool = true;
    this.textEditorBool = false;
    this.fontEditorBool = false;

    // code to display color editing icons
    var allColorIcons = document.querySelectorAll<HTMLElement>(".color-edit-icon");
    for (var i = 0; i < allColorIcons.length; i++) {
      allColorIcons.item(i).style.display = "inline-block";
    }

    // code to display font editing icons
    var allFontIcons = document.querySelectorAll<HTMLElement>(".text-edit-icon");
    for (var i = 0; i < allFontIcons.length; i++) {
      allFontIcons.item(i).style.display = "none";
    }
  }

  textEditor() {

    this.activeButton("edText");

    document.getElementById("pageFocus").style.display = "block";
    this.textEditorBool = true;
    this.colorEditorBool = false;
    this.fontEditorBool = false;

    // code to display font editing icons
    var allFontIcons = document.querySelectorAll<HTMLElement>(".text-edit-icon");
    for (var i = 0; i < allFontIcons.length; i++) {
      allFontIcons.item(i).style.display = "none";
    }

    // code to display color editing icons
    var allColorIcons = document.querySelectorAll<HTMLElement>(".color-edit-icon");
    for (var i = 0; i < allColorIcons.length; i++) {
      allColorIcons.item(i).style.display = "none";
    }
  }

  fontEditor() {

    this.activeButton("edFont");

    document.getElementById("pageFocus").style.display = "block";
    this.fontEditorBool = true;
    this.textEditorBool = false;
    this.colorEditorBool = false;

    // code to display font editing icons
    var allFontIcons = document.querySelectorAll<HTMLElement>(".text-edit-icon");
    for (var i = 0; i < allFontIcons.length; i++) {
      allFontIcons.item(i).style.display = "inline-block";
    }

    // code to display color editing icons
    var allColorIcons = document.querySelectorAll<HTMLElement>(".color-edit-icon");
    for (var i = 0; i < allColorIcons.length; i++) {
      allColorIcons.item(i).style.display = "none";
    }
  }

  activeButton(activeButton: string) {
    var buttons = ["edColor", "edFont", "edText"];
    for (var i = 0; i < buttons.length; i++) {
      if (buttons[i] === activeButton) {
        document.getElementById(activeButton).style.background = "transparent";
        document.getElementById(activeButton).style.border = "1px solid white";
      } else {
        document.getElementById(buttons[i]).style.background = "white";
        document.getElementById(buttons[i]).style.border = "none";
      }
    }
  }

  updateAboutText() { 
    this.editorpageService.updateAboutpageText(this.userId, this.leadEmail, //need to program these vars
      document.getElementById("ebackgroundPicDiv1").style.backgroundColor, 
      document.getElementById("ehomepageSection2").style.backgroundColor,
      document.getElementById("ebackgroundPicDiv0Text").innerHTML,
      document.getElementById("epicText").innerHTML,
      document.getElementById("esection2Header1").innerHTML,
      document.getElementById("esection2Text1").innerHTML,
      document.getElementById("esection2Text2").innerHTML,
      document.getElementById("esection2Header2").innerHTML,
      document.getElementById("esection2Text3").innerHTML,
      document.getElementById("esection2Header3").innerHTML,
      this.elementFonts, 
      document.getElementById("backgroundLayer").style.backgroundColor,
      this.elementFontSizes,
      this.elementColors//here
      ).subscribe(responseData => {

        console.log(responseData);
        console.log('return marker');
        if (responseData.message === 'Update successful!') {
          console.log("Updated text successfully");
          document.getElementById("edSave").style.backgroundColor = "#eeeeee";
        } else {
          console.log("Something went wrong");
        }
  
      });
  }

  softUpdateTextColor(value:string, textId:string, elementId: string) {//here
    if (value === 'undefined') {
      return;
    }
    document.getElementById(textId + "ColorInput").style.color = "black";
    document.getElementById(elementId).style.color = value;
  }

  setTextColor(value:string, textId:string, elementId: string) {//here
    if (value === 'undefined') {
      return;
    }
    document.getElementById(textId + "ColorInput").style.color = "black";
    document.getElementById(elementId).style.color = value;
    for (var i = 0; i < this.textElements.length; i++) {
      if (this.textElements[i] === elementId) {
        this.elementColors[i] = value;
        console.log('value set');
        document.getElementById('color-icon-'+(i+1)).click();
      }
    }
    this.updateCheck();
  }

  undoTcColor(id: string) {//here
    for (var i = 0; i < this.textElements.length; i++) {
      if (this.textElements[i] === id) {
        this.elementColors[i] = this.savedElementColors[i];
        console.log(this.savedElementColors[i]);
        document.getElementById(id).style.color = this.savedElementColors[i];
        console.log('undid');
      }
    }
    this.updateCheck();
  }

  deleteTcColor(id: string) {//here
    for (var i = 0; i < this.textElements.length; i++) {
      if (this.textElements[i] === id) {
        this.elementColors[i] = 'rgb(0, 0, 0)';
        document.getElementById(id).style.color = 'rgb(0, 0, 0)';
      }
    }
    this.updateCheck();
  }

  pageNav() {
    if (this.pageForm.value.pageSelect === null) {
      window.alert("Select a page");
    }

    if (this.pageForm.value.pageSelect === "contact") {
      this.router.navigate(['/contact']);
    } else if (this.pageForm.value.pageSelect === "homepage") {
      this.router.navigate(['/editor']);
    }
    console.log(this.pageForm.value.pageSelect);

  }

  highlight(event) {
    console.log(event.target.id);
    for (let i = 0; i < this.elementIds.length; i++) {//newedits add an index
      console.log(this.elementIds[i]);
        console.log(this.bbIds[i]);
      if (event.target.id === this.elementIds[i]) {
        
        if (document.getElementById(this.bbIds[i]) !== null) {
          document.getElementById(this.bbIds[i]).style.backgroundColor = "snow";//new data
          //this.scrollEditor(this.bbIds[i]);
        }
      }
    }
    console.log("highlighted");
  }

  unhighlight(event) {
    console.log(event.target.id);
    for (let i = 0; i < this.elementIds.length; i++) {//newedits add an index
      if (event.target.id === this.elementIds[i]) {
        if (document.getElementById(this.bbIds[i]) !== null) {
          document.getElementById(this.bbIds[i]).style.backgroundColor = "transparent";//new data
          //this.scrollEditor(this.bbIds[i]);
        }
      }
    }
    console.log("unhighlighted");
  }

  undoElement(id: string) {//new data
    if (id === this.bbIds[0]){//ctEditDiv
      document.getElementById("ebackgroundPicDiv0Text").innerHTML = this.savedTitleText;
      this.updateCheck();
    } else if (id === this.bbIds[3]){//ptEditDiv
      document.getElementById("epicText").innerHTML = this.savedRightSideText;
      this.updateCheck();
    } else if (id === this.bbIds[4]){//s2h1EditDiv
      document.getElementById("esection2Header1").innerHTML = this.savedHeaderOne;
      this.updateCheck();
    } else if (id === this.bbIds[5]){//s2h2EditDiv
      document.getElementById("esection2Header2").innerHTML = this.savedHeaderTwo;
      this.updateCheck();
    } else if (id === this.bbIds[6]){//s2h3EditDiv
      document.getElementById("esection2Header3").innerHTML = this.savedHeaderThree;
      this.updateCheck();
    } else if (id === this.bbIds[7]){//s2t1EditDiv
      document.getElementById("esection2Text1").innerHTML = this.savedParagraphOne;
      this.updateCheck();
    } else if (id === this.bbIds[8]){//s2t2EditDiv
      document.getElementById("esection2Text2").innerHTML = this.savedParagraphTwo;
      this.updateCheck();
    } else if (id === this.bbIds[9]){//s2t3EditDiv
      document.getElementById("esection2Text3").innerHTML = this.savedParagraphThree;
      this.updateCheck();
    } else if (id === this.bbIds[10]){//bpd1EditDiv
      document.getElementById("ebackgroundPicDiv1").style.backgroundColor = this.savedSection1Color;
      this.updateCheck();
    } else if (id === this.bbIds[11]){//hs2EditDiv
      document.getElementById("ehomepageSection2").style.backgroundColor = this.savedSection2Color;
      this.updateCheck();
    } else if (id === this.bbIds[12]){//hs2EditDiv
      document.getElementById("backgroundLayer").style.backgroundColor = this.savedBackgroundLayer;
      this.updateCheck();
    }
  }

  deleteElement(id: string) {//newedits add an index
    console.log(id);
    for (let i = 0; i < this.bbIds.length; i++) {
      if (id === this.bbIds[i]) {
        console.log("match");
        if (this.bbIds[i] === this.bbIds[10] || this.bbIds[i] === this.bbIds[11] || this.bbIds[i] === this.bbIds[12]) {
            console.log("transparent");
            console.log(this.bbIds[i]);
            if (this.bbIds[i] === this.bbIds[10]) {
              document.getElementById(this.elementIds[i]).style.backgroundColor = this.savedSection1Color;
            } else if (this.bbIds[i] === this.bbIds[11]) {
              document.getElementById(this.elementIds[i]).style.backgroundColor = this.savedSection2Color;
            } else if (this.bbIds[i] === this.bbIds[12]) {
              document.getElementById(this.elementIds[i]).style.backgroundColor = this.savedBackgroundLayer;
            }
            this.updateCheck();
        } else {
          console.log("nothing");
          console.log(this.bbIds[i]);
          document.getElementById(this.elementIds[i]).innerHTML = "";
          this.updateCheck();
        }
      }
    }
  }

  onSavebi() {
    if (this.biForm.invalid) {
      return;
    }
    this.editorpageService.updateAboutpageBackgroundImage(
      this.userId,
      this.biForm.value.image
    );
  }

  onbiImagePicked(event: Event) {
    document.getElementById("biSaveButton").style.display = "inline-block";
    const file = (event.target as HTMLInputElement).files[0];
    this.biForm.patchValue({ image: file });
    this.biForm.get('image').updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.biimagePreview = reader.result as string;
      console.log(this.biimagePreview);
      document.getElementById("ebackgroundPicDiv0").style.backgroundImage = "url('" + this.biimagePreview + "')";
    };
    reader.readAsDataURL(file);
  }

  onSavep1() {
    if (this.p1Form.invalid) {
      return;
    }
    this.editorpageService.updateAboutpagePhotoOne(
      this.userId,
      this.p1Form.value.image
    );
  }

  onp1ImagePicked(event: Event) {
    document.getElementById("p1SaveButton").style.display = "inline-block";
    const file = (event.target as HTMLInputElement).files[0];
    this.p1Form.patchValue({ image: file });
    this.p1Form.get('image').updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.p1imagePreview = reader.result as string;
      console.log(this.p1imagePreview);
      document.getElementById("eleftColumn").style.backgroundImage = "url('" + this.p1imagePreview + "')";
    };
    reader.readAsDataURL(file);
  }

  

  setctText(ctTextForm: NgForm) {
    if (ctTextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.ctDirty = false;
    console.log(ctTextForm.value.text);
    document.getElementById("ebackgroundPicDiv0Text").innerHTML = ctTextForm.value.text;
    document.getElementById("ebackgroundPicDiv0Text").style.padding = "130px";
    document.getElementById("clearctTextInput").click();
    this.updateCheck();
  }

  ctState() {
    document.getElementById("ebackgroundPicDiv0Text").innerHTML = "";
    document.getElementById("ebackgroundPicDiv0Text").style.padding = "0px";
    document.getElementById("ebackgroundPicDiv0Text").style.marginBottom = "0px";
    this.ctDirty = true;
  }

  setctFont(id: string) {
    if (this.icon1Form.invalid) {
      return;
    }
    console.log(this.icon1Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon1Form.value.fontFamily;
    this.elementFonts[0] = this.icon1Form.value.fontFamily;
    this.updateCheck();
  }

  setptText(ptTextForm: NgForm) {
    if (ptTextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.ptDirty = false;
    console.log(ptTextForm.value.text);
    document.getElementById("epicText").innerHTML = ptTextForm.value.text;
    document.getElementById("clearptTextInput").click();
    this.updateCheck();
  }

  ptState() {
    document.getElementById("epicText").innerHTML = "";
    this.ptDirty = true;
  }

  setptFont(id: string) {
    if (this.icon2Form.invalid) {
      return;
    }
    console.log(this.icon2Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon2Form.value.fontFamily;
    this.elementFonts[1] = this.icon2Form.value.fontFamily;
    this.updateCheck();
  }

  sets2h1Text(s2h1TextForm: NgForm) {
    if (s2h1TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.s2h1Dirty = false;
    console.log(s2h1TextForm.value.text);
    document.getElementById("esection2Header1").innerHTML = s2h1TextForm.value.text;
    document.getElementById("clears2h1TextInput").click();
    this.updateCheck();
  }

  s2h1State() {
    document.getElementById("esection2Header1").innerHTML = "";
    this.s2h1Dirty = true;
  }

  sets2h1Font(id: string) {
    if (this.icon3Form.invalid) {
      return;
    }
    console.log(this.icon3Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon3Form.value.fontFamily;
    this.elementFonts[2] = this.icon3Form.value.fontFamily;
    this.updateCheck();
  }

  sets2t1Text(s2t1TextForm: NgForm) {
    if (s2t1TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.s2t1Dirty = false;
    console.log(s2t1TextForm.value.text);
    document.getElementById("esection2Text1").innerHTML = s2t1TextForm.value.text;
    document.getElementById("clears2t1TextInput").click();
    this.updateCheck();
  }

  s2t1State() {
    document.getElementById("esection2Text1").innerHTML = "";
    this.s2t1Dirty = true;
  }

  sets2t1Font(id: string) {
    if (this.icon4Form.invalid) {
      return;
    }
    console.log(this.icon4Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon4Form.value.fontFamily;
    this.elementFonts[3] = this.icon4Form.value.fontFamily;
    this.updateCheck();
  }

  sets2t2Text(s2t2TextForm: NgForm) {
    if (s2t2TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.s2t2Dirty = false;
    console.log(s2t2TextForm.value.text);
    document.getElementById("esection2Text2").innerHTML = s2t2TextForm.value.text;
    document.getElementById("clears2t2TextInput").click();
    this.updateCheck();
  }

  s2t2State() {
    document.getElementById("esection2Text2").innerHTML = "";
    this.s2t2Dirty = true;
  }

  sets2t2Font(id: string) {
    if (this.icon5Form.invalid) {
      return;
    }
    console.log(this.icon5Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon5Form.value.fontFamily;
    this.elementFonts[4] = this.icon5Form.value.fontFamily;
    this.updateCheck();
  }

  sets2h2Text(s2h2TextForm: NgForm) {
    if (s2h2TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.s2h2Dirty = false;
    console.log(s2h2TextForm.value.text);
    document.getElementById("esection2Header2").innerHTML = s2h2TextForm.value.text;
    document.getElementById("clears2h2TextInput").click();
    this.updateCheck();
  }

  s2h2State() {
    document.getElementById("esection2Header2").innerHTML = "";
    this.s2h2Dirty = true;
  }

  sets2h2Font(id: string) {
    if (this.icon6Form.invalid) {
      return;
    }
    console.log(this.icon6Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon6Form.value.fontFamily;
    this.elementFonts[5] = this.icon6Form.value.fontFamily;
    this.updateCheck();
  }

  sets2t3Text(s2t3TextForm: NgForm) {
    if (s2t3TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.s2t3Dirty = false;
    console.log(s2t3TextForm.value.text);
    document.getElementById("esection2Text3").innerHTML = s2t3TextForm.value.text;
    document.getElementById("clears2t3TextInput").click();
    this.updateCheck();
  }

  s2t3State() {
    document.getElementById("esection2Text3").innerHTML = "";
    this.s2t3Dirty = true;
  }

  sets2t3Font(id: string) {
    if (this.icon7Form.invalid) {
      return;
    }
    console.log(this.icon7Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon7Form.value.fontFamily;
    this.elementFonts[6] = this.icon7Form.value.fontFamily;
    this.updateCheck();
  }

  sets2h3Text(s2h3TextForm: NgForm) {
    if (s2h3TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.s2h3Dirty = false;
    console.log(s2h3TextForm.value.text);
    document.getElementById("esection2Header3").innerHTML = s2h3TextForm.value.text;
    document.getElementById("clears2h3TextInput").click();
    this.updateCheck();
  }

  s2h3State() {
    document.getElementById("esection2Header3").innerHTML = "";
    this.s2h3Dirty = true;
  }

  sets2h3Font(id: string) {
    if (this.icon8Form.invalid) {
      return;
    }
    console.log(this.icon8Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon8Form.value.fontFamily;
    this.elementFonts[7] = this.icon8Form.value.fontFamily;
    this.updateCheck();
  }

  updateCheck() {
    let updated = false;

    this.editorpageService.getAboutpage().subscribe(data => {
      if (document.getElementById("ebackgroundPicDiv0Text").innerHTML !== data.aboutpage.titleText){
        updated = true;
      } else if (document.getElementById("epicText").innerHTML !== data.aboutpage.rightSideText){
        updated = true;
      } else if (document.getElementById("esection2Header1").innerHTML !== data.aboutpage.headerOne){
        updated = true;
      } else if (document.getElementById("esection2Header2").innerHTML !== data.aboutpage.headerTwo){
        updated = true;
      } else if (document.getElementById("esection2Header3").innerHTML !== data.aboutpage.headerThree){
        updated = true;
      } else if (document.getElementById("esection2Text1").innerHTML !== data.aboutpage.paragraphOne){
        updated = true;
      } else if (document.getElementById("esection2Text2").innerHTML !== data.aboutpage.paragraphTwo){
        updated = true;
      } else if (document.getElementById("esection2Text3").innerHTML !== data.aboutpage.paragraphThree){
        updated = true;
      } else if (document.getElementById("ebackgroundPicDiv1").style.backgroundColor !== data.aboutpage.section1Color){
        updated = true;
      } else if (document.getElementById("ehomepageSection2").style.backgroundColor !== data.aboutpage.section2Color){
        updated = true;
      } else if (document.getElementById("backgroundLayer").style.backgroundColor !== data.aboutpage.backgroundLayer){
        updated = true;
      }

      for (var i = 0; i < this.elementFonts.length; i++) {
        if (this.elementFonts[i] !== data.aboutpage.elementFonts[i]) {
          updated = true;
        }
      }

      for (var i = 0; i < this.elementColors.length; i++) {//here
        if (this.elementColors[i] !== data.aboutpage.elementColors[i]) {
          updated = true;
        }
      }

      for (var i = 0; i < this.elementFontSizes.length; i++) {
        if (this.elementFontSizes[i] !== data.aboutpage.elementFontSizes[i]) {
          updated = true;
        }
      }

      if (updated === true) {
        document.getElementById("edSave").style.backgroundColor = "#ffffff";
      } else {
        document.getElementById("edSave").style.backgroundColor = "#eeeeee";
      }
    });

    
  }

  setFontSize(id: string, event: Event) {
    let size = event.target['innerText'].split('C')[0].trim();
    document.getElementById(id).style.fontSize = size;
    for (var i = 0; i < this.textElements.length; i++) {
      if (id === this.textElements[i]) {
        this.elementFontSizes[i] = size;
      }
    }
    this.updateCheck();
  }

  setbpd1Color(value: string) {
    document.getElementById("bpd1ColorInput").style.color = "black";
    document.getElementById("ebackgroundPicDiv1").style.backgroundColor = value;
    this.updateCheck();
  }

  seths2Color(value: string) {
    document.getElementById("hs2ColorInput").style.color = "black";
    document.getElementById("ehomepageSection2").style.backgroundColor = value;
    this.updateCheck();
  }

  setBlColor(value: string) {
    document.getElementById("blColorInput").style.color = "black";
    document.getElementById("backgroundLayer").style.backgroundColor = value;
    this.updateCheck();
  }
  
  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
  }

}
