import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { HomepageComponent } from './homepage/homepage.component';
import { AboutpageComponent } from './aboutpage/aboutpage.component';
import { ContactpageComponent } from './contactpage/contactpage.component';
import { ContacteditorComponent } from './Editorpage/contacteditor/contacteditor.component';
import { AbouteditorComponent } from './Editorpage/abouteditor/abouteditor.component';
import { EditorpageComponent } from './Editorpage/editorpage.component';
import { LoginComponent } from './auth/login/login.component';
import { OnboardComponent } from './auth/onboard/onboard.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { PlansComponent } from './auth/plans/plans.component';
import { SuccessComponent } from './auth/success/success.component';
import { CancelComponent } from './auth/cancel/cancel.component';
import { AccountComponent } from './auth/account/account.component';
import {TestpageComponent } from './testpage/testpage.component';
import { AuthGuard } from './auth/auth.guard';


const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'landing', component: LandingpageComponent },
  { path: 'plans', component: PlansComponent, canActivate: [AuthGuard] },
  { path: 'success/:checkoutId', component: SuccessComponent },
  { path: 'cancel', component: CancelComponent },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
  { path: 'homepage', component: HomepageComponent },
  { path: 'aboutpage', component: AboutpageComponent },
  { path: 'contactpage', component: ContactpageComponent },
  { path: 'editor', component: EditorpageComponent, canActivate: [AuthGuard] },
  { path: 'contact', component: ContacteditorComponent, canActivate: [AuthGuard] },
  { path: 'about', component: AbouteditorComponent, canActivate: [AuthGuard] },
  { path: 'onboard', component: OnboardComponent },
  // { path: 'create', component: GameComponent, canActivate: [AuthGuard] },
  // { path: 'edit/:postId', component: PostCreateComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'test', component: TestpageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
