import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';

import {AuthService} from '../auth.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
 
  constructor(public authService: AuthService, private router: Router) {}

  ngOnInit () {
    document.getElementById("initloader").style.display = "none";
    document.getElementById("hToolbar").style.width = "100%";
    document.getElementById("hToolbar").style.right = "unset";
    document.getElementById("hToolbar").style.zIndex = "unset";
    document.getElementById("hToolbar").style.position = "relative";
    document.getElementById("previewItem").style.display = "none";

    //somewhere here I need to handle if this is a valid subdomain. Do I tell user and leave them on the 
    //domain or redirect them?

    document.getElementById("headerTitleText").style.display = "none";
    document.getElementById("logo").style.backgroundImage = 
        'url(' + 'https://realestate-images-folder.s3.us-east-2.amazonaws.com/1604181768630-1604181774696.png' + ')';
    document.getElementById("logo").style.display = "block";
    document.getElementById("hToolbar").style.backgroundColor = "#ffffff";
  }

  goToSignUp() {
    this.router.navigate(['/onboard']);
  }

  onLogin(form: NgForm) {
    if (form.invalid) {
      return;
    }
    if (document.getElementById("loginError").style.display === "block") {
      document.getElementById("loginError").style.display = "none";
    }
    document.getElementById("loginFormItems").style.display = "none";
    document.getElementById("loginSpinner").style.display = "block";
    this.authService.login(form.value.email, form.value.password);
  }
}
