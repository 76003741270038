import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

import { Homepage } from './homepage.model';
import { Contactpage } from './contacteditor/contactpage.model';
import { Aboutpage } from './abouteditor/aboutpage.model';


@Injectable({ providedIn: 'root' })
export class EditorpageService {
  private homepage: Homepage;
  private homepageUpdated = new Subject<Homepage>();
  private idInFocus = '';
  activeSubdomain = '';

  constructor(private http: HttpClient, private router: Router) { }


  //THIS IS THE HOME PAGE SECTION

  setSubdomain(sub: string) {
    this.activeSubdomain = sub;
  }

  getSubdomain() {
    return this.activeSubdomain;
  }

  getHomepageUpdateListener() {
    return this.homepageUpdated.asObservable();
  }

  getHomepage() {
    // tslint:disable-next-line: max-line-length
    return this.http.get<{ homepage: any }>(
      'api/homepage/'
    );
    // return this.http.get<{ _id: string, email: string, headerSetting: string, brandLogoPath: string, headerColor: string, bannerColor: string, section2Color: string, backgroundImagePath: string, brandText: string, card1Text1: string, card1Text2: string, card1Text3: string, card2Text1: string, bannerText: string, card3Text1: string, card3Text2: string, card3Text3: string, card3Text4: string, card3PhoneNumber: string, bullet1: string, bullet2: string, bullet3: string, bullet4: string, bullet5: string, bullet6: string, bullet7: string, bullet8: string }>(
    //   'api/homepage/'
    // );
  }

  getHomepageBySubdomain(subdomain: string) {
    // tslint:disable-next-line: max-line-length
    return this.http.get<{ homepage: any }>(
      'api/homepage/sub/' + subdomain
    );
  }
  
  getHomepageByCustomDomain(customdomain: string) {
    // tslint:disable-next-line: max-line-length
    return this.http.get<{ homepage: any }>(
      'api/homepage/custom/' + customdomain
    );
  }

  updateHomepageBackgroundImage(id: string, image: File | string) {
    let homepageBackgroundImageData: {
      id: string,
      backgroundImagePath: string;
    } | FormData;
    if (typeof image === 'object') {
      homepageBackgroundImageData = new FormData();
      homepageBackgroundImageData.append('id', id);
      homepageBackgroundImageData.append('image', image, Date.now().toString());
    } else {
      homepageBackgroundImageData = {
        id: id,
        backgroundImagePath: image
      };
    }
    this.http
      .post('api/homepage/backgroundImage/' + id, homepageBackgroundImageData)
      .subscribe(response => {
        //this.router.navigate(['/']);
      });
  }

  updateHomepageHeaderImage(id: string, image: File | string) {
    let homepageHeaderImageData: {
      id: string,
      brandLogoPath: string;
    } | FormData;
    if (typeof image === 'object') {
      homepageHeaderImageData = new FormData();
      homepageHeaderImageData.append('id', id);
      homepageHeaderImageData.append('image', image, Date.now().toString());
    } else {
      homepageHeaderImageData = {
        id: id,
        brandLogoPath: image
      };
    }
    return this.http
      .post<{ message: string; }>('api/homepage/headerImage/' + id, homepageHeaderImageData);
  }

  updateHomepageTestimonialPic1(id: string, image: File | string) {
    let homepageTP1Data: {
      id: string,
      testimonialPic1: string;
    } | FormData;
    if (typeof image === 'object') {
      homepageTP1Data = new FormData();
      homepageTP1Data.append('id', id);
      homepageTP1Data.append('image', image, Date.now().toString());
    } else {
      homepageTP1Data = {
        id: id,
        testimonialPic1: image
      };
    }
    console.log("joubi");
    console.log(id);
    return this.http
      .post<{ message: string; }>('api/homepage/t1/' + id, homepageTP1Data)
      .subscribe(response => {
        //this.router.navigate(['/']);
      });;
  }

  updateHomepageTestimonialPic2(id: string, image: File | string) {
    let homepageTP2Data: {
      id: string,
      testimonialPic2: string;
    } | FormData;
    if (typeof image === 'object') {
      homepageTP2Data = new FormData();
      homepageTP2Data.append('id', id);
      homepageTP2Data.append('image', image, Date.now().toString());
    } else {
      homepageTP2Data = {
        id: id,
        testimonialPic2: image
      };
    }

    console.log("pic 2 editorpage")
    return this.http
      .post<{ message: string; }>('api/homepage/t2/' + id, homepageTP2Data)
      .subscribe(response => {
        //this.router.navigate(['/']);
      });;;
  }

  updateHomepageTestimonialPic3(id: string, image: File | string) {
    let homepageTP3Data: {
      id: string,
      testimonialPic3: string;
    } | FormData;
    if (typeof image === 'object') {
      homepageTP3Data = new FormData();
      homepageTP3Data.append('id', id);
      homepageTP3Data.append('image', image, Date.now().toString());
    } else {
      homepageTP3Data = {
        id: id,
        testimonialPic3: image
      };
    }

    console.log("pic 3 editorpage");
    console.log(id);
    return this.http
      .post<{ message: string; }>('api/homepage/t3/' + id, homepageTP3Data)
      .subscribe(response => {
        //this.router.navigate(['/']);
      });;;
  }

  updateHomepageTestimonialPic4(id: string, image: File | string) {
    let homepageTP4Data: {
      id: string,
      testimonialPic4: string;
    } | FormData;
    if (typeof image === 'object') {
      homepageTP4Data = new FormData();
      homepageTP4Data.append('id', id);
      homepageTP4Data.append('image', image, Date.now().toString());
    } else {
      homepageTP4Data = {
        id: id,
        testimonialPic4: image
      };
    }

    console.log("pic 4 editorpage");
    return this.http
      .post<{ message: string; }>('api/homepage/t4/' + id, homepageTP4Data)
      .subscribe(response => {
        //this.router.navigate(['/']);
      });;;
  }

  updateHomepageTestimonialPic5(id: string, image: File | string) {
    let homepageTP5Data: {
      id: string,
      testimonialPic5: string;
    } | FormData;
    if (typeof image === 'object') {
      homepageTP5Data = new FormData();
      homepageTP5Data.append('id', id);
      homepageTP5Data.append('image', image, Date.now().toString());
    } else {
      homepageTP5Data = {
        id: id,
        testimonialPic5: image
      };
    }

    console.log("pic 5 editorpage");
    return this.http
      .post<{ message: string; }>('api/homepage/t5/' + id, homepageTP5Data)
      .subscribe(response => {
        //this.router.navigate(['/']);
      });
  }

  updateHomepageText(id: string, email: string, headerSetting: string, headerColor: string, bannerColor: string, section2Color: string, brandText: string, card1Text1: string, card1Text2: string, card1Text3: string, card2Text1: string, bannerText: string, card3Text1: string, card3Text2: string, card3Text3: string, card3Text4: string, card3PhoneNumber: string, bullet1: string, bullet2: string, bullet3: string, bullet4: string, bullet5: string, bullet6: string, bullet7: string, bullet8: string, testimonialName1: string, 
    testimonialText1: string, testimonialName2: string, testimonialText2: string, testimonialName3: string, testimonialText3: string, testimonialName4: string, testimonialText4: string, testimonialName5: string, testimonialText5: string, elementFonts: string[], backgroundLayer: string, elementColors: string[], elementFontSizes: string[], otherColors: string[]) {
    
      let homepageTextData: Homepage;
    homepageTextData = {
      id: id,
      email: email,
      headerSetting: headerSetting,
      brandLogoPath: "",
      headerColor: headerColor,
      bannerColor: bannerColor,
      section2Color: section2Color,
      backgroundImagePath: "",
      brandText: brandText,
      card1Text1: card1Text1,
      card1Text2: card1Text2,
      card1Text3: card1Text3,
      card2Text1: card2Text1,
      bannerText: bannerText,
      card3Text1: card3Text1,
      card3Text2: card3Text2,
      card3Text3: card3Text3,
      card3Text4: card3Text4,
      card3PhoneNumber: card3PhoneNumber,
      bullet1: bullet1,
      bullet2: bullet2,
      bullet3: bullet3,
      bullet4: bullet4,
      bullet5: bullet5,
      bullet6: bullet6,
      bullet7: bullet7,
      bullet8: bullet8,
      testimonialName1: testimonialName1,
      testimonialPic1:  "",
      testimonialText1: testimonialText1,
      testimonialName2: testimonialName2, 
      testimonialPic2:  "",
      testimonialText2: testimonialText2,
      testimonialName3: testimonialName3, 
      testimonialPic3:  "",
      testimonialText3: testimonialText3,
      testimonialName4: testimonialName4, 
      testimonialPic4:  "",
      testimonialText4: testimonialText4,
      testimonialName5: testimonialName5, 
      testimonialPic5:  "",
      testimonialText5: testimonialText5,
      elementFonts: elementFonts,
      backgroundLayer: backgroundLayer,
      elementColors: elementColors,
      elementFontSizes: elementFontSizes,
      otherColors: otherColors
    };
    return this.http
      .put<{ message: string; }>('api/homepage/text', homepageTextData);
  }

  createLead(name: string, email: string, phoneNumber: string, address: string, userId: string, leadEmail: string) {
    let leadData: {
      name: string,
      email: string,
      phoneNumber: string,
      address: string,
      userId: string,
      leadEmail: string
    };
    leadData = {
      name: name, 
      email: email, 
      phoneNumber: phoneNumber, 
      address: address,
      userId: userId,
      leadEmail: leadEmail
    }
    return this.http
      .post<{ message: string; lead: any; leadId: string; }>('api/homepage/lead/' + userId, leadData);
  }



  //THIS IS THE CONTACT PAGE SECTION

  getContactpage() {
    return this.http.get<{ contactpage: any }>(
      'api/homepage/contactpage/'
    );
  }

  getContactpageBySubdomain(subdomain: string) {
    return this.http.get<{ contactpage: any }>(
      'api/homepage/contactpage/sub/' + subdomain
    );
  }

  getContactpageByCustomDomain(customdomain: string) {
    return this.http.get<{ contactpage: any }>(
      'api/homepage/contactpage/custom/' + customdomain
    );
  }

  updateContactpageBackgroundImage(id: string, image: File | string) {
    let contactpageBackgroundImageData: {
      id: string,
      backgroundImagePath: string;
    } | FormData;
    if (typeof image === 'object') {
      contactpageBackgroundImageData = new FormData();
      contactpageBackgroundImageData.append('id', id);
      contactpageBackgroundImageData.append('image', image, Date.now().toString());
    } else {
      contactpageBackgroundImageData = {
        id: id,
        backgroundImagePath: image
      };
    }
    this.http
      .post('api/homepage/contactpage/backgroundImage/' + id, contactpageBackgroundImageData)
      .subscribe(response => {
        //this.router.navigate(['/']);
      });
  }

  updateContactpageText(id: string, email: string, visitIconColor: string,
    callIconColor: string, section1Color:string, section2Color:string, formColor:string, 
    titleText:string, visitText:string, callText:string, messageHeaderText:string, addressText:string, 
    phoneNumberText:string, showAddress:boolean, showPhoneNumber:boolean, elementFonts: string[], 
    backgroundLayer: string, elementFontSizes: string[], elementColors: string[]) {

    
    let contactpageTextData: Contactpage;
      contactpageTextData = {
      id: id,
      email: email,
      backgroundImagePath: "",
      visitIconColor: visitIconColor,
      callIconColor: callIconColor,
      section1Color: section1Color,
      section2Color: section2Color,
      formColor: formColor,
      titleText: titleText,
      visitText: visitText,
      callText: callText,
      messageHeaderText: messageHeaderText,
      addressText: addressText,
      phoneNumberText: phoneNumberText,
      showAddress: showAddress,
      showPhoneNumber: showPhoneNumber,
      elementFonts: elementFonts,
      backgroundLayer: backgroundLayer,
      elementFontSizes: elementFontSizes,
      elementColors: elementColors
    };
    return this.http
      .put<{ message: string; }>('api/homepage/contactpage/text', contactpageTextData);
  }

  createQuestion(name: string, email: string, phoneNumber: string, message: string, userId: string, toEmail: string) {
    let questionData: {
      name: string,
      email: string,
      phoneNumber: string,
      message: string,
      userId: string,
      toEmail: string
    };
    questionData = {
      name: name, 
      email: email, 
      phoneNumber: phoneNumber, 
      message: message,
      userId: userId,
      toEmail: toEmail
    }
    return this.http
      .post<{ message: string; question: any; questionId: string; }>('api/homepage/contactpage/question/' + userId, questionData);
  }

  //THIS IS THE ABOUT PAGE SECTION

  getAboutpage() {
    return this.http.get<{ aboutpage: any }>(
      'api/homepage/aboutpage/'
    );
  }

  getAboutpageBySubdomain(subdomain: string) {
    return this.http.get<{ aboutpage: any }>(
      'api/homepage/aboutpage/sub/' + subdomain
    );
  }

  getAboutpageByCustomDomain(customdomain: string) {
    return this.http.get<{ aboutpage: any }>(
      'api/homepage/aboutpage/custom/' + customdomain
    );
  }

  updateAboutpageBackgroundImage(id: string, image: File | string) {
    let aboutpageBackgroundImageData: {
      id: string,
      backgroundImagePath: string;
    } | FormData;
    if (typeof image === 'object') {
      aboutpageBackgroundImageData = new FormData();
      aboutpageBackgroundImageData.append('id', id);
      aboutpageBackgroundImageData.append('image', image, Date.now().toString());
    } else {
      aboutpageBackgroundImageData = {
        id: id,
        backgroundImagePath: image
      };
    }
    this.http
      .post('api/homepage/aboutpage/backgroundImage/' + id, aboutpageBackgroundImageData)
      .subscribe(response => {
        //this.router.navigate(['/']);
      });
  }

  updateAboutpagePhotoOne(id: string, image: File | string) {
    let aboutpagePhotoOneData: {
      id: string,
      photoOne: string;
    } | FormData;
    if (typeof image === 'object') {
      aboutpagePhotoOneData = new FormData();
      aboutpagePhotoOneData.append('id', id);
      aboutpagePhotoOneData.append('image', image, Date.now().toString());
    } else {
      aboutpagePhotoOneData = {
        id: id,
        photoOne: image
      };
    }
    this.http
      .post('api/homepage/aboutpage/photoOne/' + id, aboutpagePhotoOneData)
      .subscribe(response => {
        //this.router.navigate(['/']);
      });
  }
  
  updateAboutpageText(id: string, email: string,
    section1Color:string, section2Color:string, titleText:string, rightSideText:string, headerOne:string,
    paragraphOne:string, paragraphTwo:string, headerTwo:string, paragraphThree:string, headerThree:string,
    elementFonts: string[], backgroundLayer: string, elementFontSizes: string[], elementColors: string[]) {
    
    let aboutpageTextData: Aboutpage;
      aboutpageTextData = {
      id: id,
      email: email,
      backgroundImagePath: "",
      photoOne: "",
      section1Color: section1Color,
      section2Color: section2Color,
      titleText: titleText,
      rightSideText: rightSideText,
      headerOne: headerOne,
      paragraphOne: paragraphOne,
      paragraphTwo: paragraphTwo,
      headerTwo: headerTwo,
      paragraphThree: paragraphThree,
      headerThree: headerThree,
      elementFonts: elementFonts,
      backgroundLayer: backgroundLayer,
      elementFontSizes: elementFontSizes,
      elementColors: elementColors
    };
    return this.http
      .put<{ message: string; }>('api/homepage/aboutpage/text', aboutpageTextData);
  }

}

