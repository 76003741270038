import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';


import { AuthService } from 'src/app/auth/auth.service';
import { EditorpageService } from 'src/app/Editorpage/editorpage.service';


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit, OnDestroy {
  recursionExit = false;
  subdomainActive = false;
  customdomainActive = false;
  backgroundImagePath = "";
  brandLogoPath = "";
  slideCount = 1;
  title1 = ""; title2 = ""; title3 = ""; topRightText = ""; 
  headerDiv1Text = ""; br1 = ""; br2 = ""; br3 = ""; br4 = "";
  pN = ""; bul1 = ""; bul2 = ""; bul3 = ""; bul4 = ""; bul5 = "";
  bul6 = ""; bul7 = ""; bul8 = "";
  testimonialPic1 = ""; testimonialName1 = ""; testimonialText1 = "";
  testimonialPic2 = ""; testimonialName2 = ""; testimonialText2 = "";
  testimonialPic3 = ""; testimonialName3 = ""; testimonialText3 = "";
  testimonialPic4 = ""; testimonialName4 = ""; testimonialText4 = "";
  testimonialPic5 = ""; testimonialName5 = ""; testimonialText5 = "";
  textElements: string[] = ["title1","title2", "title3", "topRightText", "headerDiv1Text",
    "bul1", "bul2", "bul3", "bul4", "bul5", "bul6", "bul7", "bul8", "br1", "br2", "br3", "br4", "pN",
    "testimonialTitle", "testimonialName1", "testimonialText1", "testimonialName2", "testimonialText2", "testimonialName3",
    "testimonialText3", "testimonialName4", "testimonialText4", "testimonialName5", "testimonialText5"];
  elementToColor: string[] = ["eleftColumn", ".f1i", "--f1pc", "eformHolder", ".f2i", "--f2pc", 
    "etopRightDiv", "esubButton1", "esubButton2" ];
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  isLoading = false;
  userId = "";
  leadEmail: "";
  liveForm: FormGroup;
  liveForm2: FormGroup;
  headerSetting: string;
  activeTestimonial = 0;

  constructor( 
    public authService: AuthService,
    public editorpageService: EditorpageService,
    public route: ActivatedRoute,
    public router: Router,
    private elementRef: ElementRef  
    ) {}

  ngOnInit() {
    document.getElementById("initloader").style.display = "none";
    document.getElementById("hToolbar").style.width = "100%";
    document.getElementById("hToolbar").style.right = "unset";
    document.getElementById("hToolbar").style.zIndex = "unset";
    document.getElementById("hToolbar").style.position = "relative";
    document.getElementById("previewItem").style.display = "none";
    
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
    .getAuthStatusListener()
    .subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
    });

    //instead use req.subdomain on backend 
    // if www and middle === upgoeditor go to landing. Can handle this on front end and save time.
    // if other subdomain check against list of domains and if found send back apt data. Not, go to landing.
    // if www and middle === a domain on the list I check against send back apt data. Not, go to landing.

    this.liveForm = new FormGroup({
      name: new FormControl(null, { validators: [Validators.required] }),
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email /* , Validators.minLength(3) */]
      }),
      phoneNumber: new FormControl(null, { validators: [Validators.required] }),
      address: new FormControl(null, { validators: [Validators.required] })
    });

    this.liveForm2 = new FormGroup({
      name: new FormControl(null, { validators: [Validators.required] }),
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email /* , Validators.minLength(3) */]
      }),
      phoneNumber: new FormControl(null, { validators: [Validators.required] }),
      address: new FormControl(null, { validators: [Validators.required] })
    });

    if (location.protocol === "http:") {
      console.log('http correct');
      location.href = 'https://' + location.host;
    }

    // Store location host to check if there is a subdomain other than www
    let subdomain = location.host.split(".")[0];
    let customdomain = location.host.split(".")[1];
    console.log(subdomain);
    console.log(customdomain);


    // If user is not logged in, if they are on the main site, show them the landing page.
    if (!this.userIsAuthenticated) {
      if (location.host.substring(0,14) === 'www.upgoeditor') {
        this.router.navigate(['/landing']);
        return;
      } else if (subdomain !== 'www') {
        this.subdomainActive = true;
      } else if (customdomain !== 'upgoeditor') {
        this.customdomainActive = true;
      }
    }
    // If user is not logged in, show spinner while we decide to retrieve data or redirect.
    // If user isn't logged in and subdomain is active, then show user data without logging in
    if (!this.userIsAuthenticated) {
      document.getElementById("pageDiv").style.visibility = "hidden";
      document.getElementById("spinner").style.display = "block";
    }


    if (this.subdomainActive === false) {
      if (this.customdomainActive === false) {
        this.editorpageService.getHomepage().subscribe(data => {
          document.getElementById("spinner").style.display = "none";
          document.getElementById("pageDiv").style.visibility = "visible";
          console.log("HOMEPAGE DATA");
          console.log(data);
          this.userId = data.homepage.userId;
          this.leadEmail = data.homepage.email;
          this.brandLogoPath = data.homepage.brandLogoPath;
          console.log(this.brandLogoPath);
          if (this.brandLogoPath !== "") {
            document.getElementById("logo").style.backgroundImage = 
            'url(' + this.brandLogoPath + ')';
          }
          this.backgroundImagePath = data.homepage.backgroundImagePath;
          console.log(this.backgroundImagePath);
          if (this.backgroundImagePath !== "") {
            document.getElementById("backgroundPicDiv1").style.backgroundImage = 
            'url(' + this.backgroundImagePath + ')';
          }
          document.getElementById("backgroundLayer").style.backgroundColor = data.homepage.backgroundLayer;
          document.getElementById("hToolbar").style.backgroundColor = data.homepage.headerColor;
          document.getElementById("headerDiv1").style.backgroundColor = data.homepage.bannerColor;
          document.getElementById("homepageSection2").style.backgroundColor = data.homepage.section2Color;
          document.getElementById("headerTitleText").innerHTML = data.homepage.brandText;
          document.getElementById("title1").innerHTML = data.homepage.card1Text1;
          document.getElementById("title2").innerHTML = data.homepage.card1Text2;
          document.getElementById("title3").innerHTML = data.homepage.card1Text3;
          document.getElementById("topRightText").innerHTML = data.homepage.card2Text1;
          document.getElementById("headerDiv1Text").innerHTML = data.homepage.bannerText;
          document.getElementById("br1").innerHTML = data.homepage.card3Text1;
          document.getElementById("br2").innerHTML = data.homepage.card3Text2;
          document.getElementById("br3").innerHTML = data.homepage.card3Text3;
          document.getElementById("br4").innerHTML = data.homepage.card3Text4;
          document.getElementById("pN").innerHTML = data.homepage.card3PhoneNumber;
          document.getElementById("bul1").innerHTML = data.homepage.bullet1;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv1").style.display = "none";
          }
          document.getElementById("bul2").innerHTML = data.homepage.bullet2;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv2").style.display = "none";
          }
          document.getElementById("bul3").innerHTML = data.homepage.bullet3;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv3").style.display = "none";
          }
          document.getElementById("bul4").innerHTML = data.homepage.bullet4;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv4").style.display = "none";
          }
          document.getElementById("bul5").innerHTML = data.homepage.bullet5;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv5").style.display = "none";
          }
          document.getElementById("bul6").innerHTML = data.homepage.bullet6;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv6").style.display = "none";
          }
          document.getElementById("bul7").innerHTML = data.homepage.bullet7;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv7").style.display = "none";
          }
          document.getElementById("bul8").innerHTML = data.homepage.bullet8;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv8").style.display = "none";
          }

          this.headerSetting = data.homepage.headerSetting;
          if (this.headerSetting === "text") {
            document.getElementById("headerTitleText").style.display = "block";
            document.getElementById("logo").style.display = "none";
          } else if (this.headerSetting === "image") {
            document.getElementById("headerTitleText").style.display = "none";
            document.getElementById("logo").style.display = "block";
          }

          //add testimonial data population
          //document.getElementById("testimonialName1").innerHTML = data.homepage.testimonialName1;
          this.testimonialName1 = data.homepage.testimonialName1;

          this.testimonialPic1 = data.homepage.testimonialPic1;
          document.getElementById("testimonialPicDiv1").style.backgroundImage = 'url(' + this.testimonialPic1 + ')';
          this.testimonialText1 = data.homepage.testimonialText1;
          this.testimonialName2 = data.homepage.testimonialName2;

          this.testimonialPic2 = data.homepage.testimonialPic2;
          this.testimonialText2 = data.homepage.testimonialText2;
          this.testimonialName3 = data.homepage.testimonialName3;

          this.testimonialPic3 = data.homepage.testimonialPic3;
          this.testimonialText3 = data.homepage.testimonialText3;
          this.testimonialName4 = data.homepage.testimonialName4;

          this.testimonialPic4 = data.homepage.testimonialPic4;
          this.testimonialText4 = data.homepage.testimonialText4;
          this.testimonialName5 = data.homepage.testimonialName5;

          this.testimonialPic5 = data.homepage.testimonialPic5;
          this.testimonialText5 = data.homepage.testimonialText5;

          console.log(this.activeTestimonial);    
          this.alternateTestimonials();

          // code to set the font
          for (var i = 0; i < this.textElements.length; i++) {
            document.getElementById(this.textElements[i]).style.fontFamily = data.homepage.elementFonts[i];
          }

          // code to set the font
          for (var i = 0; i < this.textElements.length; i++) {
            document.getElementById(this.textElements[i]).style.color = data.homepage.elementColors[i];
          }

          // code to set the font size
          for (var i = 0; i < this.textElements.length; i++) {
            document.getElementById(this.textElements[i]).style.fontSize = data.homepage.elementFontSizes[i];
          }

          for (var i = 0; i < data.homepage.otherColors.length; i++) {
            if (this.elementToColor[i].charAt(0) === ".") {
              var querySelectors = document.querySelectorAll<HTMLElement>(this.elementToColor[i]);
              for (var j = 0; j < querySelectors.length; j++) {
                querySelectors[j].style.backgroundColor = data.homepage.otherColors[i];
              }
            }
    
            if (this.elementToColor[i].charAt(0) === "-") {
              this.elementRef.nativeElement.style.setProperty(this.elementToColor[i], data.homepage.otherColors[i]);
            }
        
            if (this.elementToColor[i].charAt(0) === "e") {
              var res = this.elementToColor[i].substring(1, this.elementToColor[i].length);
              document.getElementById(res).style.backgroundColor = data.homepage.otherColors[i];
            }
          } 

        }, (e) => {
          this.router.navigate(['/landing']);
          console.log('IN ERROR BLOCK');
          console.log(e.error.message);
          if (e.error.message === 'Auth failed!') {
            console.log("auth failure caught");
          }
        });
      } else if (this.customdomainActive === true) {
        console.log("in customdomain active");
        this.editorpageService.getHomepageByCustomDomain(customdomain).subscribe(data => {
          document.getElementById("spinner").style.display = "none";
          document.getElementById("pageDiv").style.visibility = "visible";
          console.log("HOMEPAGE DATA");
          console.log(data);
          this.userId = data.homepage.userId;
          this.leadEmail = data.homepage.email;
          this.brandLogoPath = data.homepage.brandLogoPath;
          console.log(this.brandLogoPath);
          if (this.brandLogoPath !== "") {
            document.getElementById("logo").style.backgroundImage = 
            'url(' + this.brandLogoPath + ')';
          }
          this.backgroundImagePath = data.homepage.backgroundImagePath;
          console.log(this.backgroundImagePath);
          if (this.backgroundImagePath !== "") {
            document.getElementById("backgroundPicDiv1").style.backgroundImage = 
            'url(' + this.backgroundImagePath + ')';
          }
          document.getElementById("backgroundLayer").style.backgroundColor = data.homepage.backgroundLayer;
          document.getElementById("hToolbar").style.backgroundColor = data.homepage.headerColor;
          document.getElementById("headerDiv1").style.backgroundColor = data.homepage.bannerColor;
          document.getElementById("homepageSection2").style.backgroundColor = data.homepage.section2Color;
          document.getElementById("headerTitleText").innerHTML = data.homepage.brandText;
          document.getElementById("title1").innerHTML = data.homepage.card1Text1;
          document.getElementById("title2").innerHTML = data.homepage.card1Text2;
          document.getElementById("title3").innerHTML = data.homepage.card1Text3;
          document.getElementById("topRightText").innerHTML = data.homepage.card2Text1;
          document.getElementById("headerDiv1Text").innerHTML = data.homepage.bannerText;
          document.getElementById("br1").innerHTML = data.homepage.card3Text1;
          document.getElementById("br2").innerHTML = data.homepage.card3Text2;
          document.getElementById("br3").innerHTML = data.homepage.card3Text3;
          document.getElementById("br4").innerHTML = data.homepage.card3Text4;
          document.getElementById("pN").innerHTML = data.homepage.card3PhoneNumber;
          document.getElementById("bul1").innerHTML = data.homepage.bullet1;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv1").style.display = "none";
          }
          document.getElementById("bul2").innerHTML = data.homepage.bullet2;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv2").style.display = "none";
          }
          document.getElementById("bul3").innerHTML = data.homepage.bullet3;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv3").style.display = "none";
          }
          document.getElementById("bul4").innerHTML = data.homepage.bullet4;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv4").style.display = "none";
          }
          document.getElementById("bul5").innerHTML = data.homepage.bullet5;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv5").style.display = "none";
          }
          document.getElementById("bul6").innerHTML = data.homepage.bullet6;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv6").style.display = "none";
          }
          document.getElementById("bul7").innerHTML = data.homepage.bullet7;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv7").style.display = "none";
          }
          document.getElementById("bul8").innerHTML = data.homepage.bullet8;
          if (data.homepage.bullet1 === "") {
            document.getElementById("listDiv8").style.display = "none";
          }

          this.headerSetting = data.homepage.headerSetting;
          if (this.headerSetting === "text") {
            document.getElementById("headerTitleText").style.display = "block";
            document.getElementById("logo").style.display = "none";
          } else if (this.headerSetting === "image") {
            document.getElementById("headerTitleText").style.display = "none";
            document.getElementById("logo").style.display = "block";
          }

          //add testimonial data population
          //document.getElementById("testimonialName1").innerHTML = data.homepage.testimonialName1;
          this.testimonialName1 = data.homepage.testimonialName1;

          this.testimonialPic1 = data.homepage.testimonialPic1;
          document.getElementById("testimonialPicDiv1").style.backgroundImage = 'url(' + this.testimonialPic1 + ')';
          this.testimonialText1 = data.homepage.testimonialText1;
          this.testimonialName2 = data.homepage.testimonialName2;

          this.testimonialPic2 = data.homepage.testimonialPic2;
          this.testimonialText2 = data.homepage.testimonialText2;
          this.testimonialName3 = data.homepage.testimonialName3;

          this.testimonialPic3 = data.homepage.testimonialPic3;
          this.testimonialText3 = data.homepage.testimonialText3;
          this.testimonialName4 = data.homepage.testimonialName4;

          this.testimonialPic4 = data.homepage.testimonialPic4;
          this.testimonialText4 = data.homepage.testimonialText4;
          this.testimonialName5 = data.homepage.testimonialName5;

          this.testimonialPic5 = data.homepage.testimonialPic5;
          this.testimonialText5 = data.homepage.testimonialText5;

          console.log(this.activeTestimonial);    
          this.alternateTestimonials();

          // code to set the font
          for (var i = 0; i < this.textElements.length; i++) {
            document.getElementById(this.textElements[i]).style.fontFamily = data.homepage.elementFonts[i];
          }

          // code to set the font
          for (var i = 0; i < this.textElements.length; i++) {
            document.getElementById(this.textElements[i]).style.color = data.homepage.elementColors[i];
          }

          for (var i = 0; i < this.textElements.length; i++) {
            document.getElementById(this.textElements[i]).style.fontSize = data.homepage.elementFontSizes[i];
          }

          for (var i = 0; i < data.homepage.otherColors.length; i++) {
            if (this.elementToColor[i].charAt(0) === ".") {
              var querySelectors = document.querySelectorAll<HTMLElement>(this.elementToColor[i]);
              for (var j = 0; j < querySelectors.length; j++) {
                querySelectors[j].style.backgroundColor = data.homepage.otherColors[i];
              }
            }
    
            if (this.elementToColor[i].charAt(0) === "-") {
              this.elementRef.nativeElement.style.setProperty(this.elementToColor[i], data.homepage.otherColors[i]);
            }
        
            if (this.elementToColor[i].charAt(0) === "e") {
              var res = this.elementToColor[i].substring(1, this.elementToColor[i].length);
              document.getElementById(res).style.backgroundColor = data.homepage.otherColors[i];
            }
          } 

        }, (e) => {
          this.router.navigate(['/landing']);
          console.log('IN ERROR BLOCK');
          console.log(e.error.message);
          if (e.error.message === 'Auth failed!') {
            console.log("auth failure caught");
          }
        });
      }
      
    } else if (this.subdomainActive === true) {
      console.log("in subdomain active");
      this.editorpageService.getHomepageBySubdomain(subdomain).subscribe(data => {
        document.getElementById("spinner").style.display = "none";
          document.getElementById("pageDiv").style.visibility = "visible";
        console.log("HOMEPAGE DATA");
        console.log(data);
        this.userId = data.homepage.userId;
        this.leadEmail = data.homepage.email;
        this.brandLogoPath = data.homepage.brandLogoPath;
        console.log(this.brandLogoPath);
        if (this.brandLogoPath !== "") {
          document.getElementById("logo").style.backgroundImage = 
          'url(' + this.brandLogoPath + ')';
        }
        this.backgroundImagePath = data.homepage.backgroundImagePath;
        console.log(this.backgroundImagePath);
        if (this.backgroundImagePath !== "") {
          document.getElementById("backgroundPicDiv1").style.backgroundImage = 
          'url(' + this.backgroundImagePath + ')';
        }
        document.getElementById("backgroundLayer").style.backgroundColor = data.homepage.backgroundLayer;
        document.getElementById("hToolbar").style.backgroundColor = data.homepage.headerColor;
        document.getElementById("headerDiv1").style.backgroundColor = data.homepage.bannerColor;
        document.getElementById("homepageSection2").style.backgroundColor = data.homepage.section2Color;
        document.getElementById("headerTitleText").innerHTML = data.homepage.brandText;
        document.getElementById("title1").innerHTML = data.homepage.card1Text1;
        document.getElementById("title2").innerHTML = data.homepage.card1Text2;
        document.getElementById("title3").innerHTML = data.homepage.card1Text3;
        document.getElementById("topRightText").innerHTML = data.homepage.card2Text1;
        document.getElementById("headerDiv1Text").innerHTML = data.homepage.bannerText;
        document.getElementById("br1").innerHTML = data.homepage.card3Text1;
        document.getElementById("br2").innerHTML = data.homepage.card3Text2;
        document.getElementById("br3").innerHTML = data.homepage.card3Text3;
        document.getElementById("br4").innerHTML = data.homepage.card3Text4;
        document.getElementById("pN").innerHTML = data.homepage.card3PhoneNumber;
        document.getElementById("bul1").innerHTML = data.homepage.bullet1;
        if (data.homepage.bullet1 === "") {
          document.getElementById("listDiv1").style.display = "none";
        }
        document.getElementById("bul2").innerHTML = data.homepage.bullet2;
        if (data.homepage.bullet1 === "") {
          document.getElementById("listDiv2").style.display = "none";
        }
        document.getElementById("bul3").innerHTML = data.homepage.bullet3;
        if (data.homepage.bullet1 === "") {
          document.getElementById("listDiv3").style.display = "none";
        }
        document.getElementById("bul4").innerHTML = data.homepage.bullet4;
        if (data.homepage.bullet1 === "") {
          document.getElementById("listDiv4").style.display = "none";
        }
        document.getElementById("bul5").innerHTML = data.homepage.bullet5;
        if (data.homepage.bullet1 === "") {
          document.getElementById("listDiv5").style.display = "none";
        }
        document.getElementById("bul6").innerHTML = data.homepage.bullet6;
        if (data.homepage.bullet1 === "") {
          document.getElementById("listDiv6").style.display = "none";
        }
        document.getElementById("bul7").innerHTML = data.homepage.bullet7;
        if (data.homepage.bullet1 === "") {
          document.getElementById("listDiv7").style.display = "none";
        }
        document.getElementById("bul8").innerHTML = data.homepage.bullet8;
        if (data.homepage.bullet1 === "") {
          document.getElementById("listDiv8").style.display = "none";
        }

        this.headerSetting = data.homepage.headerSetting;
        if (this.headerSetting === "text") {
          document.getElementById("headerTitleText").style.display = "block";
          document.getElementById("logo").style.display = "none";
        } else if (this.headerSetting === "image") {
          document.getElementById("headerTitleText").style.display = "none";
          document.getElementById("logo").style.display = "block";
        }

        //add testimonial data population
        //document.getElementById("testimonialName1").innerHTML = data.homepage.testimonialName1;
        this.testimonialName1 = data.homepage.testimonialName1;

        this.testimonialPic1 = data.homepage.testimonialPic1;
        document.getElementById("testimonialPicDiv1").style.backgroundImage = 'url(' + this.testimonialPic1 + ')';
        this.testimonialText1 = data.homepage.testimonialText1;
        this.testimonialName2 = data.homepage.testimonialName2;

        this.testimonialPic2 = data.homepage.testimonialPic2;
        this.testimonialText2 = data.homepage.testimonialText2;
        this.testimonialName3 = data.homepage.testimonialName3;

        this.testimonialPic3 = data.homepage.testimonialPic3;
        this.testimonialText3 = data.homepage.testimonialText3;
        this.testimonialName4 = data.homepage.testimonialName4;

        this.testimonialPic4 = data.homepage.testimonialPic4;
        this.testimonialText4 = data.homepage.testimonialText4;
        this.testimonialName5 = data.homepage.testimonialName5;

        this.testimonialPic5 = data.homepage.testimonialPic5;
        this.testimonialText5 = data.homepage.testimonialText5;

        console.log(this.activeTestimonial);    
        this.alternateTestimonials();

        // code to set the font
        for (var i = 0; i < this.textElements.length; i++) {
          document.getElementById(this.textElements[i]).style.fontFamily = data.homepage.elementFonts[i];
        }

        // code to set the font
        for (var i = 0; i < this.textElements.length; i++) {
          document.getElementById(this.textElements[i]).style.color = data.homepage.elementColors[i];
        }

        for (var i = 0; i < this.textElements.length; i++) {
          document.getElementById(this.textElements[i]).style.fontSize = data.homepage.elementFontSizes[i];
        }

        for (var i = 0; i < data.homepage.otherColors.length; i++) {
          if (this.elementToColor[i].charAt(0) === ".") {
            var querySelectors = document.querySelectorAll<HTMLElement>(this.elementToColor[i]);
            for (var j = 0; j < querySelectors.length; j++) {
              querySelectors[j].style.backgroundColor = data.homepage.otherColors[i];
            }
          }
  
          if (this.elementToColor[i].charAt(0) === "-") {
            this.elementRef.nativeElement.style.setProperty(this.elementToColor[i], data.homepage.otherColors[i]);
          }
      
          if (this.elementToColor[i].charAt(0) === "e") {
            var res = this.elementToColor[i].substring(1, this.elementToColor[i].length);
            document.getElementById(res).style.backgroundColor = data.homepage.otherColors[i];
          }
        } 

      }, (e) => {
        //this.router.navigate(['/landing']);
        location.href = "http://www.upgoeditor.com/";
        console.log('IN ERROR BLOCK');
        console.log(e.error.message);
        if (e.error.message === 'Auth failed!') {
          console.log("auth failure caught");
        }
      });
    }
  }

  alternateTestimonials() {

    if ((this.activeTestimonial + 1) > 5) {
      this.activeTestimonial = 1;
    } else {
      this.activeTestimonial = this.activeTestimonial + 1;
    }
    if (this.activeTestimonial === 1) {
      if (this.recursionExit === false) {
        document.getElementById("t1").style.display = "block";
        document.getElementById("t2").style.display = "none";
        document.getElementById("t3").style.display = "none";
        document.getElementById("t4").style.display = "none";
        document.getElementById("t5").style.display = "none";
        document.getElementById("testimonialPicDiv1").style.backgroundImage = 'url(' + this.testimonialPic1 + ')';
      }
    } 
    if (this.activeTestimonial === 2) {
      if (this.recursionExit === false) {
        document.getElementById("t1").style.display = "none";
        document.getElementById("t2").style.display = "block";
        document.getElementById("t3").style.display = "none";
        document.getElementById("t4").style.display = "none";
        document.getElementById("t5").style.display = "none";
        document.getElementById("testimonialPicDiv2").style.backgroundImage = 'url(' + this.testimonialPic2 + ')';
      }
    }
    if (this.activeTestimonial === 3) {
      if (this.recursionExit === false) {
        document.getElementById("t1").style.display = "none";
        document.getElementById("t2").style.display = "none";
        document.getElementById("t3").style.display = "block";
        document.getElementById("t4").style.display = "none";
        document.getElementById("t5").style.display = "none";
        document.getElementById("testimonialPicDiv3").style.backgroundImage = 'url(' + this.testimonialPic3 + ')';
      }
    }
    if (this.activeTestimonial === 4) {
      if (this.recursionExit === false) {
        document.getElementById("t1").style.display = "none";
        document.getElementById("t2").style.display = "none";
        document.getElementById("t3").style.display = "none";
        document.getElementById("t4").style.display = "block";
        document.getElementById("t5").style.display = "none";
        document.getElementById("testimonialPicDiv4").style.backgroundImage = 'url(' + this.testimonialPic4 + ')';
      }
    }
    if (this.activeTestimonial === 5) {
      if (this.recursionExit === false) {
        document.getElementById("t1").style.display = "none";
        document.getElementById("t2").style.display = "none";
        document.getElementById("t3").style.display = "none";
        document.getElementById("t4").style.display = "none";
        document.getElementById("t5").style.display = "block";
        document.getElementById("testimonialPicDiv5").style.backgroundImage = 'url(' + this.testimonialPic5 + ')';
      }
    }  

    setTimeout(() => {
      this.alternateTestimonials();
    }, 3000);
  }

  onSaveLiveForm() {
    if (this.liveForm.invalid) {
      console.log('invalid');
      return;
    }

    console.log(this.liveForm.value.name);
    console.log(this.liveForm.value.email);
    console.log(this.liveForm.value.phoneNumber);
    console.log(this.liveForm.value.address);
  }
  
  // onSaveLiveForm() {
  //   if (this.liveForm.invalid) {
  //     window.alert('You must fix an error in the form before you submit.');
  //     return;
  //   }
  //   this.editorpageService.createLead(
  //     this.liveForm.value.name,
  //     this.liveForm.value.email,
  //     this.liveForm.value.phoneNumber,
  //     this.liveForm.value.address,
  //     this.userId,
  //     this.leadEmail
  //   ).subscribe(data => {
  //     if (data.message = "Lead created successfully") {
  //       window.alert("Your form was received and we will contact you soon.");
  //     }
  //   });
  //   this.liveForm.reset();
  // }

  onSaveLiveForm2() {
    if (this.liveForm2.invalid) {
      window.alert('You must fix an error in the form before you submit.');
      return;
    }
    this.editorpageService.createLead(
      this.liveForm2.value.name,
      this.liveForm2.value.email,
      this.liveForm2.value.phoneNumber,
      this.liveForm2.value.address,
      this.userId,
      this.leadEmail
    ).subscribe(data => {
      if (data.message = "Lead created successfully") {
        window.alert("Your form was received and we will contact you soon.");
      }
    });
    this.liveForm2.reset();
  }

  


  ngOnDestroy() {
    this.recursionExit = true;
    this.authListenerSubs.unsubscribe();
  }

}
