import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';


import { AuthService } from 'src/app/auth/auth.service';
import { mimeType } from './mime-type.validator';
import { EditorpageService } from './editorpage.service';


@Component({
  selector: 'app-editorpage',
  templateUrl: './editorpage.component.html',
  styleUrls: ['./editorpage.component.css']
})
export class EditorpageComponent implements OnInit, OnDestroy {
  // bulletBinder = {// Use invisible buttons to manipulate this object and delete divs
  //   b1: " ", b2: " ", b3: " ", b4: " ", b5: " ", b6: " ", b7: " ", b8: " "
  // }
  elementIds: string[] = ["eheaderDiv1", "ehomepageSection2", "etitle1", "ebackgroundPicDiv1",
    "etitle2", "etitle3", "etopRightText",
    "eheaderDiv1Text", "brh1", "brh2",
    "brh3", "brh4", "epN",
    "bullet1", "bullet2", "bullet3",
    "bullet4", "bullet5", "bullet6",
    "bullet7", "bullet8", "hToolbar", "headerTitleText", "etestimonialName1", "etestimonialText1",
    "etestimonialName2", "etestimonialText2", "etestimonialName3", "etestimonialText3", 
    "etestimonialName4", "etestimonialText4", "etestimonialName5", "etestimonialText5", 
    "etestimonialPicDiv1", "etestimonialPicDiv2", "etestimonialPicDiv3", "etestimonialPicDiv4", 
    "etestimonialPicDiv5", "backgroundLayer" ];
  bbIds: string[] = ["bannerEditDiv", "s2EditDiv", "h2OneEditDiv", "bi1EditDiv",
    "h3OneEditDiv", "h3TwoEditDiv", "trEditDiv",
    "mbEditDiv", "brh1EditDiv", "brh2EditDiv",
    "brh3EditDiv", "brh4EditDiv", "epNEditDiv",
    "b1EditDiv", "b2EditDiv", "b3EditDiv",
    "b4EditDiv", "b5EditDiv", "b6EditDiv",
    "b7EditDiv", "b8EditDiv", "headerEditDiv", "headerTextEditDiv", "tn1EditDiv", "tt1EditDiv",
    "tn2EditDiv", "tt2EditDiv", "tn3EditDiv", "tt3EditDiv", "tn4EditDiv", "tt4EditDiv",
    "tn5EditDiv", "tt5EditDiv", "pd1EditDiv", "pd2EditDiv", "pd3EditDiv", "pd4EditDiv", "pd5EditDiv", "blEditDiv"];
  elementFontSizes: string[] = [];
  savedElementFontSizes: string[] = [];
  elementFonts: string[] = [];
  savedElementFonts: string[] = [];
  elementColors: string[] = [];
  savedElementColors: string[] = [];
  otherColors: string[] = [];
  savedOtherColors: string[] = [];
  textElements: string[] = ["etitle1","etitle2", "etitle3", "etopRightText", "eheaderDiv1Text",
    "bullet1", "bullet2", "bullet3", "bullet4", "bullet5", "bullet6", "bullet7", "bullet8", "brh1", "brh2", "brh3", "brh4", "epN",
    "etestimonialTitle", "etestimonialName1", "etestimonialText1", "etestimonialName2", "etestimonialText2", "etestimonialName3",
    "etestimonialText3", "etestimonialName4", "etestimonialText4", "etestimonialName5", "etestimonialText5"];
  elementToColor: string[] = ["eleftColumn", ".f1i", "--f1pc", "eformHolder", ".f2i", "--f2pc", 
    "etopRightDiv", "esubButton1", "esubButton2" ];
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  userId = "";
  leadEmail: "";
  brandLogoPath = "";
  backgroundImagePath = "";
  testimonialPic1 = "";
  testimonialPic2 = "";
  testimonialPic3 = "";
  testimonialPic4 = "";
  testimonialPic5 = "";
  imagePreview: string;
  hi1imagePreview: string;
  pd1imagePreview: string;
  pd2imagePreview: string;
  pd3imagePreview: string;
  pd4imagePreview: string;
  pd5imagePreview: string;
  isLoading = false;
  editorForm: FormGroup;
  editorForm2: FormGroup;
  imageForm: FormGroup;
  hi1Form: FormGroup;
  pd1Form: FormGroup;
  pd2Form: FormGroup;
  pd3Form: FormGroup;
  pd4Form: FormGroup;
  pd5Form: FormGroup;
  blColorForm: FormGroup;
  colorForm: FormGroup;
  bannerColorForm: FormGroup;
  headerTextForm: FormGroup;
  section2ColorForm: FormGroup;
  pageForm: FormGroup;
  icon1Form: FormGroup;
  icon2Form: FormGroup;
  icon3Form: FormGroup;
  icon4Form: FormGroup;
  icon5Form: FormGroup;
  icon6Form: FormGroup;
  icon7Form: FormGroup;
  icon8Form: FormGroup;
  icon9Form: FormGroup;
  icon10Form: FormGroup;
  icon11Form: FormGroup;
  icon12Form: FormGroup;
  icon13Form: FormGroup;
  icon14Form: FormGroup;
  icon15Form: FormGroup;
  icon16Form: FormGroup;
  icon17Form: FormGroup;
  icon18Form: FormGroup;
  icon19Form: FormGroup;
  icon20Form: FormGroup;
  icon21Form: FormGroup;
  icon22Form: FormGroup;
  icon23Form: FormGroup;
  icon24Form: FormGroup;
  icon25Form: FormGroup;
  icon26Form: FormGroup;
  icon27Form: FormGroup;
  icon28Form: FormGroup;
  icon29Form: FormGroup;
  tcForm1: FormGroup; tcForm2: FormGroup; tcForm3: FormGroup;
  tcForm4: FormGroup; tcForm5: FormGroup; tcForm6: FormGroup;
  tcForm7: FormGroup; tcForm8: FormGroup; tcForm9: FormGroup;
  tcForm10: FormGroup; tcForm11: FormGroup; tcForm12: FormGroup;
  tcForm13: FormGroup; tcForm14: FormGroup; tcForm15: FormGroup;
  tcForm16: FormGroup; tcForm17: FormGroup; tcForm18: FormGroup;
  tcForm19: FormGroup; tcForm20: FormGroup; tcForm21: FormGroup;
  tcForm22: FormGroup; tcForm23: FormGroup; tcForm24: FormGroup;
  tcForm25: FormGroup; tcForm26: FormGroup; tcForm27: FormGroup;
  tcForm28: FormGroup; tcForm29: FormGroup;
  
  ecForm1: FormGroup; ecForm2: FormGroup; ecForm3: FormGroup;
  ecForm4: FormGroup; ecForm5: FormGroup; ecForm6: FormGroup; 
  ecForm7: FormGroup; ecForm8: FormGroup; ecForm9: FormGroup;

  fsForm1: FormGroup; fsForm2: FormGroup; fsForm3: FormGroup;
  fsForm4: FormGroup; fsForm5: FormGroup; fsForm6: FormGroup;
  fsForm7: FormGroup; fsForm8: FormGroup; fsForm9: FormGroup;
  fsForm10: FormGroup; fsForm11: FormGroup; fsForm12: FormGroup;
  fsForm13: FormGroup; fsForm14: FormGroup; fsForm15: FormGroup;
  fsForm16: FormGroup; fsForm17: FormGroup; fsForm18: FormGroup;
  fsForm19: FormGroup; fsForm20: FormGroup; fsForm21: FormGroup;
  fsForm22: FormGroup; fsForm23: FormGroup; fsForm24: FormGroup;
  fsForm25: FormGroup; fsForm26: FormGroup; fsForm27: FormGroup;
  fsForm28: FormGroup; fsForm29: FormGroup;

  colorEditorBool = true;
  textEditorBool = false;
  testimonialEditorBool = false;
  fontEditorBool = false;
  headerSetting: string;
  h2OneDirty = false;//new data section
  h3OneDirty = false;
  h3TwoDirty = false;
  trDirty = false;
  mbDirty = false;
  brh1Dirty = false;
  brh2Dirty = false;
  brh3Dirty = false;
  brh4Dirty = false;
  epNDirty = false;
  b1Dirty = false; b2Dirty = false; b3Dirty = false; b4Dirty = false;
  b5Dirty = false; b6Dirty = false; b7Dirty = false; b8Dirty = false;
  tn1Dirty = false; tt1Dirty = false; tn2Dirty = false; tt2Dirty = false;
  tn3Dirty = false; tt3Dirty = false; tn4Dirty = false; tt4Dirty = false;
  tn5Dirty = false; tt5Dirty = false;
  savedHToolbar: string; savedEHeaderDiv1: string; savedEHomepageSection2: string; //new data section
  savedHeaderTitleText: string; savedETitle1: string; savedETitle2: string;
  savedETitle3: string; savedETopRightText: string; savedEHeaderDiv1Text: string;
  savedBrh1: string; savedBrh2: string; savedBrh3: string; savedBrh4: string;
  savedEpN: string; savedBullet1: string; savedBullet2: string; savedBullet3: string;
  savedBullet4: string; savedBullet5: string; savedBullet6: string; savedBullet7: string; savedBullet8: string;
  savedTestimonialName1: string; savedTestimonialText1: string; savedTestimonialName2: string; 
  savedTestimonialText2: string; savedTestimonialName3: string; savedTestimonialText3: string; 
  savedTestimonialName4: string; savedTestimonialText4: string; savedTestimonialName5: string; 
  savedTestimonialText5: string; savedBackgroundLayer: string; 


  constructor(
    public authService: AuthService,
    public editorpageService: EditorpageService,
    public route: ActivatedRoute,
    public router: Router,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    document.getElementById("initloader").style.display = "none";
    document.getElementById("hToolbar").style.width = "78%";
    document.getElementById("hToolbar").style.right = "0%";
    document.getElementById("hToolbar").style.zIndex = "2";
    document.getElementById("hToolbar").style.position = "fixed";
    document.getElementById("previewItem").style.display = "block";

    document.getElementById("edColor").style.background = "transparent";
    document.getElementById("edColor").style.border = "1px solid white";

    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
    });

    // code to set the font
    // var allText = document.querySelectorAll<HTMLElement>(".font");
    // for (var i = 0; i < allText.length; i++) {
    //   allText.item(i).style.fontFamily = "Spartan-Regular";
    // }

    this.editorpageService.getHomepage().subscribe(data => {//new data
      console.log("HOMEPAGE DATA");
      console.log(data);

      for (var i = 0; i < data.homepage.elementFonts.length; i++) {
        this.elementFonts[i] = data.homepage.elementFonts[i];
        this.savedElementFonts[i] = data.homepage.elementFonts[i];
        document.getElementById(this.textElements[i]).style.fontFamily = data.homepage.elementFonts[i];
      }

      for (var i = 0; i < data.homepage.elementColors.length; i++) {
        this.elementColors[i] = data.homepage.elementColors[i];
        this.savedElementColors[i] = data.homepage.elementColors[i];
        document.getElementById(this.textElements[i]).style.color = data.homepage.elementColors[i];
      }

      for (var i = 0; i < data.homepage.elementFontSizes.length; i++) {
        this.elementFontSizes[i] = data.homepage.elementFontSizes[i];
        this.savedElementFontSizes[i] = data.homepage.elementFontSizes[i];
        document.getElementById(this.textElements[i]).style.fontSize = data.homepage.elementFontSizes[i];
      }

      for (var i = 0; i < data.homepage.otherColors.length; i++) {
        if (this.elementToColor[i].charAt(0) === ".") {
          var querySelectors = document.querySelectorAll<HTMLElement>(this.elementToColor[i]);
          for (var j = 0; j < querySelectors.length; j++) {
            querySelectors[j].style.backgroundColor = data.homepage.otherColors[i];
          }
          this.otherColors[i] = data.homepage.otherColors[i];
          this.savedOtherColors[i] = data.homepage.otherColors[i];
        }

        if (this.elementToColor[i].charAt(0) === "-") {
          this.elementRef.nativeElement.style.setProperty(this.elementToColor[i], data.homepage.otherColors[i]);
          this.otherColors[i] = data.homepage.otherColors[i];
          this.savedOtherColors[i] = data.homepage.otherColors[i];
        }
    
        if (this.elementToColor[i].charAt(0) === "e") {
          document.getElementById(this.elementToColor[i]).style.backgroundColor = data.homepage.otherColors[i];
          this.otherColors[i] = data.homepage.otherColors[i];
          this.savedOtherColors[i] = data.homepage.otherColors[i];
        }
      } 
      
      this.userId = data.homepage.userId;
      this.leadEmail = data.homepage.email;
      this.brandLogoPath = data.homepage.brandLogoPath;
      console.log(this.brandLogoPath);
      if (this.brandLogoPath !== "") {
        document.getElementById("logo").style.backgroundImage = 
        'url(' + this.brandLogoPath + ')';
        console.log(document.getElementById("logo").style.backgroundImage);
      }
      this.backgroundImagePath = data.homepage.backgroundImagePath;
      console.log(this.backgroundImagePath);
      if (this.backgroundImagePath !== "") {
        document.getElementById("ebackgroundPicDiv1").style.backgroundImage = 
        'url(' + this.backgroundImagePath + ')';
        console.log(document.getElementById("ebackgroundPicDiv1").style.backgroundImage);
      }
      document.getElementById("backgroundLayer").style.backgroundColor = data.homepage.backgroundLayer;
      this.savedBackgroundLayer = data.homepage.backgroundLayer;
      document.getElementById("hToolbar").style.backgroundColor = data.homepage.headerColor;
      this.savedHToolbar = data.homepage.headerColor;
      document.getElementById("eheaderDiv1").style.backgroundColor = data.homepage.bannerColor;
      this.savedEHeaderDiv1 = data.homepage.bannerColor;
      document.getElementById("ehomepageSection2").style.backgroundColor = data.homepage.section2Color;
      this.savedEHomepageSection2 = data.homepage.section2Color;
      document.getElementById("headerTitleText").innerHTML = data.homepage.brandText;
      this.savedHeaderTitleText = data.homepage.brandText;
      document.getElementById("etitle1").innerHTML = data.homepage.card1Text1;
      this.savedETitle1 = data.homepage.card1Text1;
      document.getElementById("etitle2").innerHTML = data.homepage.card1Text2;
      this.savedETitle2 = data.homepage.card1Text2;
      document.getElementById("etitle3").innerHTML = data.homepage.card1Text3;
      this.savedETitle3 = data.homepage.card1Text3;
      document.getElementById("etopRightText").innerHTML = data.homepage.card2Text1;
      this.savedETopRightText = data.homepage.card2Text1;
      document.getElementById("eheaderDiv1Text").innerHTML = data.homepage.bannerText;
      this.savedEHeaderDiv1Text = data.homepage.bannerText;
      document.getElementById("brh1").innerHTML = data.homepage.card3Text1;
      this.savedBrh1 = data.homepage.card3Text1;
      document.getElementById("brh2").innerHTML = data.homepage.card3Text2;
      this.savedBrh2 = data.homepage.card3Text2;
      document.getElementById("brh3").innerHTML = data.homepage.card3Text3;
      this.savedBrh3 = data.homepage.card3Text3;
      document.getElementById("brh4").innerHTML = data.homepage.card3Text4;
      this.savedBrh4 = data.homepage.card3Text4;
      document.getElementById("epN").innerHTML = data.homepage.card3PhoneNumber;
      this.savedEpN = data.homepage.card3PhoneNumber;
      //this.bulletBinder.b1 = data.homepage.bullet1;
      if (data.homepage.bullet1 !== "") {
        document.getElementById("elistDiv1").style.display = "block";
      } else {
        document.getElementById("elistDiv1").style.display = "none";
      }
      document.getElementById("bullet1").innerHTML = data.homepage.bullet1;
      this.savedBullet1 = data.homepage.bullet1;
      //this.bulletBinder.b2 = data.homepage.bullet2;
      if (data.homepage.bullet2 !== "") {
        document.getElementById("elistDiv2").style.display = "block";
      } else {
        document.getElementById("elistDiv2").style.display = "none";
      }
      document.getElementById("bullet2").innerHTML = data.homepage.bullet2;
      this.savedBullet2 = data.homepage.bullet2;
      //this.bulletBinder.b3 = data.homepage.bullet3;
      if (data.homepage.bullet3 !== "") {
        document.getElementById("elistDiv3").style.display = "block";
      } else {
        document.getElementById("elistDiv3").style.display = "none";
      }
      document.getElementById("bullet3").innerHTML = data.homepage.bullet3;
      this.savedBullet3 = data.homepage.bullet3;
      //this.bulletBinder.b4 = data.homepage.bullet4;
      if (data.homepage.bullet4 !== "") {
        document.getElementById("elistDiv4").style.display = "block";
      } else {
        document.getElementById("elistDiv4").style.display = "none";
      }
      document.getElementById("bullet4").innerHTML = data.homepage.bullet4;
      this.savedBullet4 = data.homepage.bullet4;
      //this.bulletBinder.b5 = data.homepage.bullet5;
      if (data.homepage.bullet5 !== "") {
        document.getElementById("elistDiv5").style.display = "block";
      } else {
        document.getElementById("elistDiv5").style.display = "none";
      }
      document.getElementById("bullet5").innerHTML = data.homepage.bullet5;
      this.savedBullet5 = data.homepage.bullet5;
      //this.bulletBinder.b6 = data.homepage.bullet6;
      if (data.homepage.bullet6 !== "") {
        document.getElementById("elistDiv6").style.display = "block";
      } else {
        document.getElementById("elistDiv6").style.display = "none";
      }
      document.getElementById("bullet6").innerHTML = data.homepage.bullet6;
      this.savedBullet6 = data.homepage.bullet6;
      //this.bulletBinder.b7 = data.homepage.bullet7;
      if (data.homepage.bullet7 !== "") {
        document.getElementById("elistDiv7").style.display = "block";
      } else {
        document.getElementById("elistDiv7").style.display = "none";
      }
      document.getElementById("bullet7").innerHTML = data.homepage.bullet7;
      this.savedBullet7 = data.homepage.bullet7;
      //this.bulletBinder.b8 = data.homepage.bullet8;
      if (data.homepage.bullet8 !== "") {
        document.getElementById("elistDiv8").style.display = "block";
      } else {
        document.getElementById("elistDiv8").style.display = "none";
      }
      document.getElementById("bullet8").innerHTML = data.homepage.bullet8;
      this.savedBullet8 = data.homepage.bullet8;

      this.headerSetting = data.homepage.headerSetting;
      if (this.headerSetting === "text") {
        document.getElementById("headerTitleText").style.display = "block";
        document.getElementById("logo").style.display = "none";
      } else if (this.headerSetting === "image") {
        document.getElementById("headerTitleText").style.display = "none";
        document.getElementById("logo").style.display = "block";
      }
      //add testimonial data population
      document.getElementById("etestimonialName1").innerHTML = data.homepage.testimonialName1;
      this.savedTestimonialName1 = data.homepage.testimonialName1;

      this.testimonialPic1 = data.homepage.testimonialPic1;
      console.log(this.testimonialPic1);
      if (this.testimonialPic1 !== "") {
        document.getElementById("etestimonialPicDiv1").style.backgroundImage = 
        'url(' + this.testimonialPic1 + ')';
        console.log(document.getElementById("etestimonialPicDiv1").style.backgroundImage);
      }

      document.getElementById("etestimonialText1").innerHTML = data.homepage.testimonialText1;
      this.savedTestimonialText1 = data.homepage.testimonialText1;
      document.getElementById("etestimonialName2").innerHTML = data.homepage.testimonialName2;
      this.savedTestimonialName2 = data.homepage.testimonialName2;

      this.testimonialPic2 = data.homepage.testimonialPic2;
      console.log(this.testimonialPic2);
      if (this.testimonialPic2 !== "") {
        document.getElementById("etestimonialPicDiv2").style.backgroundImage = 
        'url(' + this.testimonialPic2 + ')';
        console.log(document.getElementById("etestimonialPicDiv2").style.backgroundImage);
      }

      document.getElementById("etestimonialText2").innerHTML = data.homepage.testimonialText2;
      this.savedTestimonialText2 = data.homepage.testimonialText2;
      document.getElementById("etestimonialName3").innerHTML = data.homepage.testimonialName3;
      this.savedTestimonialName3 = data.homepage.testimonialName3;

      this.testimonialPic3 = data.homepage.testimonialPic3;
      console.log(this.testimonialPic3);
      if (this.testimonialPic3 !== "") {
        document.getElementById("etestimonialPicDiv3").style.backgroundImage =
          'url(' + this.testimonialPic3 + ')';
        console.log(document.getElementById("etestimonialPicDiv3").style.backgroundImage);
      }

      document.getElementById("etestimonialText3").innerHTML = data.homepage.testimonialText3;
      this.savedTestimonialText3 = data.homepage.testimonialText3;
      document.getElementById("etestimonialName4").innerHTML = data.homepage.testimonialName4;
      this.savedTestimonialName4 = data.homepage.testimonialName4;

      this.testimonialPic4 = data.homepage.testimonialPic4;
      console.log(this.testimonialPic4);
      if (this.testimonialPic4 !== "") {
        document.getElementById("etestimonialPicDiv4").style.backgroundImage = 
        'url(' + this.testimonialPic4 + ')';
        console.log(document.getElementById("etestimonialPicDiv4").style.backgroundImage);
      }

      document.getElementById("etestimonialText4").innerHTML = data.homepage.testimonialText4;
      this.savedTestimonialText4 = data.homepage.testimonialText4;
      document.getElementById("etestimonialName5").innerHTML = data.homepage.testimonialName5;
      this.savedTestimonialName5 = data.homepage.testimonialName5;

      this.testimonialPic5 = data.homepage.testimonialPic5;
      console.log(this.testimonialPic5);
      if (this.testimonialPic5 !== "") {
        document.getElementById("etestimonialPicDiv5").style.backgroundImage = 
        'url(' + this.testimonialPic5 + ')';
        console.log(document.getElementById("etestimonialPicDiv5").style.backgroundImage);
      }

      document.getElementById("etestimonialText5").innerHTML = data.homepage.testimonialText5;
      this.savedTestimonialText5 = data.homepage.testimonialText5;
      this.editorpageService.setSubdomain(data.homepage.subdomain);

    });

    this.editorForm = new FormGroup({
      name: new FormControl(null, { validators: [Validators.required] }),
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email /* , Validators.minLength(3) */]
      }),
      phoneNumber: new FormControl(null, { validators: [Validators.required] }),
      address: new FormControl(null, { validators: [Validators.required] })
    });

    this.editorForm2 = new FormGroup({
      name: new FormControl(null, { validators: [Validators.required] }),
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email /* , Validators.minLength(3) */]
      }),
      phoneNumber: new FormControl(null, { validators: [Validators.required] }),
      address: new FormControl(null, { validators: [Validators.required] })
    });
    
    this.pageForm = new FormGroup({
      pageSelect: new FormControl(null, {
        validators: [Validators.required]
      })
    });

    //background image
    this.imageForm = new FormGroup({
      image: new FormControl(null, {
        validators: [Validators.required],
        asyncValidators: [mimeType]
      })
    });

    //header logo
    this.hi1Form = new FormGroup({
      image: new FormControl(null, {
        validators: [Validators.required],
        asyncValidators: [mimeType]
      })
    });

    //testimonial image 1
    this.pd1Form = new FormGroup({
      image: new FormControl(null, {
        validators: [Validators.required],
        asyncValidators: [mimeType]
      })
    });
    //testimonial image 2
    this.pd2Form = new FormGroup({
      image: new FormControl(null, {
        validators: [Validators.required],
        asyncValidators: [mimeType]
      })
    });
    //testimonial image 3 
    this.pd3Form = new FormGroup({
      image: new FormControl(null, {
        validators: [Validators.required],
        asyncValidators: [mimeType]
      })
    });
    //testimonial image 4
    this.pd4Form = new FormGroup({
      image: new FormControl(null, {
        validators: [Validators.required],
        asyncValidators: [mimeType]
      })
    });
    //testimonial image 5
    this.pd5Form = new FormGroup({
      image: new FormControl(null, {
        validators: [Validators.required],
        asyncValidators: [mimeType]
      })
    });
    //text color forms
    this.tcForm1 = new FormGroup({tc1: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm2 = new FormGroup({tc2: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm3 = new FormGroup({tc3: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm4 = new FormGroup({tc4: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm5 = new FormGroup({tc5: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm6 = new FormGroup({tc6: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm7 = new FormGroup({tc7: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm8 = new FormGroup({tc8: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm9 = new FormGroup({tc9: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm10 = new FormGroup({tc10: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm11 = new FormGroup({tc11: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm12 = new FormGroup({tc12: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm13 = new FormGroup({tc13: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm14 = new FormGroup({tc14: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm15 = new FormGroup({tc15: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm16 = new FormGroup({tc16: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm17 = new FormGroup({tc17: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm18 = new FormGroup({tc18: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm19 = new FormGroup({tc19: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm20 = new FormGroup({tc20: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm21 = new FormGroup({tc21: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm22 = new FormGroup({tc22: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm23 = new FormGroup({tc23: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm24 = new FormGroup({tc24: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm25 = new FormGroup({tc25: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm26 = new FormGroup({tc26: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm27 = new FormGroup({tc27: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm28 = new FormGroup({tc28: new FormControl(null, { validators: [Validators.required] })});
    this.tcForm29 = new FormGroup({tc29: new FormControl(null, { validators: [Validators.required] })});

    this.ecForm1 = new FormGroup({ec1: new FormControl(null, { validators: [Validators.required] })});
    this.ecForm2 = new FormGroup({ec2: new FormControl(null, { validators: [Validators.required] })});
    this.ecForm3 = new FormGroup({ec3: new FormControl(null, { validators: [Validators.required] })});
    this.ecForm4 = new FormGroup({ec4: new FormControl(null, { validators: [Validators.required] })});
    this.ecForm5 = new FormGroup({ec5: new FormControl(null, { validators: [Validators.required] })});
    this.ecForm6 = new FormGroup({ec6: new FormControl(null, { validators: [Validators.required] })});
    this.ecForm7 = new FormGroup({ec7: new FormControl(null, { validators: [Validators.required] })});
    this.ecForm8 = new FormGroup({ec8: new FormControl(null, { validators: [Validators.required] })});
    this.ecForm9 = new FormGroup({ec9: new FormControl(null, { validators: [Validators.required] })});

    this.fsForm1 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm2 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm3 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm4 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm5 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm6 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm7 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm8 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm9 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm10 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm11 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm12 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm13 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm14 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm15 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm16 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm17 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm18 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm19 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm20 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm21 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm22 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm23 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm24 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm25 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm26 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm27 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm28 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    this.fsForm29 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
    
    //background layer color
    this.blColorForm = new FormGroup({
      layerColor: new FormControl(null, { validators: [Validators.required] })
    });
    //header color
    this.colorForm = new FormGroup({
      headerColor: new FormControl(null, { validators: [Validators.required] })
    });
    //banner color
    this.bannerColorForm = new FormGroup({
      bannerColor: new FormControl(null, { validators: [Validators.required] })
    });
    //section 2 color
    this.section2ColorForm = new FormGroup({
      section2Color: new FormControl(null, { validators: [Validators.required] })
    });

    this.icon1Form = new FormGroup({
      fontFamily: new FormControl(null, { validators: [Validators.required] })
    }); 
    this.icon2Form = new FormGroup({
      fontFamily: new FormControl(null, { validators: [Validators.required] })
    });
    this.icon3Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon4Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon5Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon6Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon7Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon8Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon9Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon10Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon11Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon12Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon13Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon14Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon15Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon16Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon17Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon18Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon19Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon20Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon21Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon22Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon23Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon24Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon25Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon26Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon27Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon28Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    this.icon29Form = new FormGroup({
      fontFamily: new FormControl(null,{ validators: [Validators.required] })
    });
    
  }

 
  pageNav() {
    if (this.pageForm.value.pageSelect === null) {
      window.alert("Select a page");
    }

    if (this.pageForm.value.pageSelect === "about") {
      this.router.navigate(['/about']);
    } else if (this.pageForm.value.pageSelect === "contact") {
      this.router.navigate(['/contact']);
    }
    console.log(this.pageForm.value.pageSelect);

  }


  highlight(event) {
    for (let i = 0; i < this.bbIds.length; i++) {//newedits add an index
      
      if (event.target.id === this.elementIds[i]) {
        
        if (document.getElementById(this.bbIds[i]) !== null) {
          document.getElementById(this.bbIds[i]).style.backgroundColor = "snow";//new data
          this.scrollEditor(this.bbIds[i]);
        }
      }
    }
  }

  unhighlight(event) {
    for (let i = 0; i < this.bbIds.length; i++) {//newedits add an index
      if (event.target.id === this.elementIds[i]) {
        if (document.getElementById(this.bbIds[i]) !== null) {
          document.getElementById(this.bbIds[i]).style.backgroundColor = "transparent";//new data
          this.scrollEditor(this.bbIds[i]);
        }
      }
    }
  }

  deleteElement(id: string) {//newedits add an index
    console.log(id);
    for (let i = 0; i < this.bbIds.length; i++) {//use length here
      if (id === this.bbIds[i]) {
        console.log("match");
        if (this.bbIds[i] === this.bbIds[0] || this.bbIds[i] === this.bbIds[1] ||
          this.bbIds[i] === this.bbIds[21] || this.bbIds[i] === this.bbIds[38]) {
            console.log("transparent");
            console.log(this.bbIds[i]);
            if (this.bbIds[i] === this.bbIds[0]) {
              document.getElementById(this.elementIds[i]).style.backgroundColor = this.savedEHeaderDiv1;
            } else if (this.bbIds[i] === this.bbIds[1]) {
              document.getElementById(this.elementIds[i]).style.backgroundColor = this.savedEHomepageSection2;
            } else if (this.bbIds[i] === this.bbIds[21]) {
              document.getElementById(this.elementIds[i]).style.backgroundColor = this.savedHToolbar;
            } else if (this.bbIds[i] === this.bbIds[38]) {
              document.getElementById(this.elementIds[i]).style.backgroundColor = this.savedBackgroundLayer;
            }
          this.updateCheck();
        } else {
          console.log("nothing");
          console.log(this.bbIds[i]);
          document.getElementById(this.elementIds[i]).innerHTML = "";
          //in case element was a bullet, delete the check icon as well
          if (this.bbIds[i] === this.bbIds[13] || this.bbIds[i] === this.bbIds[14] ||
            this.bbIds[i] === this.bbIds[15] || this.bbIds[i] === this.bbIds[16] ||
            this.bbIds[i] === this.bbIds[17] || this.bbIds[i] === this.bbIds[18] ||
            this.bbIds[i] === this.bbIds[19] || this.bbIds[i] === this.bbIds[20]) {
              //invisible button to help delete check icon
              document.getElementById(this.bbIds[i] + "Delete").click();
            }
          this.updateCheck();
        }
      }
    }
  }

  bDivDelete(id: string) {
    for (let i = 1; i < 9; i++) {
      if (id === i.toString()) {
        document.getElementById("elistDiv" + i).style.display = "none";
      }
    }
  }

  bDivRestore(id: string) {
    for (let i = 1; i < 9; i++) {
      if (id === i.toString()) {
        document.getElementById("elistDiv" + i).style.display = "block";
      }
    }
  }

  undoElement(id: string) {//new data
    if (id === this.bbIds[0]){
      document.getElementById("eheaderDiv1").style.backgroundColor = this.savedEHeaderDiv1;
      this.updateCheck();
    } else if (id === this.bbIds[1]){
      document.getElementById("ehomepageSection2").style.backgroundColor = this.savedEHomepageSection2;
      this.updateCheck();
    } else if (id === this.bbIds[2]){
      document.getElementById("etitle1").innerHTML = this.savedETitle1;
      this.updateCheck();
    } else if (id === this.bbIds[4]){
      document.getElementById("etitle2").innerHTML = this.savedETitle2;
      this.updateCheck();
    } else if (id === this.bbIds[5]){
      document.getElementById("etitle3").innerHTML = this.savedETitle3;
      this.updateCheck();
    } else if (id === this.bbIds[6]){
      document.getElementById("etopRightText").innerHTML = this.savedETopRightText;
      this.updateCheck();
    } else if (id === this.bbIds[7]){
      document.getElementById("eheaderDiv1Text").innerHTML = this.savedEHeaderDiv1Text;
      this.updateCheck();
    } else if (id === this.bbIds[8]){
      document.getElementById("brh1").innerHTML = this.savedBrh1;
      this.updateCheck();
    } else if (id === this.bbIds[9]){
      document.getElementById("brh2").innerHTML = this.savedBrh2;
      this.updateCheck();
    } else if (id === this.bbIds[10]){
      document.getElementById("brh3").innerHTML = this.savedBrh3;
      this.updateCheck();
    } else if (id === this.bbIds[11]){
      document.getElementById("brh4").innerHTML = this.savedBrh4;
      this.updateCheck();
    } else if (id === this.bbIds[12]){
      document.getElementById("epN").innerHTML = this.savedEpN;
      this.updateCheck();
    } else if (id === this.bbIds[13]){
      if(this.savedBullet1 === "") {
        document.getElementById("elistDiv1").style.display = "none";
      } else { document.getElementById("elistDiv1").style.display = "block"; }
      document.getElementById("bullet1").innerHTML = this.savedBullet1;
      this.updateCheck();
    } else if (id === this.bbIds[14]){
      if(this.savedBullet2 === "") {
        document.getElementById("elistDiv2").style.display = "none";
      } else { document.getElementById("elistDiv2").style.display = "block"; }
      document.getElementById("bullet2").innerHTML = this.savedBullet2;
      this.updateCheck();
    } else if (id === this.bbIds[15]){
      if(this.savedBullet3 === "") {
        document.getElementById("elistDiv3").style.display = "none";
      } else { document.getElementById("elistDiv3").style.display = "block"; }
      document.getElementById("bullet3").innerHTML = this.savedBullet3;
      this.updateCheck();
    } else if (id === this.bbIds[16]){
      if(this.savedBullet4 === "") {
        document.getElementById("elistDiv4").style.display = "none";
      } else { document.getElementById("elistDiv4").style.display = "block"; }
      document.getElementById("bullet4").innerHTML = this.savedBullet4;
      this.updateCheck();
    } else if (id === this.bbIds[17]){
      if(this.savedBullet5 === "") {
        document.getElementById("elistDiv5").style.display = "none";
      } else { document.getElementById("elistDiv5").style.display = "block"; }
      document.getElementById("bullet5").innerHTML = this.savedBullet5;
      this.updateCheck();
    } else if (id === this.bbIds[18]){
      if(this.savedBullet6 === "") {
        document.getElementById("elistDiv6").style.display = "none";
      } else { document.getElementById("elistDiv6").style.display = "block"; }
      document.getElementById("bullet6").innerHTML = this.savedBullet6;
      this.updateCheck();
    } else if (id === this.bbIds[19]){
      if(this.savedBullet7 === "") {
        document.getElementById("elistDiv7").style.display = "none";
      } else { document.getElementById("elistDiv7").style.display = "block"; }
      document.getElementById("bullet7").innerHTML = this.savedBullet7;
      this.updateCheck();
    } else if (id === this.bbIds[20]){
      if(this.savedBullet8 === "") {
        document.getElementById("elistDiv8").style.display = "none";
      } else { document.getElementById("elistDiv8").style.display = "block"; }
      document.getElementById("bullet8").innerHTML = this.savedBullet8;
      this.updateCheck();
    } else if (id === this.bbIds[21]){
      document.getElementById("hToolbar").style.backgroundColor = this.savedHToolbar;
      this.updateCheck();
    } else if (id === this.bbIds[22]){
      document.getElementById("headerTitleText").innerHTML = this.savedHeaderTitleText;
      this.updateCheck();
    } else if (id === this.bbIds[23]){
      document.getElementById("etestimonialName1").innerHTML = this.savedTestimonialName1;
      this.updateCheck();
    } else if (id === this.bbIds[24]){
      document.getElementById("etestimonialText1").innerHTML = this.savedTestimonialText1;
      this.updateCheck();
    } else if (id === this.bbIds[25]){
      document.getElementById("etestimonialName2").innerHTML = this.savedTestimonialName2;
      this.updateCheck();
    } else if (id === this.bbIds[26]){
      document.getElementById("etestimonialText2").innerHTML = this.savedTestimonialText2;
      this.updateCheck();
    } else if (id === this.bbIds[27]){
      document.getElementById("etestimonialName3").innerHTML = this.savedTestimonialName3;
      this.updateCheck();
    } else if (id === this.bbIds[28]){
      document.getElementById("etestimonialText3").innerHTML = this.savedTestimonialText3;
      this.updateCheck();
    } else if (id === this.bbIds[29]){
      document.getElementById("etestimonialName4").innerHTML = this.savedTestimonialName4;
      this.updateCheck();
    } else if (id === this.bbIds[30]){
      document.getElementById("etestimonialText4").innerHTML = this.savedTestimonialText4;
      this.updateCheck();
    } else if (id === this.bbIds[31]){
      document.getElementById("etestimonialName5").innerHTML = this.savedTestimonialName5;
      this.updateCheck();
    } else if (id === this.bbIds[32]){
      document.getElementById("etestimonialText5").innerHTML = this.savedTestimonialText5;
      this.updateCheck();
    } else if (id === this.bbIds[38]){
      document.getElementById("backgroundLayer").style.backgroundColor = this.savedBackgroundLayer;
      this.updateCheck();
    }
  }

  scrollEditor(elementId: string) {//new data
    console.log("scroll");
    console.log(elementId);
    console.log("scroll2");
    if (elementId === "brh2EditDiv" || elementId === "brh3EditDiv" || elementId === "brh4EditDiv" || elementId === "epNEditDiv") {
      document.getElementById("editorDiv").scrollTop = 240;
    } else if (elementId === "b1EditDiv" || elementId === "b2EditDiv" || elementId === "b3EditDiv" 
    || elementId === "b4EditDiv" || elementId === "b5EditDiv" || elementId === "b6EditDiv" || 
    elementId === "b7EditDiv" || elementId === "b8EditDiv" || elementId === "tn3EditDiv" || elementId === "tt3EditDiv"
    || elementId === "tn4EditDiv" || elementId === "tt4EditDiv" || elementId === "tn5EditDiv" || elementId === "tt5EditDiv"
    || elementId === "pd3EditDiv" || elementId === "pd4EditDiv" || elementId === "pd5EditDiv") {
      document.getElementById("editorDiv").scrollTop = 726;
    } else if (document.getElementById("editorDiv").scrollTop > 0) {
      document.getElementById("editorDiv").scrollTop = 0;
    }
  }

  updateHomepageText() {//new data 

    this.editorpageService.updateHomepageText(
      this.userId,
      this.leadEmail,
      this.headerSetting,
      document.getElementById("hToolbar").style.backgroundColor,
      document.getElementById("eheaderDiv1").style.backgroundColor,
      document.getElementById("ehomepageSection2").style.backgroundColor,
      document.getElementById("headerTitleText").innerHTML,
      document.getElementById("etitle1").innerHTML,
      document.getElementById("etitle2").innerHTML,
      document.getElementById("etitle3").innerHTML,
      document.getElementById("etopRightText").innerHTML,
      document.getElementById("eheaderDiv1Text").innerHTML,
      document.getElementById("brh1").innerHTML,
      document.getElementById("brh2").innerHTML,
      document.getElementById("brh3").innerHTML,
      document.getElementById("brh4").innerHTML,
      document.getElementById("epN").innerHTML,
      document.getElementById("bullet1").innerHTML,
      document.getElementById("bullet2").innerHTML,
      document.getElementById("bullet3").innerHTML,
      document.getElementById("bullet4").innerHTML,
      document.getElementById("bullet5").innerHTML,
      document.getElementById("bullet6").innerHTML,
      document.getElementById("bullet7").innerHTML,
      document.getElementById("bullet8").innerHTML,
      document.getElementById("etestimonialName1").innerHTML,
      document.getElementById("etestimonialText1").innerHTML,
      document.getElementById("etestimonialName2").innerHTML,
      document.getElementById("etestimonialText2").innerHTML,
      document.getElementById("etestimonialName3").innerHTML,
      document.getElementById("etestimonialText3").innerHTML,
      document.getElementById("etestimonialName4").innerHTML,
      document.getElementById("etestimonialText4").innerHTML,
      document.getElementById("etestimonialName5").innerHTML,
      document.getElementById("etestimonialText5").innerHTML, 
      this.elementFonts,
      document.getElementById("backgroundLayer").style.backgroundColor,
      this.elementColors,
      this.elementFontSizes,
      this.otherColors
    ).subscribe(responseData => {

      console.log(responseData);
      console.log('return marker');
      if (responseData.message === 'Update successful!') {
        console.log("Updated text successfully");
        document.getElementById("edSave").style.backgroundColor = "#eeeeee";
      } else {
        console.log("Something went wrong");
      }

    });
  }

  //Change the editor view

  colorEditor() {

    this.activeButton("edColor");

    // code to display font editing icons
    var allFontIcons = document.querySelectorAll<HTMLElement>(".text-edit-icon");
    for (var i = 0; i < allFontIcons.length; i++) {
      allFontIcons.item(i).style.display = "none";
    }
    document.getElementById("pageFocus").style.display = "block";

    this.colorEditorBool = true;
    this.textEditorBool = false;
    this.testimonialEditorBool = false;
    this.fontEditorBool = false;

    // code to display color editing icons
    var allColorIcons = document.querySelectorAll<HTMLElement>(".color-edit-icon");
    for (var i = 0; i < allColorIcons.length; i++) {
      allColorIcons.item(i).style.display = "inline-block";
    }
  }

  textEditor() {

    this.activeButton("edText");

    // code to display font editing icons
    var allFontIcons = document.querySelectorAll<HTMLElement>(".text-edit-icon");
    for (var i = 0; i < allFontIcons.length; i++) {
      allFontIcons.item(i).style.display = "none";
    }
    var allColorIcons = document.querySelectorAll<HTMLElement>(".color-edit-icon");
    for (var i = 0; i < allColorIcons.length; i++) {
      allColorIcons.item(i).style.display = "none";
    }
    document.getElementById("pageFocus").style.display = "block";


    this.hi1imagePreview = "";
    this.imagePreview = "";
    this.textEditorBool = true;
    this.colorEditorBool = false;
    this.testimonialEditorBool = false;
    this.fontEditorBool = false;
  }

  testimonialEditor() {

    this.activeButton("edTestimonials");

    // code to display font editing icons
    var allFontIcons = document.querySelectorAll<HTMLElement>(".text-edit-icon");
    for (var i = 0; i < allFontIcons.length; i++) {
      allFontIcons.item(i).style.display = "none";
    }
    var allColorIcons = document.querySelectorAll<HTMLElement>(".color-edit-icon");
    for (var i = 0; i < allColorIcons.length; i++) {
      allColorIcons.item(i).style.display = "none";
    }
    document.getElementById("pageFocus").style.display = "none";

    this.hi1imagePreview = "";
    this.imagePreview = "";
    this.testimonialEditorBool = true;
    this.textEditorBool = false;
    this.colorEditorBool = false;
    this.fontEditorBool = false;
  }

  fontEditor() {

    this.activeButton("edFont");

    var allColorIcons = document.querySelectorAll<HTMLElement>(".color-edit-icon");
    for (var i = 0; i < allColorIcons.length; i++) {
      allColorIcons.item(i).style.display = "none";
    }

    document.getElementById("pageFocus").style.display = "block";
    this.hi1imagePreview = "";
    this.imagePreview = "";
    this.fontEditorBool = true;
    this.testimonialEditorBool = false;
    this.textEditorBool = false;
    this.colorEditorBool = false;


    // code to display font editing icons
    var allFontIcons = document.querySelectorAll<HTMLElement>(".text-edit-icon");
    for (var i = 0; i < allFontIcons.length; i++) {
      allFontIcons.item(i).style.display = "inline-block";
    }
  }

  activeButton(activeButton: string) {
    var buttons = ["edColor", "edFont", "edTestimonials", "edText"];
    for (var i = 0; i < buttons.length; i++) {
      if (buttons[i] === activeButton) {
        document.getElementById(activeButton).style.background = "transparent";
        document.getElementById(activeButton).style.border = "1px solid white";
      } else {
        document.getElementById(buttons[i]).style.background = "white";
        document.getElementById(buttons[i]).style.border = "none";
      }
    }
  }

  editorFormSubmit() {//Time to let the user customize the form colors
    if (this.editorForm.invalid) {//be sure to let user know when email is invalid
      if (this.editorForm.value.email.split('@')[1] === "" ||
          this.editorForm.value.email.split('@')[1] === undefined) 
          {
            window.alert('Please add a valid email address');
      }
      console.log('invalid');
      return;
    }

    console.log(this.editorForm.value.name);
    console.log(this.editorForm.value.email);
    console.log(this.editorForm.value.phoneNumber);
    console.log(this.editorForm.value.address);
  }

  editorForm2Submit() {//Time to let the user customize the form colors
    if (this.editorForm2.invalid) {//be sure to let user know when email is invalid
      if (this.editorForm2.value.email.split('@')[1] === "" ||
          this.editorForm2.value.email.split('@')[1] === undefined) 
          {
            window.alert('Please add a valid email address');
      }
      console.log('invalid');
      return;
    }

    console.log(this.editorForm2.value.name);
    console.log(this.editorForm2.value.email);
    console.log(this.editorForm2.value.phoneNumber);
    console.log(this.editorForm2.value.address);
  }

  onSaveBackgroundImage() {
    if (this.imageForm.invalid) {
      return;
    }

    this.editorpageService.updateHomepageBackgroundImage(
      this.userId,
      this.imageForm.value.image
    );
  }

  onImagePicked(event: Event) {
    document.getElementById("bGSaveButton").style.display = "inline-block";
    const file = (event.target as HTMLInputElement).files[0];
    this.imageForm.patchValue({ image: file });
    this.imageForm.get('image').updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result as string;
      console.log(this.imagePreview);
      document.getElementById("ebackgroundPicDiv1").style.backgroundImage = "url('" + this.imagePreview + "')";
    };
    reader.readAsDataURL(file);
  }

  onSaveHi1() {
    if (this.hi1Form.invalid) {
      return;
    }

    this.editorpageService.updateHomepageHeaderImage(
      this.userId,
      this.hi1Form.value.image
    ).subscribe(data => {
      if (data.message === "Update successful!") {
        this.headerSetting = "image";
        console.log(data.message);
      }
    });
  }

  onhi1ImagePicked(event: Event) {
    document.getElementById("hi1SaveButton").style.display = "inline-block";
    const file = (event.target as HTMLInputElement).files[0];
    this.hi1Form.patchValue({ image: file });
    this.hi1Form.get('image').updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.hi1imagePreview = reader.result as string;
      console.log(this.hi1imagePreview);
      document.getElementById("logo").style.backgroundImage = "url('" + this.hi1imagePreview + "')";
      document.getElementById("logo").style.display = "block";
      document.getElementById("headerTitleText").style.display = "none";
    };
    reader.readAsDataURL(file);
  }

  onSavePd1() {
    if (this.pd1Form.invalid) {
      return;
    }
    this.editorpageService.updateHomepageTestimonialPic1(
      this.userId,
      this.pd1Form.value.image
    );
  }

  onpd1ImagePicked(event: Event) {
    document.getElementById("pd1SaveButton").style.display = "inline-block";
    const file = (event.target as HTMLInputElement).files[0];
    this.pd1Form.patchValue({ image: file });
    this.pd1Form.get('image').updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.pd1imagePreview = reader.result as string;
      console.log(this.pd1imagePreview);
      document.getElementById("etestimonialPicDiv1").style.backgroundImage = "url('" + this.pd1imagePreview + "')";
    };
    reader.readAsDataURL(file);
  }

  onSavePd2() {
    if (this.pd2Form.invalid) {
      return;
    }
    this.editorpageService.updateHomepageTestimonialPic2(
      this.userId,
      this.pd2Form.value.image
    );
  }

  onpd2ImagePicked(event: Event) {
    document.getElementById("pd2SaveButton").style.display = "inline-block";
    const file = (event.target as HTMLInputElement).files[0];
    this.pd2Form.patchValue({ image: file });
    this.pd2Form.get('image').updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.pd2imagePreview = reader.result as string;
      console.log(this.pd2imagePreview);
      document.getElementById("etestimonialPicDiv2").style.backgroundImage = "url('" + this.pd2imagePreview + "')";
    };
    reader.readAsDataURL(file);
  }

  onSavePd3() {
    if (this.pd3Form.invalid) {
      return;
    }
    this.editorpageService.updateHomepageTestimonialPic3(
      this.userId,
      this.pd3Form.value.image
    );
  }

  onpd3ImagePicked(event: Event) {
    document.getElementById("pd3SaveButton").style.display = "inline-block";
    const file = (event.target as HTMLInputElement).files[0];
    this.pd3Form.patchValue({ image: file });
    this.pd3Form.get('image').updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.pd3imagePreview = reader.result as string;
      console.log(this.pd3imagePreview);
      document.getElementById("etestimonialPicDiv3").style.backgroundImage = "url('" + this.pd3imagePreview + "')";
    };
    reader.readAsDataURL(file);
  }

  onSavePd4() {
    if (this.pd4Form.invalid) {
      return;
    }
    this.editorpageService.updateHomepageTestimonialPic4(
      this.userId,
      this.pd4Form.value.image
    );
  }

  onpd4ImagePicked(event: Event) {
    document.getElementById("pd4SaveButton").style.display = "inline-block";
    const file = (event.target as HTMLInputElement).files[0];
    this.pd4Form.patchValue({ image: file });
    this.pd4Form.get('image').updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.pd4imagePreview = reader.result as string;
      console.log(this.pd4imagePreview);
      document.getElementById("etestimonialPicDiv4").style.backgroundImage = "url('" + this.pd4imagePreview + "')";
    };
    reader.readAsDataURL(file);
  }

  onSavePd5() {
    if (this.pd5Form.invalid) {
      return;
    }
    this.editorpageService.updateHomepageTestimonialPic5(
      this.userId,
      this.pd5Form.value.image
    );
  }

  onpd5ImagePicked(event: Event) {
    document.getElementById("pd5SaveButton").style.display = "inline-block";
    const file = (event.target as HTMLInputElement).files[0];
    this.pd5Form.patchValue({ image: file });
    this.pd5Form.get('image').updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.pd5imagePreview = reader.result as string;
      console.log(this.pd5imagePreview);
      document.getElementById("etestimonialPicDiv5").style.backgroundImage = "url('" + this.pd5imagePreview + "')";
    };
    reader.readAsDataURL(file);
  }

  setBlColor(value: string) {
    document.getElementById("blColorInput").style.color = "black";
    document.getElementById("backgroundLayer").style.backgroundColor = value;
    this.updateCheck();
  }

  setHColor(value: string) {
    document.getElementById("hColorInput").style.color = "black";
    document.getElementById("hToolbar").style.backgroundColor = value;
    this.updateCheck();
  }

  setBColor(value: string) {
    document.getElementById("bColorInput").style.color = "black";
    document.getElementById("eheaderDiv1").style.backgroundColor = value;
    this.updateCheck();
  }

  sets2Color(value: string) {
    document.getElementById("s2ColorInput").style.color = "black";
    document.getElementById("ehomepageSection2").style.backgroundColor = value;
    this.updateCheck();
  }

  softUpdateTextColor(value:string, textId:string, elementId: string) {
    if (value === 'undefined') {
      return;
    }
    document.getElementById(textId + "ColorInput").style.color = "black";
    document.getElementById(elementId).style.color = value;
  }

  setTextColor(value:string, textId:string, elementId: string) {
    if (value === 'undefined') {
      return;
    }
    document.getElementById(textId + "ColorInput").style.color = "black";
    document.getElementById(elementId).style.color = value;
    for (var i = 0; i < this.textElements.length; i++) {
      if (this.textElements[i] === elementId) {
        this.elementColors[i] = value;
        console.log('value set');
        document.getElementById('color-icon-'+(i+1)).click();
      }
    }
    this.updateCheck();
  }

  softUpdateElementColor(value:string, inputId:string, elementId: string) {
    if (value === 'undefined') {
      return;
    }
    document.getElementById(inputId + "ColorInput").style.color = "black";

    if (elementId.charAt(0) === ".") {
      var querySelectors = document.querySelectorAll<HTMLElement>(elementId);
      for (var i = 0; i < querySelectors.length; i++) {
        querySelectors[i].style.backgroundColor = value;
        console.log('soft');
      }
    }

    if (elementId.charAt(0) === "-") {
      this.elementRef.nativeElement.style.setProperty(elementId, value);
      console.log('soft');
    }

    if (elementId.charAt(0) === "e") {
      document.getElementById(elementId).style.backgroundColor = value;
      console.log('soft');
    }
  }
  setElementColor(value:string, inputId:string, elementId: string) {
    if (value === 'undefined') {
      return;
    }
    document.getElementById(inputId + "ColorInput").style.color = "black";

    if (elementId.charAt(0) === ".") {
      var querySelectors = document.querySelectorAll<HTMLElement>(elementId);
      for (var i = 0; i < querySelectors.length; i++) {
        querySelectors[i].style.backgroundColor = value;
      }
      for (var j = 0; j < this.elementToColor.length; j++) {
        if (this.elementToColor[j] === elementId) {
          this.otherColors[j] = value;
          console.log('hard');
        }
      }
    }

    if (elementId.charAt(0) === "-") {
      this.elementRef.nativeElement.style.setProperty(elementId, value);
      for (var j = 0; j < this.elementToColor.length; j++) {
        if (this.elementToColor[j] === elementId) {
          this.otherColors[j] = value;
          console.log('hard');
        }
      }
    }

    if (elementId.charAt(0) === "e") {
      document.getElementById(elementId).style.backgroundColor = value;
      for (var j = 0; j < this.elementToColor.length; j++) {
        if (this.elementToColor[j] === elementId) {
          this.otherColors[j] = value;
          console.log('hard');
        }
      }
    }

    this.updateCheck();
  }
  undoEColor(elementId: string) {
    if (elementId.charAt(0) === ".") {
      
      for (var j = 0; j < this.elementToColor.length; j++) {
        if (this.elementToColor[j] === elementId) {
          var querySelectors = document.querySelectorAll<HTMLElement>(elementId);
          for (var i = 0; i < querySelectors.length; i++) {
            querySelectors[i].style.backgroundColor = this.savedOtherColors[j];
          }
          this.otherColors[j] = this.savedOtherColors[j];
        }
      }
    }

    if (elementId.charAt(0) === "-") {
      for (var j = 0; j < this.elementToColor.length; j++) {
        if (this.elementToColor[j] === elementId) {
          this.elementRef.nativeElement.style.setProperty(elementId, this.savedOtherColors[j]);
          this.otherColors[j] = this.savedOtherColors[j];
        }
      }
    }

    if (elementId.charAt(0) === "e") {
      for (var j = 0; j < this.elementToColor.length; j++) {
        if (this.elementToColor[j] === elementId) {
          document.getElementById(elementId).style.backgroundColor = this.savedOtherColors[j];
          this.otherColors[j] = this.savedOtherColors[j];
        }
      }
    }
    this.updateCheck();
  }
  deleteEColor(elementId: string) {
    if (elementId.charAt(0) === ".") {
      
      for (var j = 0; j < this.elementToColor.length; j++) {
        if (this.elementToColor[j] === elementId) {
          var querySelectors = document.querySelectorAll<HTMLElement>(elementId);
          for (var i = 0; i < querySelectors.length; i++) {
            querySelectors[i].style.backgroundColor = "rgba(0,0,0,0)";
          }
          this.otherColors[j] = "rgba(0,0,0,0)";
        }
      }
    }

    if (elementId.charAt(0) === "-") {
      for (var j = 0; j < this.elementToColor.length; j++) {
        if (this.elementToColor[j] === elementId) {
          this.elementRef.nativeElement.style.setProperty(elementId, "black");
          this.otherColors[j] = "black";
        }
      }
    }

    if (elementId.charAt(0) === "e") {
      for (var j = 0; j < this.elementToColor.length; j++) {
        if (this.elementToColor[j] === elementId) {
          document.getElementById(elementId).style.backgroundColor = "rgba(0,0,0,0)";
          this.otherColors[j] = "rgba(0,0,0,0)";
        }
      }
    }
    this.updateCheck();
  }

  updateCheck() {//have to make call to get current page here to make most accurate. But keep saved variables for undo.
    let updated = false;

    this.editorpageService.getHomepage().subscribe(data => {
      
      if (document.getElementById("eheaderDiv1").style.backgroundColor !== data.homepage.bannerColor){
        updated = true;
      } else if (document.getElementById("ehomepageSection2").style.backgroundColor !== data.homepage.section2Color){
        updated = true;
      } else if (document.getElementById("etitle1").innerHTML !== data.homepage.card1Text1){
        updated = true;
      } else if (document.getElementById("etitle2").innerHTML !== data.homepage.card1Text2){
        updated = true;
      } else if (document.getElementById("etitle3").innerHTML !== data.homepage.card1Text3){
        updated = true;
      } else if (document.getElementById("etopRightText").innerHTML !== data.homepage.card2Text1){
        updated = true;
      } else if (document.getElementById("eheaderDiv1Text").innerHTML !== data.homepage.bannerText){
        updated = true;
      } else if (document.getElementById("brh1").innerHTML !== data.homepage.card3Text1){
        updated = true;
      } else if (document.getElementById("brh2").innerHTML !== data.homepage.card3Text2){
        updated = true;
      } else if (document.getElementById("brh3").innerHTML !== data.homepage.card3Text3){
        updated = true;
      } else if (document.getElementById("brh4").innerHTML !== data.homepage.card3Text4){
        updated = true;
      } else if (document.getElementById("epN").innerHTML !== data.homepage.card3PhoneNumber){
        updated = true;
      } else if (document.getElementById("bullet1").innerHTML !== data.homepage.bullet1){
        updated = true;
      } else if (document.getElementById("bullet2").innerHTML !== data.homepage.bullet2){
        updated = true;
      } else if (document.getElementById("bullet3").innerHTML !== data.homepage.bullet3){
        updated = true;
      } else if (document.getElementById("bullet4").innerHTML !== data.homepage.bullet4){
        updated = true;
      } else if (document.getElementById("bullet5").innerHTML !== data.homepage.bullet5){
        updated = true;
      } else if (document.getElementById("bullet6").innerHTML !== data.homepage.bullet6){
        updated = true;
      } else if (document.getElementById("bullet7").innerHTML !== data.homepage.bullet7){
        updated = true;
      } else if (document.getElementById("bullet8").innerHTML !== data.homepage.bullet8){
        updated = true;
      } else if (document.getElementById("hToolbar").style.backgroundColor !== data.homepage.headerColor){
        updated = true;
      } else if (document.getElementById("headerTitleText").innerHTML !== data.homepage.brandText){
        updated = true;
      } else if (document.getElementById("etestimonialName1").innerHTML !== data.homepage.testimonialName1){
        updated = true;
      } else if (document.getElementById("etestimonialText1").innerHTML !== data.homepage.testimonialText1){
        updated = true;
      } else if (document.getElementById("etestimonialName2").innerHTML !== data.homepage.testimonialName2){
        updated = true;
      } else if (document.getElementById("etestimonialText2").innerHTML !== data.homepage.testimonialText2){
        updated = true;
      } else if (document.getElementById("etestimonialName3").innerHTML !== data.homepage.testimonialName3){
        updated = true;
      } else if (document.getElementById("etestimonialText3").innerHTML !== data.homepage.testimonialText3){
        updated = true;
      } else if (document.getElementById("etestimonialName4").innerHTML !== data.homepage.testimonialName4){
        updated = true;
      } else if (document.getElementById("etestimonialText4").innerHTML !== data.homepage.testimonialText4){
        updated = true;
      } else if (document.getElementById("etestimonialName5").innerHTML !== data.homepage.testimonialName5){
        updated = true;
      } else if (document.getElementById("etestimonialText5").innerHTML !== data.homepage.testimonialText5){
        updated = true;
      } else if (document.getElementById("backgroundLayer").style.backgroundColor !== data.homepage.backgroundLayer) {
        updated = true;
      }

      for (var i = 0; i < this.elementFonts.length; i++) {
        if (this.elementFonts[i] !== data.homepage.elementFonts[i]) {
          updated = true;
        }
      }

      for (var i = 0; i < this.elementColors.length; i++) {
        if (this.elementColors[i] !== data.homepage.elementColors[i]) {
          updated = true;
        }
      }

      for (var i = 0; i < this.elementFontSizes.length; i++) {
        if (this.elementFontSizes[i] !== data.homepage.elementFontSizes[i]) {
          updated = true;
        }
      }

      for (var i = 0; i < this.otherColors.length; i++) {
        if (this.otherColors[i] !== data.homepage.otherColors[i]) {
          updated = true;
        }
      }

      if (updated === true) {
        document.getElementById("edSave").style.backgroundColor = "#ffffff";
      } else {
        document.getElementById("edSave").style.backgroundColor = "#eeeeee";
      }

    });
  }

  setFontSize(id: string, event: Event) {
    let size = event.target['innerText'].split('C')[0].trim();
    document.getElementById(id).style.fontSize = size;
    for (var i = 0; i < this.textElements.length; i++) {
      if (id === this.textElements[i]) {
        this.elementFontSizes[i] = size;
      }
    }
    this.updateCheck();
  }

  setHText(headerTextForm: NgForm) {
    if (headerTextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    document.getElementById("headerTitleText").style.display = "block";
    document.getElementById("logo").style.display = "none";
    document.getElementById("headerTitleText").innerHTML = headerTextForm.value.text;
    document.getElementById("clearhTextInput").click();
    this.updateCheck();
    this.headerSetting = "text";
  }

  setH2OneText(h2OneTextForm: NgForm) {
    if (h2OneTextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.h2OneDirty = false;
    console.log(h2OneTextForm.value.text);
    document.getElementById("etitle1").innerHTML = h2OneTextForm.value.text;
    document.getElementById("clearh2OneTextInput").click()
    this.updateCheck();
  }

  h2OneState() {
    document.getElementById("etitle1").innerHTML = "";
    this.h2OneDirty = true;
  }

  setH2OneFont(id: string) {
    if (this.icon1Form.invalid) {
      return;
    }
    console.log(this.icon1Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon1Form.value.fontFamily;
    this.elementFonts[0] = this.icon1Form.value.fontFamily;
    this.updateCheck();
  }

  undoTcColor(id: string) {
    for (var i = 0; i < this.textElements.length; i++) {
      if (this.textElements[i] === id) {
        this.elementColors[i] = this.savedElementColors[i];
        console.log(this.savedElementColors[i]);
        document.getElementById(id).style.color = this.savedElementColors[i];
        console.log('undid');
      }
    }
    this.updateCheck();
  }

  deleteTcColor(id: string) {
    for (var i = 0; i < this.textElements.length; i++) {
      if (this.textElements[i] === id) {
        this.elementColors[i] = 'rgb(0, 0, 0)';
        document.getElementById(id).style.color = 'rgb(0, 0, 0)';
      }
    }
    this.updateCheck();
  }

  setH3OneText(h3OneTextForm: NgForm) {
    if (h3OneTextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.h3OneDirty = false;
    console.log(h3OneTextForm.value.text);
    document.getElementById("etitle2").innerHTML = h3OneTextForm.value.text;
    document.getElementById("clearh3OneTextInput").click();
    this.updateCheck();
  }

  h3OneState() {
    document.getElementById("etitle2").innerHTML = "";
    this.h3OneDirty = true;
  }

  setH3OneFont(id: string) {
    if (this.icon2Form.invalid) {
      return;
    }
    console.log(this.icon2Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon2Form.value.fontFamily;
    this.elementFonts[1] = this.icon2Form.value.fontFamily;
    this.updateCheck();
  }

  setH3TwoText(h3TwoTextForm: NgForm) {
    if (h3TwoTextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.h3TwoDirty = false;
    console.log(h3TwoTextForm.value.text);
    document.getElementById("etitle3").innerHTML = h3TwoTextForm.value.text;
    document.getElementById("clearh3TwoTextInput").click();
    this.updateCheck();
  }

  h3TwoState() {
    document.getElementById("etitle3").innerHTML = "";
    this.h3TwoDirty = true;
  }

  setH3TwoFont(id: string) {
    if (this.icon3Form.invalid) {
      return;
    }
    console.log(this.icon3Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon3Form.value.fontFamily;
    this.elementFonts[2] = this.icon3Form.value.fontFamily;
    this.updateCheck();
  }

  settrText(trTextForm: NgForm) {
    if (trTextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.trDirty = false;
    console.log(trTextForm.value.text);
    document.getElementById("etopRightText").style.display = "inline-block"; //recently changed
    document.getElementById("etopRightText").innerHTML = trTextForm.value.text;
    document.getElementById("cleartrTextInput").click();
    this.updateCheck();
  }

  trState() {
    document.getElementById("etopRightText").style.display = "none";
    //document.getElementById("etopRightText").innerHTML = "";
    this.trDirty = true;
  }

  settrFont(id: string) {
    if (this.icon4Form.invalid) {
      return;
    }
    console.log(this.icon4Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon4Form.value.fontFamily;
    this.elementFonts[3] = this.icon4Form.value.fontFamily;
    this.updateCheck();
  }

  setmbText(mbTextForm: NgForm) {
    if (mbTextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.mbDirty = false;
    console.log(mbTextForm.value.text);
    document.getElementById("eheaderDiv1Text").style.display = "inline-block";
    document.getElementById("eheaderDiv1Text").innerHTML = mbTextForm.value.text;
    document.getElementById("clearmbTextInput").click();
    this.updateCheck();
  }

  mbState() {
    document.getElementById("eheaderDiv1Text").style.display = "none";
    this.mbDirty = true;
  }

  setmbFont(id: string) {
    if (this.icon5Form.invalid) {
      return;
    }
    console.log(this.icon5Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon5Form.value.fontFamily;
    this.elementFonts[4] = this.icon5Form.value.fontFamily;
    this.updateCheck();
  }

  setbrh1Text(brh1TextForm: NgForm) {
    if (brh1TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.brh1Dirty = false;
    console.log(brh1TextForm.value.text);
    document.getElementById("brh1").style.display = "inline-block";
    document.getElementById("brh1").innerHTML = brh1TextForm.value.text;
    document.getElementById("clearbrh1TextInput").click();
    this.updateCheck();
  }

  brh1State() {
    document.getElementById("brh1").style.display = "none";
    this.brh1Dirty = true;
  }

  setbrh1Font(id: string) {
    if (this.icon14Form.invalid) {
      return;
    }
    console.log(this.icon14Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon14Form.value.fontFamily;
    this.elementFonts[13] = this.icon14Form.value.fontFamily;
    this.updateCheck();
  }

  setbrh2Text(brh2TextForm: NgForm) {
    if (brh2TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.brh2Dirty = false;
    console.log(brh2TextForm.value.text);
    document.getElementById("brh2").style.display = "inline-block";
    document.getElementById("brh2").innerHTML = brh2TextForm.value.text;
    document.getElementById("clearbrh2TextInput").click();
    this.updateCheck();
  }

  brh2State() {
    document.getElementById("brh2").style.display = "none";
    this.brh2Dirty = true;
  }

  setbrh2Font(id: string) {
    if (this.icon15Form.invalid) {
      return;
    }
    console.log(this.icon15Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon15Form.value.fontFamily;
    this.elementFonts[14] = this.icon15Form.value.fontFamily;
    this.updateCheck();
  }

  setbrh3Text(brh3TextForm: NgForm) {
    if (brh3TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.brh3Dirty = false;
    console.log(brh3TextForm.value.text);
    document.getElementById("brh3").style.display = "inline-block";
    document.getElementById("brh3").innerHTML = brh3TextForm.value.text;
    document.getElementById("clearbrh3TextInput").click();
    this.updateCheck();
  }

  brh3State() {
    document.getElementById("brh3").style.display = "none";
    this.brh3Dirty = true;
  }

  setbrh3Font(id: string) {
    if (this.icon16Form.invalid) {
      return;
    }
    console.log(this.icon16Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon16Form.value.fontFamily;
    this.elementFonts[15] = this.icon16Form.value.fontFamily;
    this.updateCheck();
  }

  setbrh4Text(brh4TextForm: NgForm) {
    if (brh4TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.brh4Dirty = false;
    console.log(brh4TextForm.value.text);
    document.getElementById("brh4").style.display = "inline-block";
    document.getElementById("brh4").innerHTML = brh4TextForm.value.text;
    document.getElementById("clearbrh4TextInput").click();
    this.updateCheck();
  }

  brh4State() {
    document.getElementById("brh4").style.display = "none";
    this.brh4Dirty = true;
  }

  setbrh4Font(id: string) {
    if (this.icon17Form.invalid) {
      return;
    }
    console.log(this.icon17Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon17Form.value.fontFamily;
    this.elementFonts[16] = this.icon17Form.value.fontFamily;
    this.updateCheck();
  }

  setepNText(epNTextForm: NgForm) {
    if (epNTextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.epNDirty = false;
    console.log(epNTextForm.value.text);
    document.getElementById("epN").style.display = "inline-block";
    document.getElementById("epN").innerHTML = epNTextForm.value.text;
    document.getElementById("clearepNTextInput").click();
    this.updateCheck();
  }

  epNState() {
    document.getElementById("epN").style.display = "none";
    this.epNDirty = true;
  }

  setepNFont(id: string) {
    if (this.icon18Form.invalid) {
      return;
    }
    console.log(this.icon18Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon18Form.value.fontFamily;
    this.elementFonts[17] = this.icon18Form.value.fontFamily;
    this.updateCheck();
  }

  setbulletText(bulletForm: NgForm, indicator: string) {
    if (bulletForm.invalid) {
      window.alert("Text field is blank");
      return;
    }

    for (var i = 1; i < 9; i++) {
      if (indicator === ("b" + i)) {
        if (indicator === "b1") { this.b1Dirty = false; document.getElementById("elistDiv" + i).style.display = "block"; }
        if (indicator === "b2") { this.b2Dirty = false; document.getElementById("elistDiv" + i).style.display = "block"; }
        if (indicator === "b3") { this.b3Dirty = false; document.getElementById("elistDiv" + i).style.display = "block"; }
        if (indicator === "b4") { this.b4Dirty = false; document.getElementById("elistDiv" + i).style.display = "block"; }
        if (indicator === "b5") { this.b5Dirty = false; document.getElementById("elistDiv" + i).style.display = "block"; }
        if (indicator === "b6") { this.b6Dirty = false; document.getElementById("elistDiv" + i).style.display = "block"; }
        if (indicator === "b7") { this.b7Dirty = false; document.getElementById("elistDiv" + i).style.display = "block"; }
        if (indicator === "b8") { this.b8Dirty = false; document.getElementById("elistDiv" + i).style.display = "block"; }
        console.log(bulletForm.value.text);

        document.getElementById("elistDiv" + i).style.display = "block";
        document.getElementById("bullet" + i).style.display = "inline-block";
        document.getElementById("bullet" + i).innerHTML = bulletForm.value.text;
        document.getElementById("clearb" + i + "TextInput").click();
        this.updateCheck();
      }
    }
  }

  bulletState(indicator: string, restoreVar: string) {

    document.getElementById("elistDiv" + indicator).style.display = "block";
    document.getElementById("bullet" + indicator).style.display = "none";
    if (indicator === "1") { this.b1Dirty = true; }
    if (indicator === "2") { this.b2Dirty = true; }
    if (indicator === "3") { this.b3Dirty = true; }
    if (indicator === "4") { this.b4Dirty = true; }
    if (indicator === "5") { this.b5Dirty = true; }
    if (indicator === "6") { this.b6Dirty = true; }
    if (indicator === "7") { this.b7Dirty = true; }
    if (indicator === "8") { this.b8Dirty = true; }

  }

  setb1Font(id: string) {
    if (this.icon6Form.invalid) {
      return;
    }
    console.log(this.icon6Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon6Form.value.fontFamily;
    this.elementFonts[5] = this.icon6Form.value.fontFamily;
    this.updateCheck();
  }
  setb2Font(id: string) {
    if (this.icon7Form.invalid) {
      return;
    }
    console.log(this.icon7Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon7Form.value.fontFamily;
    this.elementFonts[6] = this.icon7Form.value.fontFamily;
    this.updateCheck();
  }
  setb3Font(id: string) {
    if (this.icon8Form.invalid) {
      return;
    }
    console.log(this.icon8Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon8Form.value.fontFamily;
    this.elementFonts[7] = this.icon8Form.value.fontFamily;
    this.updateCheck();
  }
  setb4Font(id: string) {
    if (this.icon9Form.invalid) {
      return;
    }
    console.log(this.icon9Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon9Form.value.fontFamily;
    this.elementFonts[8] = this.icon9Form.value.fontFamily;
    this.updateCheck();
  }
  setb5Font(id: string) {
    if (this.icon10Form.invalid) {
      return;
    }
    console.log(this.icon10Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon10Form.value.fontFamily;
    this.elementFonts[9] = this.icon10Form.value.fontFamily;
    this.updateCheck();
  }
  setb6Font(id: string) {
    if (this.icon11Form.invalid) {
      return;
    }
    console.log(this.icon11Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon11Form.value.fontFamily;
    this.elementFonts[10] = this.icon11Form.value.fontFamily;
    this.updateCheck();
  }
  setb7Font(id: string) {
    if (this.icon12Form.invalid) {
      return;
    }
    console.log(this.icon12Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon12Form.value.fontFamily;
    this.elementFonts[11] = this.icon12Form.value.fontFamily;
    this.updateCheck();
  }
  setb8Font(id: string) {
    if (this.icon13Form.invalid) {
      return;
    }
    console.log(this.icon13Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon13Form.value.fontFamily;
    this.elementFonts[12] = this.icon13Form.value.fontFamily;
    this.updateCheck();
  }

  //CODE FOR TESTIMONIAL SECTION

  setTestimonialFont(id: string) {
    if (this.icon19Form.invalid) {
      return;
    }
    console.log(this.icon19Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon19Form.value.fontFamily;
    this.elementFonts[18] = this.icon19Form.value.fontFamily;
    this.updateCheck();
  }

  settn1Text(tn1TextForm: NgForm) {
    if (tn1TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.tn1Dirty = false;
    console.log(tn1TextForm.value.text);
    document.getElementById("etestimonialName1").style.display = "inline-block";
    document.getElementById("etestimonialName1").innerHTML = tn1TextForm.value.text;
    document.getElementById("cleartn1TextInput").click();
    this.updateCheck();
  }

  tn1State() {
    document.getElementById("etestimonialName1").style.display = "none";
    this.tn1Dirty = true;
  }

  settn1Font(id: string) {
    if (this.icon20Form.invalid) {
      return;
    }
    console.log(this.icon20Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon20Form.value.fontFamily;
    this.elementFonts[19] = this.icon20Form.value.fontFamily;
    this.updateCheck();
  }

  settt1Text(tt1TextForm: NgForm) {
    if (tt1TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.tt1Dirty = false;
    console.log(tt1TextForm.value.text);
    document.getElementById("etestimonialText1").style.display = "inline-block";
    document.getElementById("etestimonialText1").innerHTML = tt1TextForm.value.text;
    document.getElementById("cleartt1TextInput").click();
    this.updateCheck();
  }

  tt1State() {
    document.getElementById("etestimonialText1").style.display = "none";
    this.tt1Dirty = true;
  }

  settt1Font(id: string) {
    if (this.icon21Form.invalid) {
      return;
    }
    console.log(this.icon21Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon21Form.value.fontFamily;
    this.elementFonts[20] = this.icon21Form.value.fontFamily;
    this.updateCheck();
  }

  settn2Text(tn2TextForm: NgForm) {
    if (tn2TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.tn2Dirty = false;
    console.log(tn2TextForm.value.text);
    document.getElementById("etestimonialName2").style.display = "inline-block";
    document.getElementById("etestimonialName2").innerHTML = tn2TextForm.value.text;
    document.getElementById("cleartn2TextInput").click();
    this.updateCheck();
  }

  tn2State() {
    document.getElementById("etestimonialName2").style.display = "none";
    this.tn2Dirty = true;
  }

  settn2Font(id: string) {
    if (this.icon22Form.invalid) {
      return;
    }
    console.log(this.icon22Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon22Form.value.fontFamily;
    this.elementFonts[21] = this.icon22Form.value.fontFamily;
    this.updateCheck();
  }

  settt2Text(tt2TextForm: NgForm) {
    if (tt2TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.tt2Dirty = false;
    console.log(tt2TextForm.value.text);
    document.getElementById("etestimonialText2").style.display = "inline-block";
    document.getElementById("etestimonialText2").innerHTML = tt2TextForm.value.text;
    document.getElementById("cleartt2TextInput").click();
    this.updateCheck();
  }

  tt2State() {
    document.getElementById("etestimonialText2").style.display = "none";
    this.tt2Dirty = true;
  }

  settt2Font(id: string) {
    if (this.icon23Form.invalid) {
      return;
    }
    console.log(this.icon23Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon23Form.value.fontFamily;
    this.elementFonts[22] = this.icon23Form.value.fontFamily;
    this.updateCheck();
  }

  settn3Text(tn3TextForm: NgForm) {
    if (tn3TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.tn3Dirty = false;
    console.log(tn3TextForm.value.text);
    document.getElementById("etestimonialName3").style.display = "inline-block";
    document.getElementById("etestimonialName3").innerHTML = tn3TextForm.value.text;
    document.getElementById("cleartn3TextInput").click();
    this.updateCheck();
  }

  tn3State() {
    document.getElementById("etestimonialName3").style.display = "none";
    this.tn3Dirty = true;
  }

  settn3Font(id: string) {
    if (this.icon24Form.invalid) {
      return;
    }
    console.log(this.icon24Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon24Form.value.fontFamily;
    this.elementFonts[23] = this.icon24Form.value.fontFamily;
    this.updateCheck();
  }

  settt3Text(tt3TextForm: NgForm) {
    if (tt3TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.tt3Dirty = false;
    console.log(tt3TextForm.value.text);
    document.getElementById("etestimonialText3").style.display = "inline-block";
    document.getElementById("etestimonialText3").innerHTML = tt3TextForm.value.text;
    document.getElementById("cleartt3TextInput").click();
    this.updateCheck();
  }

  tt3State() {
    document.getElementById("etestimonialText3").style.display = "none";
    this.tt3Dirty = true;
  }

  settt3Font(id: string) {
    if (this.icon25Form.invalid) {
      return;
    }
    console.log(this.icon25Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon25Form.value.fontFamily;
    this.elementFonts[24] = this.icon25Form.value.fontFamily;
    this.updateCheck();
  }

  settn4Text(tn4TextForm: NgForm) {
    if (tn4TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.tn4Dirty = false;
    console.log(tn4TextForm.value.text);
    document.getElementById("etestimonialName4").style.display = "inline-block";
    document.getElementById("etestimonialName4").innerHTML = tn4TextForm.value.text;
    document.getElementById("cleartn4TextInput").click();
    this.updateCheck();
  }

  tn4State() {
    document.getElementById("etestimonialName4").style.display = "none";
    this.tn4Dirty = true;
  }

  settn4Font(id: string) {
    if (this.icon26Form.invalid) {
      return;
    }
    console.log(this.icon26Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon26Form.value.fontFamily;
    this.elementFonts[25] = this.icon26Form.value.fontFamily;
    this.updateCheck();
  }

  settt4Text(tt4TextForm: NgForm) {
    if (tt4TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.tt4Dirty = false;
    console.log(tt4TextForm.value.text);
    document.getElementById("etestimonialText4").style.display = "inline-block";
    document.getElementById("etestimonialText4").innerHTML = tt4TextForm.value.text;
    document.getElementById("cleartt4TextInput").click();
    this.updateCheck();
  }

  tt4State() {
    document.getElementById("etestimonialText4").style.display = "none";
    this.tt4Dirty = true;
  }

  settt4Font(id: string) {
    if (this.icon27Form.invalid) {
      return;
    }
    console.log(this.icon27Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon27Form.value.fontFamily;
    this.elementFonts[26] = this.icon27Form.value.fontFamily;
    this.updateCheck();
  }

  settn5Text(tn5TextForm: NgForm) {
    if (tn5TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.tn5Dirty = false;
    console.log(tn5TextForm.value.text);
    document.getElementById("etestimonialName5").style.display = "inline-block";
    document.getElementById("etestimonialName5").innerHTML = tn5TextForm.value.text;
    document.getElementById("cleartn5TextInput").click();
    this.updateCheck();
  }

  tn5State() {
    document.getElementById("etestimonialName5").style.display = "none";
    this.tn5Dirty = true;
  }

  settn5Font(id: string) {
    if (this.icon28Form.invalid) {
      return;
    }
    console.log(this.icon28Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon28Form.value.fontFamily;
    this.elementFonts[27] = this.icon28Form.value.fontFamily;
    this.updateCheck();
  }

  settt5Text(tt5TextForm: NgForm) {
    if (tt5TextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.tt5Dirty = false;
    console.log(tt5TextForm.value.text);
    document.getElementById("etestimonialText5").style.display = "inline-block";
    document.getElementById("etestimonialText5").innerHTML = tt5TextForm.value.text;
    document.getElementById("cleartt5TextInput").click();
    this.updateCheck();
  }

  tt5State() {
    document.getElementById("etestimonialText5").style.display = "none";
    this.tt5Dirty = true;
  }

  settt5Font(id: string) {
    if (this.icon29Form.invalid) {
      return;
    }
    console.log(this.icon29Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon29Form.value.fontFamily;
    this.elementFonts[28] = this.icon29Form.value.fontFamily;
    this.updateCheck();
  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
  }

}
