import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';


import { AuthService } from 'src/app/auth/auth.service';
import { mimeType } from '../mime-type.validator';
import { EditorpageService } from '../editorpage.service';

@Component({
  selector: 'app-contacteditor',
  templateUrl: './contacteditor.component.html',
  styleUrls: ['./contacteditor.component.css']
})
export class ContacteditorComponent implements OnInit, OnDestroy {
  elementIds: string[] = ["visitIcon", "callIcon", "emessageHeader", "ebackgroundPicDiv1", "ehomepageSection2", "ebackgroundPicDiv0Text", 
  "evisitText", "ecallText", "emessageHeader", "eaddress", "ephoneNumber", "ebackgroundPicDiv0", "equestionSubButton", "equestionForm1", "backgroundLayer"];
  bbIds: string[] = ["viEditDiv", "ciEditDiv", "qlEditDiv", "bpd1EditDiv", "hs2EditDiv", "ctEditDiv",
  "vitEditDiv", "catEditDiv", "mhEditDiv", "adEditDiv", "phEditDiv", "biEditDiv", "sbcEditDiv", "fcEditDiv", "blEditDiv"];
  elementFontSizes: string[] = [];
  savedElementFontSizes: string[] = [];
  elementFonts: string[] = [];
  savedElementFonts: string[] = [];
  elementColors: string[] = [];
  savedElementColors: string[] = [];//here
  textElements: string[] = ["ebackgroundPicDiv0Text", "evisitText", "eaddress", "ecallText", "ephoneNumber", "emessageHeader", "equestionSubButton"];
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  headerSetting: string;
  userId = "";
  leadEmail = "";
  backgroundImagePath = "";
  showAddress = true;
  showPhoneNumber = true;
  pageForm: FormGroup;
  questionForm: FormGroup;
  icon1Form: FormGroup;
  icon2Form: FormGroup;
  icon3Form: FormGroup;
  icon4Form: FormGroup;
  icon5Form: FormGroup;
  icon6Form: FormGroup;
  viForm: FormGroup;
  ciForm: FormGroup;
  qlForm: FormGroup;
  bpd1Form: FormGroup;
  hs2Form: FormGroup;
  biForm: FormGroup;
  sbcForm: FormGroup;
  fcForm: FormGroup; 
  blForm: FormGroup;

  tcForm1: FormGroup; tcForm2: FormGroup; tcForm3: FormGroup;
  tcForm4: FormGroup; tcForm5: FormGroup; tcForm6: FormGroup;
  tcForm7: FormGroup;

  fsForm1: FormGroup; fsForm2: FormGroup; fsForm3: FormGroup;
  fsForm4: FormGroup; fsForm5: FormGroup; fsForm6: FormGroup;

  biimagePreview: string;
  ctDirty = false;
  vitDirty = false;
  catDirty = false;
  mhDirty = false;
  adDirty = false;
  phDirty = false;
  colorEditorBool = true;
  textEditorBool = false;
  fontEditorBool = false;
  savedVisitIconColor: string; savedCallIconColor: string; 
  savedSection1Color: string; savedSection2Color: string; savedFormColor: string; savedTitleText: string;
  savedVisitText: string; savedCallText: string; savedQuestionLabelText: string; savedAddressText: string;
  savedPhoneNumberText: string; savedBackgroundLayer: string;


  constructor(
    public authService: AuthService,
    public editorpageService: EditorpageService,
    public route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    document.getElementById("initloader").style.display = "none";
    document.getElementById("hToolbar").style.width = "78%";
    document.getElementById("hToolbar").style.right = "0%";
    document.getElementById("hToolbar").style.zIndex = "2";
    document.getElementById("hToolbar").style.position = "fixed";
    document.getElementById("previewItem").style.display = "block";

    document.getElementById("edColor").style.background = "transparent";
    document.getElementById("edColor").style.border = "1px solid white";

    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
      });

    this.editorpageService.getHomepage().subscribe(data => {
      console.log(data);
      document.getElementById("logo").style.backgroundImage = 
      'url(' + data.homepage.brandLogoPath + ')';

      document.getElementById("headerTitleText").innerHTML = data.homepage.brandText;
      document.getElementById("hToolbar").style.backgroundColor = data.homepage.headerColor;

      this.headerSetting = data.homepage.headerSetting;
      if (this.headerSetting === "text") {
        document.getElementById("headerTitleText").style.display = "block";
        document.getElementById("logo").style.display = "none";
      } else if (this.headerSetting === "image") {
        document.getElementById("headerTitleText").style.display = "none";
        document.getElementById("logo").style.display = "block";
      }
    });

    // code to set the font
    // var allText = document.querySelectorAll<HTMLElement>(".font");
    // for (var i = 0; i < allText.length; i++) {
    //   allText.item(i).style.fontFamily = "Spartan-Regular";
    // }

    this.editorpageService.getContactpage().subscribe(data => {//new data
      console.log("CONTACTPAGE DATA");
      console.log(data);

      for (var i = 0; i < data.contactpage.elementFonts.length; i++) {
        this.elementFonts[i] = data.contactpage.elementFonts[i];
      }

      for (var i = 0; i < data.contactpage.elementFonts.length; i++) {
        document.getElementById(this.textElements[i]).style.fontFamily = data.contactpage.elementFonts[i];
      }

      for (var i = 0; i < data.contactpage.elementColors.length; i++) {//here
        this.elementColors[i] = data.contactpage.elementColors[i];
        this.savedElementColors[i] = data.contactpage.elementColors[i];
        if (this.textElements[i] !== "equestionSubButton") {
          document.getElementById(this.textElements[i]).style.color = data.contactpage.elementColors[i];
        } else {
          document.getElementById(this.textElements[i]).style.backgroundColor = data.contactpage.elementColors[i];
        }
      }

      for (var i = 0; i < data.contactpage.elementFontSizes.length; i++) {
        this.elementFontSizes[i] = data.contactpage.elementFontSizes[i];
        this.savedElementFontSizes[i] = data.contactpage.elementFontSizes[i];
        document.getElementById(this.textElements[i]).style.fontSize = data.contactpage.elementFontSizes[i];
      }

      this.userId = data.contactpage.userId;
      this.leadEmail = data.contactpage.email;


      this.backgroundImagePath = data.contactpage.backgroundImagePath;
      console.log(this.backgroundImagePath);
      if (this.backgroundImagePath !== "") {
        document.getElementById("ebackgroundPicDiv0").style.backgroundImage = 
        'url(' + this.backgroundImagePath + ')';
        console.log(document.getElementById("ebackgroundPicDiv0").style.backgroundImage);
      }
      document.getElementById("backgroundLayer").style.backgroundColor = data.contactpage.backgroundLayer;
      this.savedBackgroundLayer = data.contactpage.backgroundLayer;
      document.getElementById("visitIcon").style.color = data.contactpage.visitIconColor;
      this.savedVisitIconColor = data.contactpage.visitIconColor;
      document.getElementById("callIcon").style.color = data.contactpage.callIconColor;
      this.savedCallIconColor = data.contactpage.callIconColor;
      document.getElementById("ebackgroundPicDiv1").style.backgroundColor = data.contactpage.section1Color;
      this.savedSection1Color = data.contactpage.section1Color;
      document.getElementById("ehomepageSection2").style.backgroundColor = data.contactpage.section2Color;
      this.savedSection2Color = data.contactpage.section2Color;
      document.getElementById("equestionForm1").style.backgroundColor = data.contactpage.formColor;
      this.savedFormColor = data.contactpage.formColor;
      document.getElementById("ebackgroundPicDiv0Text").innerHTML = data.contactpage.titleText;
      this.savedTitleText = data.contactpage.titleText;
      document.getElementById("evisitText").innerHTML = data.contactpage.visitText;
      this.savedVisitText = data.contactpage.visitText;
      document.getElementById("ecallText").innerHTML = data.contactpage.callText;
      this.savedCallText = data.contactpage.callText;
      document.getElementById("emessageHeader").innerHTML = data.contactpage.messageHeaderText;
      this.savedQuestionLabelText = data.contactpage.messageHeaderText;
      document.getElementById("eaddress").innerHTML = data.contactpage.addressText;
      this.savedAddressText = data.contactpage.addressText;
      document.getElementById("ephoneNumber").innerHTML = data.contactpage.phoneNumberText;
      this.savedPhoneNumberText = data.contactpage.phoneNumberText;

      this.showAddress = data.contactpage.showAddress;
      this.showPhoneNumber = data.contactpage.showPhoneNumber;
      
    });

      this.pageForm = new FormGroup({
        pageSelect: new FormControl(null, {
          validators: [Validators.required]
        })
      });

      this.biForm = new FormGroup({
        image: new FormControl(null, {
          validators: [Validators.required],
          asyncValidators: [mimeType]
        })
      });

      this.viForm = new FormGroup({viColor: new FormControl(null, {validators: [Validators.required]}) });
      this.ciForm = new FormGroup({ciColor: new FormControl(null, {validators: [Validators.required]}) });
      this.qlForm = new FormGroup({qlColor: new FormControl(null, {validators: [Validators.required]}) });
      this.bpd1Form = new FormGroup({bpd1Color: new FormControl(null, {validators: [Validators.required]}) });
      this.hs2Form = new FormGroup({hs2Color: new FormControl(null, {validators: [Validators.required]}) });
      this.sbcForm = new FormGroup({sbcColor: new FormControl(null, {validators: [Validators.required]}) });
      this.fcForm = new FormGroup({fcColor: new FormControl(null, {validators: [Validators.required]}) });
      //background layer color
      this.blForm = new FormGroup({layerColor: new FormControl(null, { validators: [Validators.required] })});

      this.tcForm1 = new FormGroup({tc1: new FormControl(null, { validators: [Validators.required] })});
      this.tcForm2 = new FormGroup({tc2: new FormControl(null, { validators: [Validators.required] })});
      this.tcForm3 = new FormGroup({tc3: new FormControl(null, { validators: [Validators.required] })});
      this.tcForm4 = new FormGroup({tc4: new FormControl(null, { validators: [Validators.required] })});
      this.tcForm5 = new FormGroup({tc5: new FormControl(null, { validators: [Validators.required] })});
      this.tcForm6 = new FormGroup({tc6: new FormControl(null, { validators: [Validators.required] })});
      this.tcForm7 = new FormGroup({tc7: new FormControl(null, { validators: [Validators.required] })});

      this.questionForm = new FormGroup({
        name: new FormControl(null, { validators: [Validators.required] }),
        email: new FormControl(null, {
          validators: [Validators.required, Validators.email /* , Validators.minLength(3) */]
        }),
        phoneNumber: new FormControl(null, { validators: [Validators.required] }),
        message: new FormControl(null, { validators: [Validators.required] })
      });

      this.icon1Form = new FormGroup({
        fontFamily: new FormControl(null, { validators: [Validators.required] })
      }); 
      this.icon2Form = new FormGroup({
        fontFamily: new FormControl(null, { validators: [Validators.required] })
      });
      this.icon3Form = new FormGroup({
        fontFamily: new FormControl(null,{ validators: [Validators.required] })
      });
      this.icon4Form = new FormGroup({
        fontFamily: new FormControl(null,{ validators: [Validators.required] })
      });
      this.icon5Form = new FormGroup({
        fontFamily: new FormControl(null,{ validators: [Validators.required] })
      });
      this.icon6Form = new FormGroup({
        fontFamily: new FormControl(null,{ validators: [Validators.required] })
      });

      this.fsForm1 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
      this.fsForm2 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
      this.fsForm3 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
      this.fsForm4 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
      this.fsForm5 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});
      this.fsForm6 = new FormGroup({fontSize: new FormControl(null, { validators: [Validators.required] })});

  }

  onSaveQuestionForm() {
    if (this.questionForm.invalid) {
      console.log("This form is missing a field.");
      return;
    }

    console.log(this.questionForm.value.message);
  }

  colorEditor() {

    this.activeButton("edColor");

    document.getElementById("pageFocus").style.display = "block";
    this.colorEditorBool = true;
    this.textEditorBool = false;
    this.fontEditorBool = false;

    // code to display color editing icons
    var allColorIcons = document.querySelectorAll<HTMLElement>(".color-edit-icon");
    for (var i = 0; i < allColorIcons.length; i++) {
      allColorIcons.item(i).style.display = "inline-block";
    }

    // code to display font editing icons
    var allFontIcons = document.querySelectorAll<HTMLElement>(".text-edit-icon");
    for (var i = 0; i < allFontIcons.length; i++) {
      allFontIcons.item(i).style.display = "none";
    }
  }

  textEditor() {

    this.activeButton("edText");

    document.getElementById("pageFocus").style.display = "block";
    this.textEditorBool = true;
    this.colorEditorBool = false;
    this.fontEditorBool = false;

    // code to display color editing icons
    var allColorIcons = document.querySelectorAll<HTMLElement>(".color-edit-icon");
    for (var i = 0; i < allColorIcons.length; i++) {
      allColorIcons.item(i).style.display = "none";
    }

    // code to display font editing icons
    var allFontIcons = document.querySelectorAll<HTMLElement>(".text-edit-icon");
    for (var i = 0; i < allFontIcons.length; i++) {
      allFontIcons.item(i).style.display = "none";
    }
  }

  fontEditor() {

    this.activeButton("edFont");

    document.getElementById("pageFocus").style.display = "block";
    this.fontEditorBool = true;
    this.textEditorBool = false;
    this.colorEditorBool = false;

    // code to display font editing icons
    var allFontIcons = document.querySelectorAll<HTMLElement>(".text-edit-icon");
    for (var i = 0; i < allFontIcons.length; i++) {
      allFontIcons.item(i).style.display = "inline-block";
    }

    // code to display color editing icons
    var allColorIcons = document.querySelectorAll<HTMLElement>(".color-edit-icon");
    for (var i = 0; i < allColorIcons.length; i++) {
      allColorIcons.item(i).style.display = "none";
    }
  }

  activeButton(activeButton: string) {
    var buttons = ["edColor", "edFont", "edText"];
    for (var i = 0; i < buttons.length; i++) {
      if (buttons[i] === activeButton) {
        document.getElementById(activeButton).style.background = "transparent";
        document.getElementById(activeButton).style.border = "1px solid white";
      } else {
        document.getElementById(buttons[i]).style.background = "white";
        document.getElementById(buttons[i]).style.border = "none";
      }
    }
  }

  updateContactText() {
    this.editorpageService.updateContactpageText(this.userId, this.leadEmail, //add code for these vars
      document.getElementById("visitIcon").style.color, 
      document.getElementById("callIcon").style.color,
      document.getElementById("ebackgroundPicDiv1").style.backgroundColor, 
      document.getElementById("ehomepageSection2").style.backgroundColor, 
      document.getElementById("equestionForm1").style.backgroundColor, 
      document.getElementById("ebackgroundPicDiv0Text").innerHTML, 
      document.getElementById("evisitText").innerHTML, 
      document.getElementById("ecallText").innerHTML, 
      document.getElementById("emessageHeader").innerHTML, 
      document.getElementById("eaddress").innerHTML, //add code for these bools
      document.getElementById("ephoneNumber").innerHTML, 
      this.showAddress, 
      this.showPhoneNumber, 
      this.elementFonts, 
      document.getElementById("backgroundLayer").style.backgroundColor,
      this.elementFontSizes,
      this.elementColors//here
      ).subscribe(responseData => {

        console.log(responseData);
        console.log('return marker');
        if (responseData.message === 'Update successful!') {
          console.log("Updated text successfully");
          document.getElementById("edSave").style.backgroundColor = "#eeeeee";
        } else {
          console.log("Something went wrong");
        }
      });
  }

  softUpdateTextColor(value:string, textId:string, elementId: string) {//here
    if (value === 'undefined') {
      return;
    }
    document.getElementById(textId + "ColorInput").style.color = "black";
    if (textId !== "tc7") {
      document.getElementById(elementId).style.color = value;
    } else {
      document.getElementById(elementId).style.backgroundColor = value
    }
  }

  setTextColor(value:string, textId:string, elementId: string) {//here
    if (value === 'undefined') {
      return;
    }
    document.getElementById(textId + "ColorInput").style.color = "black";
    if (textId !== "tc7") {
      document.getElementById(elementId).style.color = value;
    } else {
      document.getElementById(elementId).style.backgroundColor = value
    }
    for (var i = 0; i < this.textElements.length; i++) {
      if (this.textElements[i] === elementId) {
        this.elementColors[i] = value;
        console.log('value set');
        document.getElementById('color-icon-'+(i+1)).click();
      }
    }
    this.updateCheck();
  }

  undoTcColor(id: string) {//here
    for (var i = 0; i < this.textElements.length; i++) {
      if (this.textElements[i] === id) {
        this.elementColors[i] = this.savedElementColors[i];
        console.log(this.savedElementColors[i]);
        if (this.textElements[i] !== "equestionSubButton") {
          document.getElementById(id).style.color = this.savedElementColors[i];
        } else {
          document.getElementById(id).style.backgroundColor = this.savedElementColors[i];
        }
        console.log('undid');
      }
    }
    this.updateCheck();
  }

  deleteTcColor(id: string) {//here
    for (var i = 0; i < this.textElements.length; i++) {
      if (this.textElements[i] === id) {
        this.elementColors[i] = 'rgb(0, 0, 0)';
        if (this.textElements[i] !== "equestionSubButton") {
          document.getElementById(id).style.color = 'rgb(0, 0, 0)';
        } else {
          document.getElementById(id).style.backgroundColor = 'rgb(0, 0, 0)';
        }
      }
    }
    this.updateCheck();
  }

  pageNav() {
    if (this.pageForm.value.pageSelect === null) {
      window.alert("Select a page");
    }

    if (this.pageForm.value.pageSelect === "about") {
      this.router.navigate(['/about']);
    } else if (this.pageForm.value.pageSelect === "homepage") {
      this.router.navigate(['/editor']);
    }
    console.log(this.pageForm.value.pageSelect);

  }

  highlight(event) {
    console.log(event.target.id);
    for (let i = 0; i < this.elementIds.length; i++) { //newedits add an index
      console.log(this.elementIds[i]);
        console.log(this.bbIds[i]);
      if (event.target.id === this.elementIds[i]) {
        
        if (document.getElementById(this.bbIds[i]) !== null) {
          document.getElementById(this.bbIds[i]).style.backgroundColor = "snow";//new data
          //this.scrollEditor(this.bbIds[i]);
        }
      }
    }
    console.log("highlighted");
  }

  unhighlight(event) {
    console.log(event.target.id);
    for (let i = 0; i < this.elementIds.length; i++) {//newedits add an index
      if (event.target.id === this.elementIds[i]) {
        if (document.getElementById(this.bbIds[i]) !== null) {
          document.getElementById(this.bbIds[i]).style.backgroundColor = "transparent";//new data
          //this.scrollEditor(this.bbIds[i]);
        }
      }
    }
    console.log("unhighlighted");
  }

  highlightForm() {
    document.getElementById("fcEditDiv").style.backgroundColor = "snow";
  }

  undoElement(id: string) {//new data
    if (id === this.bbIds[0]){//viEditDiv
      document.getElementById("visitIcon").style.color = this.savedVisitIconColor;
      this.updateCheck();
    } else if (id === this.bbIds[1]){//ciEditDiv
      document.getElementById("callIcon").style.color = this.savedCallIconColor;
      this.updateCheck();
    } else if (id === this.bbIds[3]){//bpd1EditDiv
      document.getElementById("ebackgroundPicDiv1").style.backgroundColor = this.savedSection1Color;
      this.updateCheck();
    } else if (id === this.bbIds[4]){//hs2EditDiv
      document.getElementById("ehomepageSection2").style.backgroundColor = this.savedSection2Color;
      this.updateCheck();
    } else if (id === this.bbIds[5]){//ctEditDiv
      document.getElementById("ebackgroundPicDiv0Text").innerHTML = this.savedTitleText;
      this.updateCheck();
    } else if (id === this.bbIds[6]){//vitEditDiv
      document.getElementById("evisitText").innerHTML = this.savedVisitText;
      this.updateCheck();
    } else if (id === this.bbIds[7]){//catEditDiv
      document.getElementById("ecallText").innerHTML = this.savedCallText;
      this.updateCheck();
    } else if (id === this.bbIds[8]){//mhEditDiv
      document.getElementById("emessageHeader").innerHTML = this.savedQuestionLabelText;
      this.updateCheck();
    } else if (id === this.bbIds[9]){//adEditDiv
      document.getElementById("eaddress").innerHTML = this.savedAddressText;
      this.updateCheck();
    } else if (id === this.bbIds[10]){//phEditDiv
      document.getElementById("ephoneNumber").innerHTML = this.savedPhoneNumberText;
      this.updateCheck();
    } else if (id === this.bbIds[13]){//fcEditDiv
      document.getElementById("equestionForm1").style.backgroundColor = this.savedFormColor;
      this.updateCheck();
    } else if (id === this.bbIds[14]){
      document.getElementById("backgroundLayer").style.backgroundColor = this.savedBackgroundLayer;
      this.updateCheck();
    }
  }

  deleteElement(id: string) {//newedits add an index
    console.log(id);
    for (let i = 0; i < this.bbIds.length; i++) {
      if (id === this.bbIds[i]) {
        console.log("match");
        if (this.bbIds[i] === this.bbIds[3] || this.bbIds[i] === this.bbIds[4] || this.bbIds[i] === this.bbIds[12] ||
          this.bbIds[i] === this.bbIds[13] || this.bbIds[i] === this.bbIds[14]) {
            console.log("transparent");
            console.log(this.bbIds[i]);
            if (this.bbIds[i] === this.bbIds[3]) {
              document.getElementById(this.elementIds[i]).style.backgroundColor = this.savedSection1Color;
            } else if (this.bbIds[i] === this.bbIds[4]) {
              document.getElementById(this.elementIds[i]).style.backgroundColor = this.savedSection2Color;
            } else if (this.bbIds[i] === this.bbIds[13]) {
              document.getElementById(this.elementIds[i]).style.backgroundColor = this.savedFormColor;
            } else if (this.bbIds[i] === this.bbIds[14]) {
              document.getElementById(this.elementIds[i]).style.backgroundColor = this.savedBackgroundLayer;
            }
            this.updateCheck();
        } else if (this.bbIds[i] === this.bbIds[0] || this.bbIds[i] === this.bbIds[1]) {
            console.log("transparent");
            console.log(this.bbIds[i]);
          if (this.bbIds[i] === this.bbIds[0]) {
            document.getElementById("visitIcon").style.color = this.savedVisitIconColor;
          } else if (this.bbIds[i] === this.bbIds[1]) {
            document.getElementById("callIcon").style.color = this.savedCallIconColor;
          }
          this.updateCheck();
        } else {
          console.log("nothing");
          console.log(this.bbIds[i]);
          document.getElementById(this.elementIds[i]).innerHTML = "";
          this.updateCheck();
        }
      }
    }
  }

  onSavebi() {
    if (this.biForm.invalid) {
      return;
    }

    this.editorpageService.updateContactpageBackgroundImage(
      this.userId,
      this.biForm.value.image
    );
  }

  onbiImagePicked(event: Event) {
    document.getElementById("biSaveButton").style.display = "inline-block";
    const file = (event.target as HTMLInputElement).files[0];
    this.biForm.patchValue({ image: file });
    this.biForm.get('image').updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.biimagePreview = reader.result as string;
      console.log(this.biimagePreview);
      document.getElementById("ebackgroundPicDiv0").style.backgroundImage = "url('" + this.biimagePreview + "')";
    };
    reader.readAsDataURL(file);
  }

  setctText(ctTextForm: NgForm) {
    if (ctTextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.ctDirty = false;
    console.log(ctTextForm.value.text);
    document.getElementById("ebackgroundPicDiv0Text").innerHTML = ctTextForm.value.text;
    document.getElementById("ebackgroundPicDiv0Text").style.padding = "130px";
    document.getElementById("clearctTextInput").click();
    this.updateCheck();
  }

  ctState() {
    document.getElementById("ebackgroundPicDiv0Text").innerHTML = "";
    document.getElementById("ebackgroundPicDiv0Text").style.padding = "0px";
    document.getElementById("ebackgroundPicDiv0Text").style.marginBottom = "0px";
    this.ctDirty = true;
  }

  setctFont(id: string) {
    if (this.icon1Form.invalid) {
      return;
    }
    console.log(this.icon1Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon1Form.value.fontFamily;
    this.elementFonts[0] = this.icon1Form.value.fontFamily;
    this.updateCheck();
  }

  setvitText(vitTextForm: NgForm) {
    if (vitTextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.vitDirty = false;
    console.log(vitTextForm.value.text);
    document.getElementById("evisitText").innerHTML = vitTextForm.value.text;
    document.getElementById("clearvitTextInput").click();
    this.updateCheck();
  }

  vitState() {
    document.getElementById("evisitText").innerHTML = "";
    this.vitDirty = true;
  }

  setvitFont(id: string) {
    if (this.icon2Form.invalid) {
      return;
    }
    console.log(this.icon2Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon2Form.value.fontFamily;
    this.elementFonts[1] = this.icon2Form.value.fontFamily;
    this.updateCheck();
  }

  setcatText(catTextForm: NgForm) {
    if (catTextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.catDirty = false;
    console.log(catTextForm.value.text);
    document.getElementById("ecallText").innerHTML = catTextForm.value.text;
    document.getElementById("clearcatTextInput").click();
    this.updateCheck();
  }

  catState() {
    document.getElementById("ecallText").innerHTML = "";
    this.catDirty = true;
  }

  setcatFont(id: string) {
    if (this.icon4Form.invalid) {
      return;
    }
    console.log(this.icon4Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon4Form.value.fontFamily;
    this.elementFonts[3] = this.icon4Form.value.fontFamily;
    this.updateCheck();
  }

  setmhText(mhTextForm: NgForm) {
    if (mhTextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.mhDirty = false;
    console.log(mhTextForm.value.text);
    document.getElementById("emessageHeader").innerHTML = mhTextForm.value.text;
    document.getElementById("clearmhTextInput").click();
    this.updateCheck();
  }

  mhState() {
    document.getElementById("emessageHeader").innerHTML = "";
    this.mhDirty = true;
  }

  setmhFont(id: string) {
    if (this.icon6Form.invalid) {
      return;
    }
    console.log(this.icon6Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon6Form.value.fontFamily;
    this.elementFonts[5] = this.icon6Form.value.fontFamily;
    this.updateCheck();
  }

  setadText(adTextForm: NgForm) {
    if (adTextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.adDirty = false;
    console.log(adTextForm.value.text);
    document.getElementById("eaddress").innerHTML = adTextForm.value.text;
    document.getElementById("clearadTextInput").click();
    this.updateCheck();
  }

  adState() {
    document.getElementById("eaddress").innerHTML = "";
    this.adDirty = true;
  }

  setadFont(id: string) {
    if (this.icon3Form.invalid) {
      return;
    }
    console.log(this.icon3Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon3Form.value.fontFamily;
    this.elementFonts[2] = this.icon3Form.value.fontFamily;
    this.updateCheck();
  }

  setphText(phTextForm: NgForm) {
    if (phTextForm.invalid) {
      window.alert("Text field is blank");
      return;
    }
    this.phDirty = false;
    console.log(phTextForm.value.text);
    document.getElementById("ephoneNumber").innerHTML = phTextForm.value.text;
    document.getElementById("clearphTextInput").click();
    this.updateCheck();
  }

  phState() {
    document.getElementById("ephoneNumber").innerHTML = "";
    this.phDirty = true;
  }

  setphFont(id: string) {
    if (this.icon5Form.invalid) {
      return;
    }
    console.log(this.icon5Form.value.fontFamily);
    document.getElementById(id).style.fontFamily = this.icon5Form.value.fontFamily;
    this.elementFonts[4] = this.icon5Form.value.fontFamily;
    this.updateCheck();
  }

  updateCheck() {
    let updated = false;

    this.editorpageService.getContactpage().subscribe(data => {

      if (document.getElementById("visitIcon").style.color !== data.contactpage.visitIconColor){
        updated = true;
      } else if (document.getElementById("callIcon").style.color !== data.contactpage.callIconColor){
        updated = true;
      } else if (document.getElementById("ebackgroundPicDiv1").style.backgroundColor !== data.contactpage.section1Color){
        updated = true;
      } else if (document.getElementById("ehomepageSection2").style.backgroundColor !== data.contactpage.section2Color){
        updated = true;
      } else if (document.getElementById("ebackgroundPicDiv0Text").innerHTML !== data.contactpage.titleText){
        updated = true;
      } else if (document.getElementById("evisitText").innerHTML !== data.contactpage.visitText){
        updated = true;
      } else if (document.getElementById("ecallText").innerHTML !== data.contactpage.callText){
        updated = true;
      } else if (document.getElementById("emessageHeader").innerHTML !== data.contactpage.messageHeaderText){
        updated = true;
      } else if (document.getElementById("eaddress").innerHTML !== data.contactpage.addressText){
        updated = true;
      } else if (document.getElementById("ephoneNumber").innerHTML !== data.contactpage.phoneNumberText){
        updated = true;
      } else if (document.getElementById("equestionForm1").style.backgroundColor !== data.contactpage.formColor){
        updated = true;
      } else if (document.getElementById("backgroundLayer").style.backgroundColor !== data.contactpage.backgroundLayer){
        updated = true;
      }

      for (var i = 0; i < this.elementFonts.length; i++) {
        if (this.elementFonts[i] !== data.contactpage.elementFonts[i]) {
          updated = true;
        }
      }

      for (var i = 0; i < this.elementColors.length; i++) {//here
        if (this.elementColors[i] !== data.contactpage.elementColors[i]) {
          updated = true;
        }
      }

      for (var i = 0; i < this.elementFontSizes.length; i++) {
        if (this.elementFontSizes[i] !== data.contactpage.elementFontSizes[i]) {
          updated = true;
        }
      }

      if (updated === true) {
        document.getElementById("edSave").style.backgroundColor = "#ffffff";
      } else {
        document.getElementById("edSave").style.backgroundColor = "#eeeeee";
      }

    });
  }

  setFontSize(id: string, event: Event) {
    let size = event.target['innerText'].split('C')[0].trim();
    document.getElementById(id).style.fontSize = size;
    for (var i = 0; i < this.textElements.length; i++) {
      if (id === this.textElements[i]) {
        this.elementFontSizes[i] = size;
      }
    }
    this.updateCheck();
  }

  setviColor(value: string) {
    document.getElementById("viColorInput").style.color = "black";
    document.getElementById("visitIcon").style.color = value;
    this.updateCheck();
  }

  setciColor(value: string) {
    document.getElementById("ciColorInput").style.color = "black";
    document.getElementById("callIcon").style.color = value;
    this.updateCheck();
  }

  setbpd1Color(value: string) {
    document.getElementById("bpd1ColorInput").style.color = "black";
    document.getElementById("ebackgroundPicDiv1").style.backgroundColor = value;
    this.updateCheck();
  }

  seths2Color(value: string) {
    document.getElementById("hs2ColorInput").style.color = "black";
    document.getElementById("ehomepageSection2").style.backgroundColor = value;
    this.updateCheck();
  }

  setfcColor(value: string) {
    document.getElementById("fcColorInput").style.color = "black";
    document.getElementById("equestionForm1").style.backgroundColor = value;
    this.updateCheck();
  }

  setBlColor(value: string) {
    document.getElementById("blColorInput").style.color = "black";
    document.getElementById("backgroundLayer").style.backgroundColor = value;
    this.updateCheck();
  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
  }

}
