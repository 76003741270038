import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AuthService } from 'src/app/auth/auth.service';
import { EditorpageService } from 'src/app/Editorpage/editorpage.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit, OnDestroy {
  oldSubdomain: string;
  customDomain: string;
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  phoneNumber: string;
  leadEmail: string;
  leadNumber: string;
  businessName: string;
  dnsTarget: string;
  

  constructor(public router: Router, public route: ActivatedRoute, 
    public authService: AuthService, public editorpageService: EditorpageService) { }

  ngOnInit() {
    document.getElementById("initloader").style.display = "none";
    document.getElementById("hToolbar").style.width = "100%";
    document.getElementById("hToolbar").style.right = "unset";
    document.getElementById("hToolbar").style.zIndex = "unset";
    document.getElementById("hToolbar").style.position = "relative";
    document.getElementById("previewItem").style.display = "none";

    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
    });

    document.getElementById("headerTitleText").style.display = "none";
    document.getElementById("logo").style.backgroundImage = 
        'url(' + 'https://realestate-images-folder.s3.us-east-2.amazonaws.com/1604181768630-1604181774696.png' + ')';
    document.getElementById("logo").style.display = "block";
    document.getElementById("hToolbar").style.backgroundColor = "#ffffff";

    this.editorpageService.getHomepage().subscribe(data => {
      this.oldSubdomain = data.homepage.subdomain;
      this.customDomain = data.homepage.customdomain;
    });

    this.authService.getProfileInfo().subscribe(data => {
      console.log(data);
      this.phoneNumber = data.phoneNumber;
      this.leadEmail = data.leadEmail;
      this.leadNumber = data.leadNumber;
      this.businessName = data.businessName;
      this.dnsTarget = data.dnsTarget;
    });

  }

  closeModal() {
    document.getElementById("myModal").style.display = "none";
  }

  openModal() {
    document.getElementById("myModal").style.display = "block";
  }

  getDomains() {
    this.editorpageService.getHomepage().subscribe(data => {
      this.oldSubdomain = data.homepage.subdomain;
      this.customDomain = data.homepage.customdomain;
    });
  }

  getProfileInfo() {
    this.authService.getProfileInfo().subscribe(data => {
      console.log(data);
      this.phoneNumber = data.phoneNumber;
      this.leadEmail = data.leadEmail;
      this.leadNumber = data.leadNumber;
      this.businessName = data.businessName;
      this.dnsTarget = data.dnsTarget;
    });
  }

  closeMessages(id: string) {
    if (id === 'sd') {
      document.getElementById(id + "SuccessMessage").style.display = 'none';
      document.getElementById(id + "TakenMessage").style.display = 'none';
      document.getElementById(id + "FailureMessage").style.display = 'none';
      document.getElementById(id + "CloseMessageDiv").style.display = 'none';
      document.getElementById(id + "Button").style.display = 'inline-block';
    } else {
      document.getElementById(id + "SuccessMessage").style.display = 'none';
      document.getElementById(id + "FailureMessage").style.display = 'none';
      document.getElementById(id + "CloseMessageDiv").style.display = 'none';
      document.getElementById(id + "Button").style.display = 'inline-block';
    }
    

  }

  setSubdomain(subdomainForm: NgForm) {
    if (subdomainForm.value.text === "") {
      window.alert("Subdomain field is blank");
      return;
    }
    console.log(subdomainForm.value.text);

    if (subdomainForm.value.text.split(".")[1] !== undefined) {
      alert(`Only include text for your subdomain\nEx: Enter "housebiz" to generate housebiz.upgoeditor.com`);
      return;
    }

    document.getElementById('sdButton').style.display = 'none';
    document.getElementById('sdSpinner').style.display = 'inline-block';

    this.authService.changeSubdomain(subdomainForm.value.text).subscribe(data => {
      console.log(data);
      if (data.message === "Update successful!") {
        document.getElementById('sdSpinner').style.display = 'none';
        document.getElementById('sdSuccessMessage').style.display = 'inline-block';
        document.getElementById('sdCloseMessageDiv').style.display = 'inline-block';
        this.getDomains();
      } else if (data.message === "Subdomain taken") {
        document.getElementById('sdSpinner').style.display = 'none';
        document.getElementById('sdTakenMessage').style.display = 'inline-block';
        document.getElementById('sdCloseMessageDiv').style.display = 'inline-block';
      }
    }, (e) => {
      document.getElementById('sdSpinner').style.display = 'none';
      document.getElementById('sdFailureMessage').style.display = 'inline-block';
      document.getElementById('sdCloseMessageDiv').style.display = 'inline-block';
    });
  }

  setCustomDomain(customdomainForm: NgForm) {

    if (customdomainForm.value.text === "") {
      window.alert("Custom domain field is blank");
      return;
    }

    if (customdomainForm.value.text.split(".")[0] !== "www") {
      alert(`Please include "www." in the subdomain position.\nEx: www.domain.com`);
      return;
    } else if (customdomainForm.value.text.split(".")[2] === undefined) {
      alert(`Please include a top level domain.\nEx: "com" in www.domain.com or "io" in www.domain.io`);
      return;
    }

    if (this.customDomain !== "") {
      var continueVar = window.confirm(
        "If you connect this domain you will disconnect your current domain.\nClick OK to continue."
        );
      if (continueVar===false) {
        alert("stopped process");
        return;
      }
    }
    
    if (this.customDomain === "") {
      console.log(customdomainForm.value.text);
      this.authService.changeCustomDomain(customdomainForm.value.text).subscribe(data => {
        console.log(data);
        if (data.message === "domain added") {//update ui based on data we get back
          console.log('domain added and code is back to browser');
          this.getProfileInfo();
        }
      }, (e) => {
        console.log('for some reason domain not added');//getting this error
      });
    } else {
      this.authService.changeCustomDomainAndRemove(customdomainForm.value.text, this.customDomain).subscribe(data => {
        console.log(data);
        if (data.message === "new domain added old domain deleted") {//update ui based on data we get back
          console.log('domain added and code is back to browser');
          this.getProfileInfo();
        }
      }, (e) => {
        console.log('for some reason domain not added');//getting this error
      });
    }
    
  }

  setPhoneNumber(phoneNumberForm: NgForm) {
    if (phoneNumberForm.invalid) {
      window.alert("phone number field is blank");
      return;
    }

    document.getElementById('pnButton').style.display = 'none';
    document.getElementById('pnSpinner').style.display = 'inline-block';

    console.log(phoneNumberForm.value.text);
    this.authService.setPhoneNumber(phoneNumberForm.value.text).subscribe(data => {
      console.log(data);
      if (data.message === "Update successful!") {
        document.getElementById('pnSpinner').style.display = 'none';
        document.getElementById('pnSuccessMessage').style.display = 'inline-block';
        document.getElementById('pnCloseMessageDiv').style.display = 'inline-block';
        this.getProfileInfo();
      } 
    }, (e) => {
      document.getElementById('pnSpinner').style.display = 'none';
      document.getElementById('pnFailureMessage').style.display = 'inline-block';
      document.getElementById('pnCloseMessageDiv').style.display = 'inline-block';
    });
  }

  setLeadEmail(leadEmailForm: NgForm) {
    if (leadEmailForm.invalid) {
      window.alert("lead email field is blank");
      return;
    }

    document.getElementById('leButton').style.display = 'none';
    document.getElementById('leSpinner').style.display = 'inline-block';

    console.log(leadEmailForm.value.text);
    this.authService.setLeadEmail(leadEmailForm.value.text).subscribe(data => {
      console.log(data);
      if (data.message === "Update successful!") {
        document.getElementById('leSpinner').style.display = 'none';
        document.getElementById('leSuccessMessage').style.display = 'inline-block';
        document.getElementById('leCloseMessageDiv').style.display = 'inline-block';
        this.getProfileInfo();
      }
    }, (e) => {
      document.getElementById('leSpinner').style.display = 'none';
      document.getElementById('leFailureMessage').style.display = 'inline-block';
      document.getElementById('leCloseMessageDiv').style.display = 'inline-block';
    });
  }

  setLeadNumber(leadNumberForm: NgForm) {
    if (leadNumberForm.invalid) {
      window.alert("lead number field is blank");
      return;
    }

    document.getElementById('lnButton').style.display = 'none';
    document.getElementById('lnSpinner').style.display = 'inline-block';

    console.log(leadNumberForm.value.text);
    this.authService.setLeadNumber(leadNumberForm.value.text).subscribe(data => {
      console.log(data);
      if (data.message === "Update successful!") {
        document.getElementById('lnSpinner').style.display = 'none';
        document.getElementById('lnSuccessMessage').style.display = 'inline-block';
        document.getElementById('lnCloseMessageDiv').style.display = 'inline-block';
        this.getProfileInfo();
      }
    }, (e) => {
      document.getElementById('lnSpinner').style.display = 'none';
      document.getElementById('lnFailureMessage').style.display = 'inline-block';
      document.getElementById('lnCloseMessageDiv').style.display = 'inline-block';
    });
  }

  setBusinessName(businessNameForm: NgForm) {
    if (businessNameForm.invalid) {
      window.alert("business name field is blank");
      return;
    }

    document.getElementById('bnButton').style.display = 'none';
    document.getElementById('bnSpinner').style.display = 'inline-block';

    console.log(businessNameForm.value.text);
    this.authService.setBusinessName(businessNameForm.value.text).subscribe(data => {
      console.log(data);
      if (data.message === "Update successful!") {
        document.getElementById('bnSpinner').style.display = 'none';
        document.getElementById('bnSuccessMessage').style.display = 'inline-block';
        document.getElementById('bnCloseMessageDiv').style.display = 'inline-block';
        this.getProfileInfo();
      }
    }, (e) => {
      document.getElementById('bnSpinner').style.display = 'none';
      document.getElementById('bnFailureMessage').style.display = 'inline-block';
      document.getElementById('bnCloseMessageDiv').style.display = 'inline-block';
    });
  }

  manageBilling() {
    this.authService.manageBillingFromAccount().subscribe(result => {
      location.href = result.portalUrl;
    });
  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
  }

}
