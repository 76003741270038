import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';


import { AuthService } from 'src/app/auth/auth.service';
import { EditorpageService } from 'src/app/Editorpage/editorpage.service';

@Component({
  selector: 'app-aboutpage',
  templateUrl: './aboutpage.component.html',
  styleUrls: ['./aboutpage.component.css']
})
export class AboutpageComponent implements OnInit, OnDestroy {
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  subdomainActive = false;
  customdomainActive = false;
  headerSetting: string;
  userId = "";
  leadEmail = "";
  textElements: string[] = ["ebackgroundPicDiv0Text","epicText","esection2Header1","esection2Text1","esection2Text2","esection2Header2","esection2Text3","esection2Header3"];


  constructor(public authService: AuthService, public editorpageService: EditorpageService,
    public router: Router) { }

  ngOnInit() {
    document.getElementById("initloader").style.display = "none";
    document.getElementById("hToolbar").style.width = "100%";
    document.getElementById("hToolbar").style.right = "unset";
    document.getElementById("hToolbar").style.zIndex = "unset";
    document.getElementById("hToolbar").style.position = "relative";
    document.getElementById("previewItem").style.display = "none";

    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
    });


    // Store location host to check if there is a subdomain other than www
    let subdomain = location.host.split(".")[0];
    let customdomain = location.host.split(".")[1];
    console.log(subdomain);
    console.log(customdomain);


    // If user is not logged in, if they are on the main site, show them the landing page.
    if (!this.userIsAuthenticated) {
      if (location.host.substring(0,14) === 'www.upgoeditor') {
        this.router.navigate(['/landing']);
        return;
      } else if (subdomain !== 'www') {
        this.subdomainActive = true;
      } else if (customdomain !== 'upgoeditor') {
        this.customdomainActive = true;
      }
    }
    // If user is not logged in, show spinner while we decide to retrieve data or redirect.
    // If user isn't logged in and subdomain is active, then show user data without logging in
    // if (!this.userIsAuthenticated && this.subdomainActive === false && this.customdomainActive === false) {
    //   this.isLoading = true;
    // }

    // code to set the font

    if (this.subdomainActive === false) {
      if (this.customdomainActive === false) {
        this.editorpageService.getHomepage().subscribe(data => {
          console.log(data);
          document.getElementById("spinner").style.display = "none";
          document.getElementById("pageDiv").style.visibility = "visible";
          document.getElementById("logo").style.backgroundImage = 
          'url(' + data.homepage.brandLogoPath + ')';
    
          document.getElementById("headerTitleText").innerHTML = data.homepage.brandText;
          document.getElementById("hToolbar").style.backgroundColor = data.homepage.headerColor;
    
          this.headerSetting = data.homepage.headerSetting;
          if (this.headerSetting === "text") {
            document.getElementById("headerTitleText").style.display = "block";
            document.getElementById("logo").style.display = "none";
          } else if (this.headerSetting === "image") {
            document.getElementById("headerTitleText").style.display = "none";
            document.getElementById("logo").style.display = "block";
          }
        });

        this.editorpageService.getAboutpage().subscribe(data => {
          console.log(data);
          this.userId = data.aboutpage.userId;
          this.leadEmail = data.aboutpage.email;
    
          document.getElementById("backgroundPicDiv0").style.backgroundImage = 
          'url(' + data.aboutpage.backgroundImagePath + ')';
          document.getElementById("backgroundLayer").style.backgroundColor = data.aboutpage.backgroundLayer;
          document.getElementById("leftColumn").style.backgroundImage = 
          'url(' + data.aboutpage.photoOne + ')';
    
          document.getElementById("backgroundPicDiv1").style.backgroundColor = data.aboutpage.section1Color;
          document.getElementById("homepageSection2").style.backgroundColor = data.aboutpage.section2Color;
          document.getElementById("backgroundPicDiv0Text").innerHTML = data.aboutpage.titleText;
          document.getElementById("picText").innerHTML = data.aboutpage.rightSideText;
          document.getElementById("section2Header1").innerHTML = data.aboutpage.headerOne;
          document.getElementById("section2Text1").innerHTML = data.aboutpage.paragraphOne;
          document.getElementById("section2Text2").innerHTML = data.aboutpage.paragraphTwo;
          document.getElementById("section2Header2").innerHTML = data.aboutpage.headerTwo;
          document.getElementById("section2Text3").innerHTML = data.aboutpage.paragraphThree;
          document.getElementById("section2Header3").innerHTML = data.aboutpage.headerThree;

          // code to set the font size
          for (var i = 0; i < this.textElements.length; i++) {
            var res = this.textElements[i].substring(1, this.textElements[i].length);
            document.getElementById(res).style.fontSize = data.aboutpage.elementFontSizes[i];
          }

          for (var i = 0; i < this.textElements.length; i++) {
            var res = this.textElements[i].substring(1, this.textElements[i].length);
            document.getElementById(res).style.color = data.aboutpage.elementColors[i];
          }

          for (var i = 0; i < this.textElements.length; i++) {
            var res = this.textElements[i].substring(1, this.textElements[i].length);
            document.getElementById(res).style.fontFamily = data.aboutpage.elementFonts[i];
          }
          
        });
      } else if (this.customdomainActive === true) {
        console.log("in customdomain active");
        this.editorpageService.getHomepageByCustomDomain(customdomain).subscribe(data => {
          
          document.getElementById("spinner").style.display = "none";
          document.getElementById("pageDiv").style.visibility = "visible";
          document.getElementById("logo").style.backgroundImage = 
          'url(' + data.homepage.brandLogoPath + ')';
    
          document.getElementById("headerTitleText").innerHTML = data.homepage.brandText;
          document.getElementById("hToolbar").style.backgroundColor = data.homepage.headerColor;
    
          this.headerSetting = data.homepage.headerSetting;
          if (this.headerSetting === "text") {
            document.getElementById("headerTitleText").style.display = "block";
            document.getElementById("logo").style.display = "none";
          } else if (this.headerSetting === "image") {
            document.getElementById("headerTitleText").style.display = "none";
            document.getElementById("logo").style.display = "block";
          }
        });
    
        this.editorpageService.getAboutpageByCustomDomain(customdomain).subscribe(data => {
          console.log(data);
          this.userId = data.aboutpage.userId;
          this.leadEmail = data.aboutpage.email;
    
          document.getElementById("backgroundPicDiv0").style.backgroundImage = 
          'url(' + data.aboutpage.backgroundImagePath + ')';
          document.getElementById("backgroundLayer").style.backgroundColor = data.aboutpage.backgroundLayer;
          document.getElementById("leftColumn").style.backgroundImage = 
          'url(' + data.aboutpage.photoOne + ')';
    
          document.getElementById("backgroundPicDiv1").style.backgroundColor = data.aboutpage.section1Color;
          document.getElementById("homepageSection2").style.backgroundColor = data.aboutpage.section2Color;
          document.getElementById("backgroundPicDiv0Text").innerHTML = data.aboutpage.titleText;
          document.getElementById("picText").innerHTML = data.aboutpage.rightSideText;
          document.getElementById("section2Header1").innerHTML = data.aboutpage.headerOne;
          document.getElementById("section2Text1").innerHTML = data.aboutpage.paragraphOne;
          document.getElementById("section2Text2").innerHTML = data.aboutpage.paragraphTwo;
          document.getElementById("section2Header2").innerHTML = data.aboutpage.headerTwo;
          document.getElementById("section2Text3").innerHTML = data.aboutpage.paragraphThree;
          document.getElementById("section2Header3").innerHTML = data.aboutpage.headerThree;

          // code to set the font size
          for (var i = 0; i < this.textElements.length; i++) {
            var res = this.textElements[i].substring(1, this.textElements[i].length);
            document.getElementById(res).style.fontSize = data.aboutpage.elementFontSizes[i];
          }

          for (var i = 0; i < this.textElements.length; i++) {
            var res = this.textElements[i].substring(1, this.textElements[i].length);
            document.getElementById(res).style.color = data.aboutpage.elementColors[i];
          }

          for (var i = 0; i < this.textElements.length; i++) {
            var res = this.textElements[i].substring(1, this.textElements[i].length);
            document.getElementById(res).style.fontFamily = data.aboutpage.elementFonts[i];
          }
          
        });
      }
      
    } else if (this.subdomainActive === true) {
      console.log("in subdomain active");
      this.editorpageService.getHomepageBySubdomain(subdomain).subscribe(data => {
        
        document.getElementById("spinner").style.display = "none";
        document.getElementById("pageDiv").style.visibility = "visible";
        document.getElementById("logo").style.backgroundImage = 
        'url(' + data.homepage.brandLogoPath + ')';
  
        document.getElementById("headerTitleText").innerHTML = data.homepage.brandText;
        document.getElementById("hToolbar").style.backgroundColor = data.homepage.headerColor;
  
        this.headerSetting = data.homepage.headerSetting;
        if (this.headerSetting === "text") {
          document.getElementById("headerTitleText").style.display = "block";
          document.getElementById("logo").style.display = "none";
        } else if (this.headerSetting === "image") {
          document.getElementById("headerTitleText").style.display = "none";
          document.getElementById("logo").style.display = "block";
        }
      });
  
      this.editorpageService.getAboutpageBySubdomain(subdomain).subscribe(data => {
        console.log(data);
        this.userId = data.aboutpage.userId;
        this.leadEmail = data.aboutpage.email;
  
        document.getElementById("backgroundPicDiv0").style.backgroundImage = 
        'url(' + data.aboutpage.backgroundImagePath + ')';
        document.getElementById("backgroundLayer").style.backgroundColor = data.aboutpage.backgroundLayer;
        document.getElementById("leftColumn").style.backgroundImage = 
        'url(' + data.aboutpage.photoOne + ')';
  
        document.getElementById("backgroundPicDiv1").style.backgroundColor = data.aboutpage.section1Color;
        document.getElementById("homepageSection2").style.backgroundColor = data.aboutpage.section2Color;
        document.getElementById("backgroundPicDiv0Text").innerHTML = data.aboutpage.titleText;
        document.getElementById("picText").innerHTML = data.aboutpage.rightSideText;
        document.getElementById("section2Header1").innerHTML = data.aboutpage.headerOne;
        document.getElementById("section2Text1").innerHTML = data.aboutpage.paragraphOne;
        document.getElementById("section2Text2").innerHTML = data.aboutpage.paragraphTwo;
        document.getElementById("section2Header2").innerHTML = data.aboutpage.headerTwo;
        document.getElementById("section2Text3").innerHTML = data.aboutpage.paragraphThree;
        document.getElementById("section2Header3").innerHTML = data.aboutpage.headerThree;

        // code to set the font size
        for (var i = 0; i < this.textElements.length; i++) {
          var res = this.textElements[i].substring(1, this.textElements[i].length);
          document.getElementById(res).style.fontSize = data.aboutpage.elementFontSizes[i];
        }

        for (var i = 0; i < this.textElements.length; i++) {
          var res = this.textElements[i].substring(1, this.textElements[i].length);
          document.getElementById(res).style.color = data.aboutpage.elementColors[i];
        }

        for (var i = 0; i < this.textElements.length; i++) {
          var res = this.textElements[i].substring(1, this.textElements[i].length);
          document.getElementById(res).style.fontFamily = data.aboutpage.elementFonts[i];
        }
        
      });
    }

  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
  }
}
