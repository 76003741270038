import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { loadStripe } from '@stripe/stripe-js';

import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent implements OnInit {
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  stripePromise; 
  stripePlanId = "";
  starting = false;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    document.getElementById("initloader").style.display = "none";
    document.getElementById("hToolbar").style.width = "100%";
    document.getElementById("hToolbar").style.right = "unset";
    document.getElementById("hToolbar").style.zIndex = "unset";
    document.getElementById("hToolbar").style.position = "relative";
    document.getElementById("previewItem").style.display = "none";

    document.getElementById("headerTitleText").style.display = "none";
    document.getElementById("logo").style.backgroundImage = 
        'url(' + 'https://realestate-images-folder.s3.us-east-2.amazonaws.com/1604181768630-1604181774696.png' + ')';
    document.getElementById("logo").style.display = "block";
    document.getElementById("hToolbar").style.backgroundColor = "#ffffff";

    this.authService.setupStripe().subscribe(data => {
      this.stripePromise = loadStripe(data.publishableKey);
      this.stripePlanId = data.basicPrice;
    })
    
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
    .getAuthStatusListener()
    .subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
    });

  }
  
  async planClicked() {
    this.starting = true;
    const stripe = await this.stripePromise;
    this.authService.createCheckoutSession(this.stripePlanId).subscribe(data => {
      stripe.redirectToCheckout({
              sessionId: data.sessionId
            }).then(result => {
              if (result.error) {
                var displayError = document.getElementById("error-message");
                displayError.textContent = result.error.message;
              }
            })
    });
  } 
}
