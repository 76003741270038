import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';


import { AuthService } from 'src/app/auth/auth.service';
import { EditorpageService } from 'src/app/Editorpage/editorpage.service';

@Component({
  selector: 'app-contactpage',
  templateUrl: './contactpage.component.html',
  styleUrls: ['./contactpage.component.css']
})
export class ContactpageComponent implements OnInit, OnDestroy {
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  subdomainActive = false;
  customdomainActive = false;
  headerSetting: string;
  userId = "";
  leadEmail = "";
  showAddress = true;
  showPhoneNumber = true;
  questionForm: FormGroup;
  textElements: string[] = ["ebackgroundPicDiv0Text", "evisitText", "eaddress", "ecallText", "ephoneNumber", "emessageHeader", "equestionSubButton"];

  constructor(public authService: AuthService, public editorpageService: EditorpageService,
              public router: Router) { }

  ngOnInit() {
    document.getElementById("initloader").style.display = "none";
    document.getElementById("hToolbar").style.width = "100%";
    document.getElementById("hToolbar").style.right = "unset";
    document.getElementById("hToolbar").style.zIndex = "unset";
    document.getElementById("hToolbar").style.position = "relative";
    document.getElementById("previewItem").style.display = "none";

    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
    });

    this.questionForm = new FormGroup({
      name: new FormControl(null, { validators: [Validators.required] }),
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email /* , Validators.minLength(3) */]
      }),
      phoneNumber: new FormControl(null, { validators: [Validators.required] }),
      message: new FormControl(null, { validators: [Validators.required] })
    });

    // Store location host to check if there is a subdomain other than www
    let subdomain = location.host.split(".")[0];
    let customdomain = location.host.split(".")[1];
    console.log(subdomain);
    console.log(customdomain);


    // If user is not logged in, if they are on the main site, show them the landing page.
    if (!this.userIsAuthenticated) {
      if (location.host.substring(0,14) === 'www.upgoeditor') {
        this.router.navigate(['/landing']);
        return;
      } else if (subdomain !== 'www') {
        this.subdomainActive = true;
      } else if (customdomain !== 'upgoeditor') {
        this.customdomainActive = true;
      }
    }
    // If user is not logged in, show spinner while we decide to retrieve data or redirect.
    // If user isn't logged in and subdomain is active, then show user data without logging in
    if (!this.userIsAuthenticated) {
      document.getElementById("pageDiv").style.visibility = "hidden";
      document.getElementById("spinner").style.display = "block";
    }

    if (this.subdomainActive === false) {
      if (this.customdomainActive === false) {
        this.editorpageService.getHomepage().subscribe(data => {
          console.log(data);
          document.getElementById("spinner").style.display = "none";
          document.getElementById("pageDiv").style.visibility = "visible";
          document.getElementById("logo").style.backgroundImage = 
          'url(' + data.homepage.brandLogoPath + ')';
    
          document.getElementById("headerTitleText").innerHTML = data.homepage.brandText;
          document.getElementById("hToolbar").style.backgroundColor = data.homepage.headerColor;
    
          this.headerSetting = data.homepage.headerSetting;
          if (this.headerSetting === "text") {
            document.getElementById("headerTitleText").style.display = "block";
            document.getElementById("logo").style.display = "none";
          } else if (this.headerSetting === "image") {
            document.getElementById("headerTitleText").style.display = "none";
            document.getElementById("logo").style.display = "block";
          }
        });
    
        this.editorpageService.getContactpage().subscribe(data => {
          this.userId = data.contactpage.userId;
          this.leadEmail = data.contactpage.email;
    
          document.getElementById("backgroundPicDiv0").style.backgroundImage = 
          'url(' + data.contactpage.backgroundImagePath + ')';
          document.getElementById("backgroundLayer").style.backgroundColor = data.contactpage.backgroundLayer;
          document.getElementById("visitIcon").style.color = data.contactpage.visitIconColor;
          document.getElementById("callIcon").style.color = data.contactpage.callIconColor;
          document.getElementById("messageHeader").style.color = data.contactpage.questionLabelColor;
          document.getElementById("questionSubButton").style.backgroundColor = data.contactpage.formButtonColor;
          document.getElementById("backgroundPicDiv1").style.backgroundColor = data.contactpage.section1Color;
          document.getElementById("homepageSection2").style.backgroundColor = data.contactpage.section2Color;
          document.getElementById("questionForm1").style.backgroundColor = data.contactpage.formColor;
          document.getElementById("backgroundPicDiv0Text").innerHTML = data.contactpage.titleText;
          document.getElementById("visitText").innerHTML = data.contactpage.visitText;
          document.getElementById("callText").innerHTML = data.contactpage.callText;
          document.getElementById("messageHeader").innerHTML = data.contactpage.messageHeaderText;
          document.getElementById("address").innerHTML = data.contactpage.addressText;
          document.getElementById("phoneNumber").innerHTML = data.contactpage.phoneNumberText;
    
          this.showAddress = data.contactpage.showAddress;
          this.showPhoneNumber = data.contactpage.showPhoneNumber;

          // code to set the font size
          for (var i = 0; i < this.textElements.length; i++) {
            var res = this.textElements[i].substring(1, this.textElements[i].length);
            if (this.textElements[i] !== "equestionSubButton") {
              document.getElementById(res).style.fontSize = data.contactpage.elementFontSizes[i];
            }
          }

          for (var i = 0; i < this.textElements.length; i++) {
            var res = this.textElements[i].substring(1, this.textElements[i].length);
            if (this.textElements[i] !== "equestionSubButton") {
              document.getElementById(res).style.color = data.contactpage.elementColors[i];
            } else {
              document.getElementById(res).style.backgroundColor = data.contactpage.elementColors[i];
            }
          }

          for (var i = 0; i < this.textElements.length; i++) {
            var res = this.textElements[i].substring(1, this.textElements[i].length);
            if (this.textElements[i] !== "equestionSubButton") {
              document.getElementById(res).style.fontFamily = data.contactpage.elementFonts[i];
            }
          }
          
        });
      } else if (this.customdomainActive === true) {
        console.log("in customdomain active");
        this.editorpageService.getHomepageByCustomDomain(customdomain).subscribe(data => {
          console.log(data);
          document.getElementById("spinner").style.display = "none";
          document.getElementById("pageDiv").style.visibility = "visible";
          document.getElementById("logo").style.backgroundImage = 
          'url(' + data.homepage.brandLogoPath + ')';
    
          document.getElementById("headerTitleText").innerHTML = data.homepage.brandText;
          document.getElementById("hToolbar").style.backgroundColor = data.homepage.headerColor;
    
          this.headerSetting = data.homepage.headerSetting;
          if (this.headerSetting === "text") {
            document.getElementById("headerTitleText").style.display = "block";
            document.getElementById("logo").style.display = "none";
          } else if (this.headerSetting === "image") {
            document.getElementById("headerTitleText").style.display = "none";
            document.getElementById("logo").style.display = "block";
          }
        });
    
        this.editorpageService.getContactpageByCustomDomain(customdomain).subscribe(data => {
          this.userId = data.contactpage.userId;
          this.leadEmail = data.contactpage.email;
    
          document.getElementById("backgroundPicDiv0").style.backgroundImage = 
          'url(' + data.contactpage.backgroundImagePath + ')';
          document.getElementById("backgroundLayer").style.backgroundColor = data.contactpage.backgroundLayer;
          document.getElementById("visitIcon").style.color = data.contactpage.visitIconColor;
          document.getElementById("callIcon").style.color = data.contactpage.callIconColor;
          document.getElementById("messageHeader").style.color = data.contactpage.questionLabelColor;
          document.getElementById("questionSubButton").style.backgroundColor = data.contactpage.formButtonColor;
          document.getElementById("backgroundPicDiv1").style.backgroundColor = data.contactpage.section1Color;
          document.getElementById("homepageSection2").style.backgroundColor = data.contactpage.section2Color;
          document.getElementById("questionForm1").style.backgroundColor = data.contactpage.formColor;
          document.getElementById("backgroundPicDiv0Text").innerHTML = data.contactpage.titleText;
          document.getElementById("visitText").innerHTML = data.contactpage.visitText;
          document.getElementById("callText").innerHTML = data.contactpage.callText;
          document.getElementById("messageHeader").innerHTML = data.contactpage.messageHeaderText;
          document.getElementById("address").innerHTML = data.contactpage.addressText;
          document.getElementById("phoneNumber").innerHTML = data.contactpage.phoneNumberText;
    
          this.showAddress = data.contactpage.showAddress;
          this.showPhoneNumber = data.contactpage.showPhoneNumber;

          // code to set the font size
          for (var i = 0; i < this.textElements.length; i++) {
            var res = this.textElements[i].substring(1, this.textElements[i].length);
            if (this.textElements[i] !== "equestionSubButton") {
              document.getElementById(res).style.fontSize = data.contactpage.elementFontSizes[i];
            }
          }

          for (var i = 0; i < this.textElements.length; i++) {
            var res = this.textElements[i].substring(1, this.textElements[i].length);
            if (this.textElements[i] !== "equestionSubButton") {
              document.getElementById(res).style.color = data.contactpage.elementColors[i];
            } else {
              document.getElementById(res).style.backgroundColor = data.contactpage.elementColors[i];
            }
          }

          for (var i = 0; i < this.textElements.length; i++) {
            var res = this.textElements[i].substring(1, this.textElements[i].length);
            if (this.textElements[i] !== "equestionSubButton") {
              document.getElementById(res).style.fontFamily = data.contactpage.elementFonts[i];
            }
          }
          
        });
      }
      
    } else if (this.subdomainActive === true) {
      console.log("in subdomain active");
      this.editorpageService.getHomepageBySubdomain(subdomain).subscribe(data => {
        console.log(data);
        document.getElementById("spinner").style.display = "none";
        document.getElementById("pageDiv").style.visibility = "visible";
        document.getElementById("logo").style.backgroundImage = 
        'url(' + data.homepage.brandLogoPath + ')';
  
        document.getElementById("headerTitleText").innerHTML = data.homepage.brandText;
        document.getElementById("hToolbar").style.backgroundColor = data.homepage.headerColor;
  
        this.headerSetting = data.homepage.headerSetting;
        if (this.headerSetting === "text") {
          document.getElementById("headerTitleText").style.display = "block";
          document.getElementById("logo").style.display = "none";
        } else if (this.headerSetting === "image") {
          document.getElementById("headerTitleText").style.display = "none";
          document.getElementById("logo").style.display = "block";
        }
      });
  
      this.editorpageService.getContactpageBySubdomain(subdomain).subscribe(data => {
        this.userId = data.contactpage.userId;
        this.leadEmail = data.contactpage.email;
  
        document.getElementById("backgroundPicDiv0").style.backgroundImage = 
        'url(' + data.contactpage.backgroundImagePath + ')';
        document.getElementById("backgroundLayer").style.backgroundColor = data.contactpage.backgroundLayer;
        document.getElementById("visitIcon").style.color = data.contactpage.visitIconColor;
        document.getElementById("callIcon").style.color = data.contactpage.callIconColor;
        document.getElementById("messageHeader").style.color = data.contactpage.questionLabelColor;
        document.getElementById("questionSubButton").style.backgroundColor = data.contactpage.formButtonColor;
        document.getElementById("backgroundPicDiv1").style.backgroundColor = data.contactpage.section1Color;
        document.getElementById("homepageSection2").style.backgroundColor = data.contactpage.section2Color;
        document.getElementById("questionForm1").style.backgroundColor = data.contactpage.formColor;
        document.getElementById("backgroundPicDiv0Text").innerHTML = data.contactpage.titleText;
        document.getElementById("visitText").innerHTML = data.contactpage.visitText;
        document.getElementById("callText").innerHTML = data.contactpage.callText;
        document.getElementById("messageHeader").innerHTML = data.contactpage.messageHeaderText;
        document.getElementById("address").innerHTML = data.contactpage.addressText;
        document.getElementById("phoneNumber").innerHTML = data.contactpage.phoneNumberText;
  
        this.showAddress = data.contactpage.showAddress;
        this.showPhoneNumber = data.contactpage.showPhoneNumber;

        // code to set the font size
        for (var i = 0; i < this.textElements.length; i++) {
          var res = this.textElements[i].substring(1, this.textElements[i].length);
          if (this.textElements[i] !== "equestionSubButton") {
            document.getElementById(res).style.fontSize = data.contactpage.elementFontSizes[i];
          }
        }

        for (var i = 0; i < this.textElements.length; i++) {
          var res = this.textElements[i].substring(1, this.textElements[i].length);
          if (this.textElements[i] !== "equestionSubButton") {
            document.getElementById(res).style.color = data.contactpage.elementColors[i];
          } else {
            document.getElementById(res).style.backgroundColor = data.contactpage.elementColors[i];
          }
        }

        for (var i = 0; i < this.textElements.length; i++) {
          var res = this.textElements[i].substring(1, this.textElements[i].length);
          if (this.textElements[i] !== "equestionSubButton") {
            document.getElementById(res).style.fontFamily = data.contactpage.elementFonts[i];
          }
        }
        
      });
    }

  }

  ngOnDestroy(){
    this.authListenerSubs.unsubscribe();
  }
}
