import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

import { AuthData } from './auth-data.model';
import { Auth } from './auth.model';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root'})
export class AuthService {
  private isAuthenticated = false;
  private token: string;
  private userId: string;
  private tokenTimer: any;
  private authStatusListener = new Subject<boolean>();

  constructor(private http: HttpClient, private router: Router) {}

  getToken() {
    return this.token;
  }

  getIsAuth() { 
    return this.isAuthenticated;
  }

  getUserId() {
    return this.userId;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  setupStripe() {
    return this.http.get<{ publishableKey: string, basicPrice: string }>('api/user/setup');
  }

  changeSubdomain(newSubdomain: string) {
    let changeOb = {
      newSub: newSubdomain
    }
    return this.http.post<{ message: string }>('api/heroku/change-subdomain', changeOb);
  }

  changeCustomDomain(newCustomDomain: string) {
    let changeOb = {
      newCustom: newCustomDomain
    }
    return this.http.post<{ message: string }>('api/heroku/change-customdomain', changeOb);
  }

  changeCustomDomainAndRemove(newCustomDomain: string, oldCustomDomain: string) {
    let changeOb = {
      newCustom: newCustomDomain,
      oldCustom: oldCustomDomain
    }
    return this.http.post<{ message: string }>('api/heroku/change-customdomain-remove', changeOb);
  }

  currentEndCheck() {
    this.http.get<{ message: string }>('api/user/period-end')
    .subscribe(response => {
      console.log(response);
      if (response.message === "late") {
        document.getElementById('obD').style.display = "block";
      }
    });
  }

  createCheckoutSession(planId: string) {
    let planOb = {
      planId: planId
    }
    return this.http.post<{ sessionId: string }>('api/user/create-checkout-session', planOb);
  }

  manageBilling(checkoutId: string) { 
    let checkoutOb = {
      checkoutId: checkoutId
    }
    return this.http.post<{ portalUrl: string }>('api/user/customer-portal', checkoutOb);
  }

  manageBillingFromAccount() {
    return this.http.get<{ portalUrl: string }>('api/user/customer-portal-account');
  }

  setPhoneNumber(phoneNumber: string) {
    let phoneNumberOb = {
      phoneNumber: phoneNumber
    }
    return this.http.post<{ message: string }>('api/user/phone-number', phoneNumberOb);
  }

  setLeadEmail(leadEmail: string) {
    let leadEmailOb = {
      leadEmail: leadEmail
    }
    return this.http.post<{ message: string }>('api/user/lead-email', leadEmailOb);
  }

  setLeadNumber(leadNumber: string) {
    let leadNumberOb = {
      leadNumber: leadNumber
    }
    return this.http.post<{ message: string }>('api/user/lead-number', leadNumberOb);
  }

  setBusinessName(businessName: string) {
    let businessNameOb = {
      businessName: businessName
    }
    return this.http.post<{ message: string }>('api/user/business-name', businessNameOb);
  }

  getProfileInfo() {
    return this.http.get<{ phoneNumber: string, leadEmail: string, leadNumber: string, businessName: string, dnsTarget: string }>('api/user/profile-info');
  }

  createUser(email: string, password: string, firstName: string, lastName: string, dateOfBirth: string, phoneNumber: string, leadEmail: string, leadNumber: string, businessName: string, businessType: string, subdomain: string) {
    const authData: AuthData = {email: email, password: password, firstName: firstName, lastName: lastName, dateOfBirth: dateOfBirth, phoneNumber: phoneNumber, leadEmail: leadEmail, leadNumber: leadNumber, businessName: businessName, businessType: businessType, subdomain: subdomain };
    this.http.post('api/user/signup', authData)
    .subscribe(response => {
      console.log(response);

      let addOb = {
        subToAdd: subdomain
      }
      this.http.post<{ message: string }>('api/heroku/add-subdomain', addOb)
      .subscribe(response2 => {
        console.log(response2);
        this.router.navigate(['/login']);
      });
    });
  }

  login(email: string, password: string) {
    const auth: Auth = {email: email, password: password};
    this.http.post<{token: string, expiresIn: number, userId: string}>('api/user/login', auth)
    .subscribe(response => {
      const token = response.token;
      this.token = token;
      if (token) {
        const expiresInDuration = response.expiresIn;
        this.setAuthTimer(expiresInDuration);
        this.isAuthenticated = true;
        this.userId = response.userId;
        this.authStatusListener.next(true);
        const now = new Date();
        const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
        console.log(expirationDate);
        this.saveAuthData(token, expirationDate, this.userId);
        this.currentEndCheck();
        this.router.navigate(['/editor']);
      }
    }, (e) => {
      document.getElementById("loginFormItems").style.display = "block";
      document.getElementById("loginSpinner").style.display = "none";
      document.getElementById("loginError").style.display = "block";
    });
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.userId = authInformation.userId;
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);
    }
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    this.userId = null;
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(['/login']);
  }

  private setAuthTimer(duration: number) {
    console.log('Setting timer: ' + duration);
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(token: string, expirationDate: Date, userId: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('expiration', expirationDate.toISOString());
    localStorage.setItem('userId', userId);
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('userId');
  }

  private getAuthData() {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expiration');
    const userId = localStorage.getItem('userId');
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId
    };
  }
}
