import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  MatInputModule,
  MatCardModule,
  MatButtonModule,
  MatToolbarModule,
  MatExpansionModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatListModule,
  MatMenuModule,
  MatCheckboxModule,
  MatNativeDateModule,
  MatTableModule,
  MatIconModule
   } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClipboardModule } from 'angular-clipboard-auto';
import { ColorPickerModule } from 'ngx-color-picker';


import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomepageComponent } from './homepage/homepage.component';
import { EditorpageComponent } from './Editorpage/editorpage.component';
import { AppRoutingModule } from './app-routing.module';
import {LoginComponent} from './auth/login/login.component';
import { AuthInterceptor } from './auth/auth-interceptor';
import { OnboardComponent } from './auth/onboard/onboard.component';
import { AbouteditorComponent } from './Editorpage/abouteditor/abouteditor.component';
import { ContacteditorComponent } from './Editorpage/contacteditor/contacteditor.component';
import { AboutpageComponent } from './aboutpage/aboutpage.component';
import { ContactpageComponent } from './contactpage/contactpage.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { PlansComponent } from './auth/plans/plans.component';
import { CancelComponent } from './auth/cancel/cancel.component';
import { SuccessComponent } from './auth/success/success.component';
import { AccountComponent } from './auth/account/account.component';
import { TestpageComponent } from './testpage/testpage.component';







@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomepageComponent,
    EditorpageComponent,
    LoginComponent,
    OnboardComponent,
    AbouteditorComponent,
    ContacteditorComponent,
    AboutpageComponent,
    ContactpageComponent,
    LandingpageComponent,
    PlansComponent,
    CancelComponent,
    SuccessComponent,
    AccountComponent,
    TestpageComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatListModule,
    MatIconModule,
    FormsModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ClipboardModule,
    MatTableModule,
    ColorPickerModule,
    
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    MatDatepickerModule,
    MatNativeDateModule
  ], /* for services */
  bootstrap: [AppComponent]
})
export class AppModule { }
