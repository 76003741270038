import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import {Router} from '@angular/router';

import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.css']
})
export class OnboardComponent implements OnInit {
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  onboardForm: FormGroup;
  part1 = true;
  part2 = false;
  minDate = new Date(1931,0,1);
  maxDate = new Date(2005,11,31);
  

  constructor( 
    public authService: AuthService, private router: Router
    ) {}

  ngOnInit() {
    document.getElementById("initloader").style.display = "none";
    document.getElementById("hToolbar").style.width = "100%";
    document.getElementById("hToolbar").style.right = "unset";
    document.getElementById("hToolbar").style.zIndex = "unset";
    document.getElementById("hToolbar").style.position = "relative";
    document.getElementById("previewItem").style.display = "none";

    document.getElementById("headerTitleText").style.display = "none";
    document.getElementById("logo").style.backgroundImage = 
        'url(' + 'https://realestate-images-folder.s3.us-east-2.amazonaws.com/1604181768630-1604181774696.png' + ')';
    document.getElementById("logo").style.display = "block";
    document.getElementById("hToolbar").style.backgroundColor = "#ffffff";
    
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
    .getAuthStatusListener()
    .subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
    });


    this.onboardForm = new FormGroup({
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email /* , Validators.minLength(3) */]
      }),
      password: new FormControl(null, { validators: [Validators.required, Validators.minLength(8)] }),
      password2: new FormControl(null, { validators: [Validators.required] }),
      firstName: new FormControl(null, { validators: [Validators.required] }),
      lastName: new FormControl(null, { validators: [Validators.required] }),
      dateOfBirth: new FormControl(null, { validators: [Validators.required] }),
      phoneNumber: new FormControl(null, { validators: [Validators.required, Validators.minLength(10)] }),
      leadEmail: new FormControl(null, { validators: [Validators.required, Validators.email] }),
      leadNumber: new FormControl(null, { validators: [Validators.required, Validators.minLength(10)] }),
      businessName: new FormControl(null, { validators: [Validators.required] }),
      businessType: new FormControl(null, { validators: [Validators.required] }), 
      subdomain: new FormControl(null, { validators: [Validators.required] })
    });

  }

  create() {
    if (!this.onlyContainsLetters(this.onboardForm.value.subdomain)) {
      window.alert(`Your subdomain entry can only contain letters.\n` +
      `Ex: Enter "housebuyer" to produce the subdomain "housebuyer.upgoeditor.com"`);
      return;
    } else if (this.onboardForm.invalid) {
      window.alert("You must fix an error in the form before you submit.");
      return;
    }
    console.log(this.onboardForm);
    return;
    
    this.authService.createUser(
      this.onboardForm.value.email,
      this.onboardForm.value.password,
      this.onboardForm.value.firstName, 
      this.onboardForm.value.lastName,
      this.onboardForm.value.dateOfBirth,
      this.onboardForm.value.phoneNumber,
      this.onboardForm.value.leadEmail,
      this.onboardForm.value.leadNumber,
      this.onboardForm.value.businessName,
      this.onboardForm.value.businessType,
      this.onboardForm.value.subdomain
      );
      
      this.onboardForm.reset();
  }

  goNext() {
    if (this.onboardForm.get('firstName').invalid || this.onboardForm.get('lastName').invalid
    || this.onboardForm.get("dateOfBirth").invalid || this.onboardForm.get('email').invalid
    || this.onboardForm.get('phoneNumber').invalid || this.onboardForm.get('password').invalid) {
      window.alert("You must fix an error in this form before you proceed.");
      return;
    } else if (this.onboardForm.value.password !== this.onboardForm.value.password2) {
      window.alert("Passwords do not match. Please correct.");
      return;
    } else if (!this.containsDigits(this.onboardForm.value.password)) {
      window.alert("Password must contain at least one number.");
      return;
    } else if (!this.containsLetters(this.onboardForm.value.password)) {
      window.alert("Password must contain at least one letter.");
      return;
    }
    this.part1 = false;
    this.part2 = true;
  }

  onlyContainsLetters(text: string) {
    for (var i = 0; i < text.length; i++) {
      if (!(/[a-zA-Z]/).test(text[i])) {
        return false;
      }
    }
    return true;
  }

  containsDigits(pass: string) {
    var valueToReturn = false;
    var passArray = Array.from(pass);
    for (var i = 0; i < passArray.length; i++) {
      if (!isNaN(passArray[i] as any)) {
        valueToReturn = true;
        break;
      }
    }
    return valueToReturn;
  }

  containsLetters(pass: string) {
    var valueToReturn = false;
    var passArray = Array.from(pass);
    for (var i = 0; i < passArray.length; i++) {
      if (isNaN(passArray[i] as any)) {
        valueToReturn = true;
        break;
      }
    }
    return valueToReturn;
  }

  goToLogIn() {
    this.router.navigate(['/login']);
  }

  goBack() {

    this.part2 = false;
    this.part1 = true;
  }
}
