import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.css']
})
export class CancelComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
    document.getElementById("initloader").style.display = "none";
    document.getElementById("hToolbar").style.width = "100%";
    document.getElementById("hToolbar").style.right = "unset";
    document.getElementById("hToolbar").style.zIndex = "unset";
    document.getElementById("hToolbar").style.position = "relative";
    document.getElementById("previewItem").style.display = "none";

    document.getElementById("headerTitleText").style.display = "none";
    document.getElementById("logo").style.backgroundImage = 
        'url(' + 'https://realestate-images-folder.s3.us-east-2.amazonaws.com/1604181768630-1604181774696.png' + ')';
    document.getElementById("logo").style.display = "block";
    document.getElementById("hToolbar").style.backgroundColor = "#ffffff";

  }

  tryAgain() {
    this.router.navigate(['/plans']);
  }
}
