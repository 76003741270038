import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testpage',
  templateUrl: './testpage.component.html',
  styleUrls: ['./testpage.component.css']
})
export class TestpageComponent implements OnInit {
  contentDivs: string[] = ["0","1","2","3","4"]
  contentDivBackgrounds: string[] = [`url("https://realestate-images-folder.s3.us-east-2.amazonaws.com/1610990543812-1610990547824.jpg")`, ``, ``, ``, ``]


  constructor() { }

  ngOnInit() {
    document.getElementById("initloader").style.display = "none";
    document.getElementById("hToolbar").style.width = "78%";
    document.getElementById("hToolbar").style.right = "0%";
    document.getElementById("hToolbar").style.zIndex = "2";
    document.getElementById("hToolbar").style.position = "fixed";
    document.getElementById("previewItem").style.display = "block";
    document.querySelector("main").style.backgroundColor = "gainsboro";

    document.addEventListener('DOMContentLoaded', event => { 
      this.paintContentDivs();
    });
    
    
  }

  paintContentDivs() {
    var querySelector = document.querySelectorAll<HTMLElement>(".contentDiv");
    for (var i = 0; i < this.contentDivs.length; i++) {
      console.log(i);
      if (i === 0) {
        querySelector[i].style.backgroundImage = this.contentDivBackgrounds[i];
      }
      
    }

    document.getElementById('cdid01').style.display = 'inline-table';
  }

  columnTest() {
    document.getElementById('cdid02').style.display = 'inline-table';
    this.twoColumns0();
  }

  twoColumns0() {
    document.getElementById('cdid01').style.marginLeft = "3%";
    document.getElementById('cdid01').style.width = "45%";
    document.getElementById('cdid02').style.marginLeft = "3%";
    document.getElementById('cdid02').style.width = "45%";
  }

  headerTest() {
    document.getElementById('cdidh201').innerHTML = "We Will Buy Your Home";
    document.getElementById('cdidh202').innerHTML = "We Will Buy Your Home";
  }

}
