import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { EditorpageService } from '../Editorpage/editorpage.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  username = '';
  showAllPageOptions: Boolean;
  above600 = false;

  constructor(private authService: AuthService, private router: Router, private editorpageService: EditorpageService) {}

  ngOnInit() {
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
    .getAuthStatusListener()
    .subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
    });

    //this.editorpageService.

    let subdomain = location.host.split(".")[0];
    let customdomain = location.host.split(".")[1];

    if (location.host.substring(0,14) === 'www.upgoeditor') {
      this.showAllPageOptions = false;
    } else if (subdomain !== "www" || customdomain !== "upgoeditor") {
      this.showAllPageOptions = true;
    }
    
  }

  showPreview() {
    var sub = this.editorpageService.getSubdomain();
    window.open("https://" + sub + ".upgoeditor.com/", "_blank");
  }

  showAllOptions() {
    this.showAllPageOptions = true;
  }

  dontShowOptions() {
    this.showAllPageOptions = false;
  }

  headerLinkRoute() {
    if (this.userIsAuthenticated) {
      this.router.navigate(['/homepage']);
    } else {
      this.router.navigate(['/']);
    }
  }

  closeOverdueBanner() {
    document.getElementById("obD").style.display = "none";
  }

  onLogout() {
    document.getElementById("logo").style.backgroundImage = 
        'url(' + 'https://realestate-images-folder.s3.us-east-2.amazonaws.com/1604181768630-1604181774696.png' + ')';
    document.getElementById("logo").style.display = "block";
    this.authService.logout();
  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
  }
}
