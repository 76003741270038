import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  checkoutId: string;

  constructor(public router: Router, public route: ActivatedRoute, public authService: AuthService) { }

  ngOnInit() {
    document.getElementById("initloader").style.display = "none";
    document.getElementById("hToolbar").style.width = "100%";
    document.getElementById("hToolbar").style.right = "unset";
    document.getElementById("hToolbar").style.zIndex = "unset";
    document.getElementById("hToolbar").style.position = "relative";
    document.getElementById("previewItem").style.display = "none";

    document.getElementById("headerTitleText").style.display = "none";
    document.getElementById("logo").style.backgroundImage = 
        'url(' + 'https://realestate-images-folder.s3.us-east-2.amazonaws.com/1604181768630-1604181774696.png' + ')';
    document.getElementById("logo").style.display = "block";
    document.getElementById("hToolbar").style.backgroundColor = "#ffffff";

  }

  manageBilling() {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has("checkoutId")) {
        console.log(paramMap.get("checkoutId"))
        this.checkoutId = paramMap.get("checkoutId");
      } 
    });
    this.authService.manageBilling(this.checkoutId).subscribe(result => {
      location.href = result.portalUrl;
    });
  }

  backToEditor() {
    this.router.navigate(['/editor']);
  }
}
